import { type ComponentRef, type ReactElement, useState } from 'react'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'
import { useToggle } from '@stuller/shared/util/react-hooks'
import { MenuItem } from '../MenuItem'
import { MessagesBadge } from './MessagesBadge'
import { MessagesPopover } from './MessagesPopover'

/**
 * Menu item and dropdown for messages
 */
function MenuMessages (): ReactElement {
  const [open, handleToggle, handleOpenChange] = useToggle()
  const [reference, setReference] = useState<ComponentRef<typeof MenuItem> | null>(null)

  return (
    <>
      <MenuItem
        icon={fas.faEnvelope}
        text='Messages'
        open={open}
        onClick={handleToggle}
        setReference={setReference}
      >
        <MessagesBadge />
      </MenuItem>
      <MessagesPopover
        open={open}
        onOpenChange={handleOpenChange}
        reference={reference}
      />
    </>
  )
}

export {
  MenuMessages
}
