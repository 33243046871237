import { forwardRef, type ReactNode } from 'react'
import { clsx } from 'clsx'

export interface NavProps {
  /**
   * Id of element
   */
  id?: string
  /**
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /**
   * Children of element
   */
  children?: ReactNode
  /**
   * Make NavItems look like tabs
   */
  tabs?: boolean
  /**
   * Fills the nav to extend to full available width
   */
  fill?: boolean
  /**
   * All horizontal space will be occupied by nav links, but unlike the `fill` above,
   * every nav item will be the same width.
   */
  justified?: boolean
}

/**
 * Main component to hold the navigation
 *
 * Options for tabs, fill and justified
 */
const Nav = forwardRef<HTMLUListElement, NavProps>(({
  className,
  children,
  tabs = false,
  fill = false,
  justified = false,
  ...otherAttributes
}, ref) => {
  const classNames = clsx(
    'nav',
    tabs && 'nav-tabs',
    fill && 'nav-fill',
    justified && 'nav-justified',
    className
  )

  return (
    <ul
      className={classNames}
      ref={ref}
      {...otherAttributes}
    >
      {children}
    </ul>
  )
})
Nav.displayName = 'Nav'

export {
  Nav
}
