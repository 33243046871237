import type { TargetingMap } from '@stuller/contentstack/util/types'
import { useAuth } from '@stuller/stullercom/feat/auth'
import { useTargetingPreviewer } from './targetingPreviewer'

/**
 * Hook to check if a web user has a specific ship to account ID
 */
function useShipToAccountTargeting (targeting: TargetingMap['SHIP_TO_ACCOUNT']): boolean {
  const [targetingPreviewer] = useTargetingPreviewer()
  const { authUser } = useAuth()
  const shipToId = targetingPreviewer?.shipToAccount ?? authUser?.user?.defaultActiveShipToAccount?.id

  return shipToId == null ? false : targeting.targetValue.includes(shipToId)
}

export { useShipToAccountTargeting }
