import { type CSSProperties, type ReactNode, forwardRef } from 'react'
import { clsx } from 'clsx'
import { type HeadingTag } from '../../types/bootstrap'

interface CardSubtitleType {
  /**
   * Id of element
   */
  id?: string
  /**
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /**
   * Inline styles to pass to the containing element
   */
  style?: CSSProperties
  /**
   * Children of element
   */
  children?: ReactNode
  /**
   * HTML element tag type, must be h2-h6
   */
  tag?: Exclude<HeadingTag, 'h1'>
}

/**
 * React component wrapper of bootstrap's card-subtitle component to serve as
 * sub-header element for Card component (h# should always be smaller than CardTitle)
 */
const CardSubtitle = forwardRef<HTMLHeadingElement, CardSubtitleType>(({
  className,
  children,
  tag: Tag = 'h6',
  ...attributes
}, ref) => {
  const classNames = clsx('card-subtitle text-truncate', className)

  return (
    <Tag
      className={classNames}
      ref={ref}
      {...attributes}
    >
      {children}
    </Tag>
  )
})
CardSubtitle.displayName = 'CardSubitle'

export {
  CardSubtitle
}
