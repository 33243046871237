import type { TargetingMap } from '@stuller/contentstack/util/types'
import { useAuth } from '@stuller/stullercom/feat/auth'
import { useTargetingPreviewer } from './targetingPreviewer'

/**
 * Hook to check whether or not a user is enrolled in ecommerce
 */
function useEcommerceTargeting (targeting: TargetingMap['ECOMMERCE']): boolean {
  const [targetingPreviewer] = useTargetingPreviewer()
  const auth = useAuth()
  const { authUser } = auth
  const ecommerceMode = targetingPreviewer?.isEcommerceMerchandisingMode != null ? targetingPreviewer?.isEcommerceMerchandisingMode : authUser?.user?.ecommerceMerchandisingMode ?? false

  return targeting.targetValue ? ecommerceMode : !ecommerceMode
}

export { useEcommerceTargeting }
