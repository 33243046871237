import { type ReactElement } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fad } from '@awesome.me/kit-3dbd93c064/icons'
import { Badge } from '@stuller/stullercom/ui'
import { useGetNotificationsUnreadCountQuery } from '@stuller/stullercom/data-access/apollo-queries'
import { NOTIFICATION_APP } from './constants'

/**
 * Shows the number of unread messages or a triangle if there are important messages
 */
function MessagesBadge (): ReactElement | null {
  const { data } = useGetNotificationsUnreadCountQuery({
    variables: { app: NOTIFICATION_APP },
    fetchPolicy: 'cache-and-network',
    pollInterval: 300000,
    notifyOnNetworkStatusChange: true
  })
  const unreadCount = data?.viewer?.notificationsUnreadCount

  if (unreadCount == null || unreadCount.total === 0) {
    return null
  }

  if (unreadCount.important > 0) {
    return (
      <FontAwesomeIcon
        icon={fad.faTriangleExclamation}
        className='text-danger'
        style={{ fontSize: 21, '--fa-secondary-opacity': '1.0', '--fa-primary-color': 'white' }}
      />
    )
  }

  return (
    <Badge color='orange-300' pill>
      <span>{unreadCount.total}</span>
      <span className='visually-hidden'>unread messages</span>
    </Badge>
  )
}

export {
  MessagesBadge
}
