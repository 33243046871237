import { CmsContent } from '@stuller/stullercom/feat/cms-content'
import { type ReactElement } from 'react'

/**
 * StullerCom footer nav
 */
function FooterStullerCom (): ReactElement {
  return (
    <footer
      className='bg-gray-100 border-top pb-6 py-md-7 w-100 d-print-none'
      style={{ boxShadow: '0 50vh 0 50vh var(--bs-gray-100)' }}
      data-gtm-click-section='footer-nav'
      data-test='footer'
    >
      <div className='container-xxxl p-0'>
        <CmsContent contentContainerName='footer-nav' noRefetch skipLoading />
      </div>
    </footer>
  )
}

export {
  FooterStullerCom
}
