import { type ReactElement, useState } from 'react'
import { type CartLineMessageFragment } from '@stuller/stullercom/data-access/apollo-queries'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'
import { Popover, PopoverBody } from '@stuller/stullercom/ui'
import { FloatingPortal } from '@floating-ui/react'
import clsx from 'clsx'

interface CartCardAlertProps {
  messages: CartLineMessageFragment[]
  type: 'error' | 'note'
}

/**
 * The alert element to show errors and notes on a cart item
 */
function CartCardAlert ({ messages, type }: CartCardAlertProps): ReactElement {
  const [open, handleOpenChange] = useState(false)
  const [reference, setReference] = useState<HTMLDivElement | null>(null)

  // Only two options to choose from right now, so a ternary operation will suffice currently
  const hoverIconClassNames = clsx(
    'd-flex rounded-2 fw-bold align-items-center py-1 ms-2',
    type === 'error' ? 'bg-red-100 text-red-300' : 'bg-yellow-100 text-yellow-300')
  const popoverClassNames = clsx(type === 'error' ? 'bg-red-100 border-red-200' : 'bg-yellow-100 border-yellow-200')
  const popoverBodyClassNames = clsx(
    'row g-2',
    type === 'error' ? 'text-red-500' : 'text-yellow-600')

  return (
    <>
      <div className={hoverIconClassNames} ref={setReference} onMouseEnter={() => { handleOpenChange(true) }} data-test='hover-link'>
        <FontAwesomeIcon className='ms-2 fs-6' icon={fas.faTriangleExclamation} />
        <span className='mx-2 fs-6'>{messages.length}</span>
      </div>
      {open && (
        <FloatingPortal>
          <Popover
            className={popoverClassNames}
            hover reference={reference}
            open={open}
            onOpenChange={handleOpenChange}
            placement='top-end'
          >
            <PopoverBody className={popoverBodyClassNames} data-test='alert-message'>
              {messages.map(value => <div key={value.code}>{value.message}</div>)}
            </PopoverBody>
          </Popover>
        </FloatingPortal>
      )}
    </>
  )
}

export {
  CartCardAlert
}
