import { forwardRef, useContext } from 'react'
import clsx from 'clsx'
import {
  DropdownContext,
  Button,
  type ButtonProps
} from '../../..'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'

export interface DropdownToggleProps extends ButtonProps {
  /**
   * Indicates the toggle is part of a split button dropdown
   */
  split?: boolean
  /**
   * Href for if the button is a link
   */
  href?: string
}

/**
 * Dropdown toggle button to toggle the dropdown menu
 */
const DropdownToggle = forwardRef<HTMLButtonElement, DropdownToggleProps>(({
  className,
  children,
  split = false,
  href,
  ...otherAttributes
}, ref) => {
  const { open, onToggle, type } = useContext(DropdownContext)
  const classNames = clsx(
    'dropdown-toggle',
    split && 'dropdown-toggle-split',
    className
  )
  const icon = type === 'dropdown'
    ? fas.faAngleDown
    : type === 'dropup'
      ? fas.faAngleUp
      : type === 'dropend'
        ? fas.faAngleRight
        : fas.faAngleLeft

  return (
    <Button
      className={classNames}
      active={open}
      onClick={onToggle}
      aria-expanded={open}
      ref={ref}
      href={href}
      {...otherAttributes}
    >
      {split ? <span className='visually-hidden'>Toggle Dropdown</span> : children}
      <FontAwesomeIcon icon={icon} className='dropdown-toggle-icon' size='lg' />
    </Button>
  )
})
DropdownToggle.displayName = 'DropdownToggle'

export {
  DropdownToggle
}
