import { type ReactElement } from 'react'
import { Badge } from '@stuller/stullercom/ui'
import { useGetPendingShowcaseCallbackRequestsCountQuery } from '@stuller/stullercom/data-access/apollo-queries'

/**
 * Shows the number of pending showcase callback requests
 */
function ShowcaseBadge (): ReactElement | null {
  const { data } = useGetPendingShowcaseCallbackRequestsCountQuery({
    variables: { onlyRecent: true },
    fetchPolicy: 'cache-and-network',
    pollInterval: 300000
  })
  const pendingCount = data?.pendingJewelerShowcaseCallbackRequests?.count

  if (pendingCount == null || pendingCount === 0) {
    return null
  }

  return (
    <Badge color='blue-300'>
      <span>{pendingCount}</span>
      <span className='visually-hidden'>Pending showcase callback requests</span>
    </Badge>
  )
}

export {
  ShowcaseBadge
}
