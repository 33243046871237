import { type ReactElement, useRef } from 'react'
import { useRouter } from 'next/router'
import { useHotkeys, useHotkeysSequence } from '@stuller/shared/util/react-hooks'
import { Modal, ModalBody, ModalHeader } from '@stuller/stullercom/ui'

interface ConsultantModeKeyboardModalProps {
  /**
   * Modal open state
   */
  isOpen: boolean
  /**
   * Toggle modal open state
   */
  onToggle: () => void
}

/**
 * Styled <Kbd> element
 */
function Kbd ({ children }: { children: ReactElement | string }): ReactElement {
  return (
    <kbd className='fs-3 mx-1'>
      {children}
    </kbd>
  )
}

/**
 * Keyboard shortcuts modal
 */
function ConsultantModeShortcutsModal ({ isOpen, onToggle }: ConsultantModeKeyboardModalProps): ReactElement {
  const portalRef = useRef(typeof window !== 'undefined' ? document.body : undefined)

  return (
    <Modal isOpen={isOpen} onToggle={onToggle} size='lg' portalRef={portalRef}>
      <ModalHeader onToggle={onToggle}>
        Keyboard Shortcuts
      </ModalHeader>
      <ModalBody>
        <div className='row pb-4 fs-6'>
          <div className='col-md-6 mb-4'>
            <section className='mb-4'>
              <h3>General shortcuts</h3>
              <div className='pb-2'>
                <Kbd>?</Kbd>
                <span className='mx-2'>Keyboard Help</span>
              </div>
              <div>
                <Kbd>/</Kbd>
                <span className='mx-2'>Focus Search Bar</span>
              </div>
            </section>

            <section>
              <h3>Consultant Mode</h3>
              <div className='pb-2'>
                <Kbd>C</Kbd>
                <Kbd>C</Kbd>
                <Kbd>A</Kbd>
                <span className='mx-2'>Change Account</span>
              </div>
              <div>
                <Kbd>C</Kbd>
                <Kbd>A</Kbd>
                <Kbd>S</Kbd>
                <span className='mx-2'>Account Summary</span>
              </div>
            </section>
          </div>
          <div className='col-md-6'>
            <section>
              <h3>Go to Pages</h3>
              <div className='pb-2'>
                <Kbd>Ctrl</Kbd>
                <span>+</span>
                <Kbd>Home</Kbd>
                <span className='mx-2'>Homepage</span>
              </div>
              <div className='pb-2'>
                <Kbd>J</Kbd>
                <Kbd>Q</Kbd>
                <span className='fw-light'>or</span>
                <Kbd>Q</Kbd>
                <Kbd>O</Kbd>
                <span className='mx-2'>Quick Order</span>
              </div>
              <div className='pb-2'>
                <Kbd>J</Kbd>
                <Kbd>C</Kbd>
                <span className='mx-2'>Cart</span>
              </div>
              <div className='pb-2'>
                <Kbd>J</Kbd>
                <Kbd>K</Kbd>
                <span className='mx-2'>Checkout</span>
              </div>
              <div className='pb-2'>
                <Kbd>J</Kbd>
                <Kbd>D</Kbd>
                <Kbd>M</Kbd>
                <span className='mx-2'>Diamonds: Melee Search</span>
              </div>
              <div className='pb-2'>
                <Kbd>J</Kbd>
                <Kbd>D</Kbd>
                <Kbd>S</Kbd>
                <span className='mx-2'>Diamonds: Serialized Search</span>
              </div>
              <div className='pb-2'>
                <Kbd>J</Kbd>
                <Kbd>G</Kbd>
                <span className='mx-2'>Gemstones: Search</span>
              </div>
              <div className='pb-2'>
                <Kbd>J</Kbd>
                <Kbd>E</Kbd>
                <Kbd>S</Kbd>
                <span className='mx-2'>Earring Stud Builder</span>
              </div>
              <div className='pb-2'>
                <Kbd>J</Kbd>
                <Kbd>O</Kbd>
                <Kbd>H</Kbd>
                <span className='mx-2'>Order History</span>
              </div>
              <div>
                <Kbd>J</Kbd>
                <Kbd>P</Kbd>
                <Kbd>B</Kbd>
                <span className='mx-2'>Pay Bill</span>
              </div>
            </section>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

/**
 * Keyboard shortcuts for consultant mode
 */
function ConsultantModeShortcuts (): null {
  const router = useRouter()

  useHotkeys('ctrl+home', async () => {
    await router.push('/')
  })

  useHotkeysSequence('cca', async () => {
    await router.push('/consultant')
  })

  useHotkeysSequence('cas', async () => {
    await router.push('/consultant/searchstep4')
  })

  useHotkeysSequence(['jq', 'qo'], async () => {
    await router.push('/quickorder')
  })

  useHotkeysSequence('jc', async () => {
    await router.push('/cart')
  })

  useHotkeysSequence('jk', async () => {
    await router.push('/checkout')
  })

  useHotkeysSequence('jdm', async () => {
    await router.push('/browse/diamonds/meleesearch')
  })

  useHotkeysSequence('jds', async () => {
    await router.push('/products/serialized-diamond-search')
  })

  useHotkeysSequence('jg', async () => {
    await router.push('/browse/gemstones')
  })

  useHotkeysSequence('jes', async () => {
    await router.push('/earringstudbuilder')
  })

  useHotkeysSequence('joh', async () => {
    await router.push('/orders')
  })

  useHotkeysSequence('jpb', async () => {
    await router.push('/receivables/summary')
  })

  return null
}

export {
  ConsultantModeShortcuts,
  ConsultantModeShortcutsModal
}
