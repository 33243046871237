import { type ChangeEvent, type ReactElement } from 'react'
import { useRouter } from 'next/router'
import { useHotkeys } from 'react-hotkeys-hook'
import {
  Button,
  Select,
  Spinner
} from '@stuller/stullercom/ui'
import { useToggle } from '@stuller/shared/util/react-hooks'
import {
  useGetCmsLookupByTypeQuery,
  type GetCmsLookupByTypeQuery
} from '@stuller/stullercom/data-access/apollo-queries'
import {
  ConsultantModeShortcuts,
  ConsultantModeShortcutsModal
} from './ConsultantModeShortcuts'
import {
  ConsultantModeHelpModal
} from './ConsultantModeHelp'

interface QuickLinksProps {
  /**
   * Quicklinks data
   */
  data: GetCmsLookupByTypeQuery | undefined
}

/**
 * Quicklinks select
 */
function QuickLinks ({ data }: QuickLinksProps): ReactElement | null {
  const router = useRouter()

  if (data == null) {
    return null
  }

  /**
   * Handle on change event for dropdown
   */
  async function handleSelectChange (event: ChangeEvent<HTMLSelectElement>): Promise<void> {
    await router.push(event.target.value)
  }

  return (
    <Select size='sm' onChange={handleSelectChange} title='Quicklinks'>
      <option value=''>Select an Option</option>
      <option value='/consultant/searchstep1' className='d-md-none'>Change Account</option>
      <option value='/consultant/searchstep4' className='d-md-none'>Account Summary</option>
      {data?.cmsLookupsByType.map(l => (<option key={l.id} value={l.code}>{l.value}</option>))}
    </Select>
  )
}

/**
 * Current consultant quick bar
 */
function ConsultantModeQuickBar (): ReactElement {
  const [shortcutsModalOpen, handleToggleShortcutsModal] = useToggle()
  const [helpModalOpen, handleToggleHelpModal] = useToggle()
  const { data, loading } = useGetCmsLookupByTypeQuery({
    variables: { type: 'DOTQuickLinks' }
  })

  useHotkeys('shift+?', handleToggleShortcutsModal)

  return (
    <>
      <div className='bg-gray-800'>
        <div className='container-xxxl fs-3 fw-light text-white py-1 px-4'>
          {loading
            ? (
              <div className='row align-items-center justify-content-center py-1'>
                <div className='col-auto'>
                  <Spinner size='sm' color='white' />
                </div>
              </div>)
            : (
              <div className='row align-items-center justify-content-between'>
                <div className='col-auto d-none d-md-inline py-2'>
                  <div className='row align-items-center'>
                    <div className='col-auto'>
                      Helpful Links
                    </div>
                    <div className='col-auto'>
                      <Button href='/consultant/searchstep1' tag='a' color='primary' size='sm'>
                        Change Account
                      </Button>
                    </div>
                    <div className='col-auto'>
                      <Button onClick={handleToggleHelpModal} color='primary' size='sm'>
                        Help
                      </Button>
                    </div>
                    <div className='col-auto'>
                      <Button href='/consultant/searchstep4' tag='a' color='primary' size='sm'>
                        Account Summary
                      </Button>
                    </div>
                    <div className='col-auto'>
                      <Button onClick={handleToggleShortcutsModal} color='light' size='sm' outline>
                        Keyboard Shortcuts
                      </Button>
                    </div>
                  </div>
                </div>
                <div className='col-auto px-0 py-2'>
                  <div className='row align-items-center mx-0 px-0'>
                    <div className='col-auto d-md-none d-lg-flex'>
                      QuickLinks
                    </div>
                    <div className='col-auto me-0 pe-0'>
                      <QuickLinks data={data} />
                    </div>
                  </div>
                </div>
              </div>)}
        </div>
      </div>
      <ConsultantModeShortcuts />
      {!loading &&
        <>
          <ConsultantModeShortcutsModal isOpen={shortcutsModalOpen} onToggle={handleToggleShortcutsModal} />
          <ConsultantModeHelpModal isOpen={helpModalOpen} onToggle={handleToggleHelpModal} />
        </>}
    </>
  )
}

export {
  ConsultantModeQuickBar
}
