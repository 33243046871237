import { type ReactElement, useContext } from 'react'
import { Loading } from '@stuller/stullercom/ui'
import { CartPopoverContext } from './CartPopoverContext'
import { CartList } from './CartList'
import { CartError } from './CartError'

/**
 * The inner content of the cart popover that handles cart query results
 */
function CartPopoverInner (): ReactElement {
  const { cart, cartItemCount, scheduledOrderCancel } = useContext(CartPopoverContext)

  if (!cartItemCount.loading && !cart.loading &&
    (cartItemCount.error != null || cart.error != null || cart.data == null)) {
    return <CartError />
  }

  return (
    <Loading
      loading={cartItemCount.loading || cart.loading || scheduledOrderCancel.loading || cart.data == null}
      className='px-3 pb-4'
      spinnerClassName='pb-4'
    >
      {(cart.previousData != null || cart.data != null) && (
        <CartList />
      )}
    </Loading>
  )
}

export {
  CartPopoverInner
}
