import { useCallback, useEffect, useRef, useState } from 'react'
import { type MultiItemCarouselContextType } from './MultiItemCarouselContext'
import { useEventListener, useResizeObserver } from '@stuller/shared/util/react-hooks'
import { useDrag } from '@use-gesture/react'

type UseMultiItemCarousel = MultiItemCarouselContextType

/**
 * State hook for `MultiItemCarousel`
 */
function useMultiItemCarousel (): UseMultiItemCarousel {
  const multiItemCarouselInnerRef = useRef<HTMLDivElement>(null)
  const [index, setIndex] = useState(0)
  const [maxIndex, setMaxIndex] = useState(0)

  // Sets the current number of slides
  const updateMaxIndex = useCallback(() => {
    if (multiItemCarouselInnerRef.current != null) {
      const scrollWidth = multiItemCarouselInnerRef.current.scrollWidth
      const clientWidth = multiItemCarouselInnerRef.current.clientWidth
      if (scrollWidth != null && clientWidth != null && clientWidth !== 0) {
        setMaxIndex(Math.ceil(scrollWidth / clientWidth) - 1)
      }
    }
  }, [])

  // Update max index when the scroll container is resized
  useResizeObserver(updateMaxIndex, multiItemCarouselInnerRef)

  // Update max index on start
  useEffect(() => {
    updateMaxIndex()
  }, [updateMaxIndex])

  // Update the index when needed
  const updateIndex = useCallback(() => {
    if (multiItemCarouselInnerRef.current != null) {
      const scrollLeft = multiItemCarouselInnerRef.current.scrollLeft
      const clientWidth = multiItemCarouselInnerRef.current.clientWidth
      const scrollWidth = multiItemCarouselInnerRef.current.scrollWidth - multiItemCarouselInnerRef.current.clientWidth
      let newIndex = Math.floor(scrollLeft / clientWidth)
      const atEnd = (Math.ceil(scrollLeft) >= scrollWidth && scrollLeft !== 0)

      if (atEnd) {
        newIndex = maxIndex
      }

      setIndex(newIndex)
    }
  }, [multiItemCarouselInnerRef, maxIndex])

  // Update index on scroll
  useEventListener('scroll', updateIndex, multiItemCarouselInnerRef)

  // Handle index
  const handleIndex = useCallback((newIndex: number) => {
    if (multiItemCarouselInnerRef.current != null && newIndex >= 0 && newIndex <= maxIndex) {
      multiItemCarouselInnerRef.current.scrollLeft = multiItemCarouselInnerRef.current.clientWidth * newIndex
    }
  }, [multiItemCarouselInnerRef, maxIndex])

  // Handle previous
  const handlePrev = useCallback(() => {
    if (multiItemCarouselInnerRef.current != null) {
      if (index > 0) {
        multiItemCarouselInnerRef.current.scrollLeft = multiItemCarouselInnerRef.current.clientWidth * (index - 1)
      } else {
        multiItemCarouselInnerRef.current.scrollLeft = multiItemCarouselInnerRef.current.scrollWidth
      }
    }
  }, [multiItemCarouselInnerRef, index])

  // Handle next
  const handleNext = useCallback(() => {
    if (multiItemCarouselInnerRef.current != null) {
      if (index < maxIndex) {
        multiItemCarouselInnerRef.current.scrollLeft = multiItemCarouselInnerRef.current.clientWidth * (index + 1)
      } else {
        multiItemCarouselInnerRef.current.scrollLeft = 0
      }
    }
  }, [multiItemCarouselInnerRef, index, maxIndex])

  // Handle dragging touch events with the useDrag hook from use-gesture
  useDrag(({ delta: [x], direction: [dx] }) => {
    if (dx != null && dx !== 0) {
      multiItemCarouselInnerRef?.current?.scrollBy({ left: -x, behavior: 'instant' })
    }
  }, {
    target: multiItemCarouselInnerRef,
    filterTaps: true,
    preventDefault: true,
    eventOptions: { capture: true, passive: false }
  })

  return {
    index,
    maxIndex,
    handleIndex,
    handleNext,
    handlePrev,
    multiItemCarouselInnerRef
  }
}

export {
  useMultiItemCarousel
}
