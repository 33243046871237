import { createContext, type Dispatch, type SetStateAction } from 'react'
import { type LayoutContextProps } from './propsTypes'
import { type LayoutContextState } from './stateTypes'

const layoutContextPropsDefault: LayoutContextProps = {}

const layoutContextStateDefault: LayoutContextState = {
  marketRatesFooterShown: false
}

export interface LayoutContextType {
  /**
   * The layout props that are provided to layout components via `LayoutContext`
   */
  layoutProps: LayoutContextProps
  /**
   * Sets the layout props on context
   */
  setLayoutProps: Dispatch<SetStateAction<LayoutContextProps>>
  /**
   * Layout state to share amoungst other parts of the layout and app via `LayoutContext`
   */
  layoutState: LayoutContextState
  /**
   * Sets the layout state on context
   */
  setLayoutState: Dispatch<SetStateAction<LayoutContextState>>
}

const LayoutContext = createContext<LayoutContextType>({
  layoutProps: {},
  setLayoutProps: () => {},
  layoutState: layoutContextStateDefault,
  setLayoutState: () => {}
})

export {
  LayoutContext,
  layoutContextPropsDefault,
  layoutContextStateDefault
}
