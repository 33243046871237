import type { TargetingMap } from '@stuller/contentstack/util/types'
import { useGetActiveCategoriesByIdsQuery } from '@stuller/stullercom/data-access/apollo-queries'
import { useTargetingPreviewer } from './targetingPreviewer'

/**
 * Hook to check if a web user is excluding a specific category
 */
function useCategoryExcludeTargeting (targeting: TargetingMap['CATEGORY_EXCLUDE']): boolean {
  const [targetingPreviewer] = useTargetingPreviewer()
  const { data } = useGetActiveCategoriesByIdsQuery({
    variables: {
      ids: targeting.targetValue
    },
    ssr: true
  })

  return targetingPreviewer?.excludedCategory != null
    ? targetingPreviewer?.excludedCategory !== targeting.targetValue
    : data?.activeCategoriesByIds?.length != null
      ? data?.activeCategoriesByIds?.length > 0
      : false
}

export { useCategoryExcludeTargeting }
