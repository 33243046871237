import { type ComponentRef, type ReactElement, useState } from 'react'
import Link from 'next/link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Spinner } from '@stuller/stullercom/ui'
import { useToggle } from '@stuller/shared/util/react-hooks'
import { useGetCartItemCountQuery } from '@stuller/stullercom/data-access/apollo-queries'

/**
 * Cart link and item count
 */
function Cart (): ReactElement {
  const { data, loading } = useGetCartItemCountQuery({
    fetchPolicy: 'cache-and-network',
    pollInterval: 300000
  })
  const cartItemCount = data?.cartItemCount

  return (
    <div className='col-auto'>
      <Link href='/cart' className='link-dark'>
        View Cart
        {loading
          ? <Spinner size='xs' className='ms-2' />
          : cartItemCount != null && <>- {cartItemCount} item{cartItemCount !== 1 ? 's' : ''}</>}
        <FontAwesomeIcon icon={fas.faCartShopping} className='ps-2' />
      </Link>
    </div>
  )
}

/**
 * Checkout link
 */
function Checkout (): ReactElement {
  return (
    <div className='col-auto'>
      <Link href='/checkout' className='link-dark'>Checkout</Link>
    </div>
  )
}

/**
 * My account link and dropdown with additional links
 */
function Account (): ReactElement {
  const [open, handleToggle, handleOpenChange] = useToggle()
  const [reference, setReference] = useState<ComponentRef<typeof DropdownToggle> | null>(null)

  return (
    <div className='col-auto'>
      <Dropdown
        open={open}
        onToggle={handleToggle}
        onOpenChange={handleOpenChange}
        reference={reference}
        hover
      >
        <DropdownToggle href='/myaccount' tag='a' color='anchor-dark' ref={setReference}>
          My Account
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem tag='a' href='/myaccount'>Account Settings</DropdownItem>
          <DropdownItem tag='a' href='/orders'>View Orders</DropdownItem>
          <DropdownItem tag='a' href='/receivables'>View Invoices</DropdownItem>
          <DropdownItem tag='a' href='/favorite'>Favorite Items</DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  )
}

/**
 * Logout link
 */
function Logout (): ReactElement {
  return (
    <div className='col-auto'>
      <Link href='/logout' className='link-dark'>Logout</Link>
    </div>
  )
}

/**
 * Sterling utility nav divider
 */
function Divider (): ReactElement {
  return <div className='col-auto px-0 text-body-secondary'>|</div>
}

/**
 * Sterling utility nav
 */
function UtilityNavSterling (): ReactElement {
  return (
    <header className='bg-white m-0 d-print-none'>
      <div className='container-xxxl px-4'>
        <div className='row justify-content-end pt-2 pb-3'>
          <Cart />
          <Divider />
          <Checkout />
          <Divider />
          <Account />
          <Divider />
          <Logout />
        </div>
      </div>
    </header>
  )
}

export {
  UtilityNavSterling
}
