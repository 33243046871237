/**
 * Returns a string that has been encoded for use in a URL with RFC3986 rules
 */
function encodeRFC3986URIComponent (value: string): string {
  return encodeURIComponent(value).replace(
    /[!'()*]/g,
    (c) => `%${c.charCodeAt(0).toString(16).toUpperCase()}`
  )
}

export {
  encodeRFC3986URIComponent
}
