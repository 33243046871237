import {
  forwardRef,
  type ReactNode,
  type CSSProperties,
  useImperativeHandle,
  useRef
} from 'react'
import clsx from 'clsx'
import { type ScrollOrientation, useScrollState } from '@stuller/shared/util/react-hooks'

export interface ShadowContainerProps {
  /**
   * Id of element
   */
  id?: string
  /**
   * Additional class name(s) to give to the container element
   */
  className?: string
  /**
   * Additional class name(s) to give to the shadow containing element
   */
  shadowClassName?: string
  /**
   * Inline styles to pass to the containing element
   */
  style?: CSSProperties
  /**
   * Children of element
   */
  children?: ReactNode
  /**
   * Scroll orientation
   */
  orientation?: ScrollOrientation
  /**
   * Hides the scrollbar (good for carousels)
   */
  hideScrollbar?: boolean
  /**
   * Reverts to the browser's default scrollbar style
   */
  defaultScrollbar?: boolean
}

/**
 * A scrolling container that shows shadows at the start/end when there is extra scrolling accordingly.
 *
 * For the vertical orientation, you need to specify a height for the container.
 *
 * **Note**: `id`, `className`, `style`, etc. all apply to the inner scroll container, not the outter shadow container.
 */
const ShadowContainer = forwardRef<HTMLDivElement, ShadowContainerProps>(({
  className,
  shadowClassName,
  style,
  children,
  orientation = 'vertical',
  hideScrollbar = false,
  defaultScrollbar = false,
  ...otherAttributes
}, ref) => {
  const innerRef = useRef<HTMLDivElement>(null)
  // Expose innerRef, must use non-null assertion because signature requires it and conditional is not allowed
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  useImperativeHandle(ref, () => innerRef.current!, [])
  const { atStart, atEnd } = useScrollState(innerRef, { orientation })
  const shadowClassNames = clsx(
    'shadow-container',
    `shadow-container-${orientation}`,
    atStart && 'shadow-container-start',
    atEnd && 'shadow-container-end',
    shadowClassName
  )
  const classNames = clsx(
    'shadow-container-inner scroll-behavior-smooth',
    hideScrollbar ? 'overflow-hidden' : orientation === 'horizontal' ? 'overflow-x-auto' : 'overflow-y-auto',
    !defaultScrollbar && 'scrollbar',
    className
  )

  return (
    <div className={shadowClassNames}>
      <div
        ref={innerRef}
        className={classNames}
        style={{ ...style }}
        {...otherAttributes}
      >
        {children}
      </div>
    </div>
  )
})
ShadowContainer.displayName = 'ShadowContainer'

export {
  ShadowContainer
}
