import { createContext } from 'react'
import { type Auth } from './types'

const AuthContext = createContext<Auth>({
  authUser: null,
  hasRole: () => false,
  host: '',
  isSterling: false,
  isShowcase: false,
  isJewelerShowcase: false,
  isEmbeddedJewelerShowcase: false,
  isJewelerShowcasePunchout: false,
  isStullerComShowcaseMode: false,
  sessionId: '',
  showcaseSettings: null,
  getCustomShowcaseSetting: () => null
})

export {
  AuthContext
}
