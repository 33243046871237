/**
 * Determines if the supplied path should be ignored for authentication purposes, irrespective of site mode
 */
function isUnauthenticatedPath (path?: string): boolean {
  if (path === undefined) {
    return false
  }

  const urlsToIgnore = [
    '/logout/',
    '/logout',
    '/forgot/reset/',
    '/forgot/reset',
    '/forgot/',
    '/forgot',
    '/forgot/password/',
    '/forgot/password',
    '/forcechangepassword/',
    '/forcechangepassword',
    '/showcaselogin/',
    '/showcaselogin',
    '/utility/safarifix/',
    '/utility/safarifix'
  ]

  for (const url of urlsToIgnore) {
    if (path.toLowerCase() === url) {
      return true
    }
  }

  return path.toLowerCase().startsWith('/cms/public')
}

export {
  isUnauthenticatedPath
}
