import {
  forwardRef,
  type ReactNode,
  useMemo
} from 'react'
import clsx from 'clsx'
import { DropdownContext, type DropdownContextType } from '../../..'

export interface DropdownProps extends Omit<DropdownContextType, 'type'> {
  /**
   * Id of element
   */
  id?: string
  /**
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /**
   * Children of element
   */
  children?: ReactNode
  /**
   * Indicates the dropdown is part of a button group
   */
  group?: boolean
  /**
   * Size of button non-default
   */
  size?: 'sm' | 'lg'
}

/**
 * Dropdown to toggle contextual overlays for display lists of links and more.
 *
 * For easy dropdown state management use `useToggle`.
 *
 * Dropdowns use `Popover` internally with stripped down options to keep the API simple.
 * The `placement` option is sent directly through to `Popover` and the dropdown type is determined from the it.
 * They can contain any element, but it is recommended to use `DropdownItem` for buttons/links, `DropdownText` for plain text, and `DropdownDivider` for dividers.
 */
const Dropdown = forwardRef<HTMLDivElement, DropdownProps>(({
  className,
  children,
  group = false,
  size,
  open = false,
  onToggle,
  onOpenChange,
  reference = null,
  placement = 'bottom-start',
  offset = 2,
  shift = true,
  hover = false,
  dismiss = true,
  ...otherAttributes
}, ref) => {
  const type: DropdownContextType['type'] = placement.startsWith('bottom')
    ? 'dropdown'
    : placement.startsWith('top')
      ? 'dropup'
      : placement.startsWith('right')
        ? 'dropend'
        : 'dropstart'
  const context = useMemo(() => ({
    open,
    onToggle,
    onOpenChange,
    reference,
    placement,
    type,
    offset,
    shift,
    hover,
    dismiss
  }), [open, onToggle, onOpenChange, reference, placement, type, offset, shift, hover, dismiss])
  const classNames = clsx(
    type,
    group && 'btn-group',
    size != null && `btn-group-${size}`,
    className
  )

  return (
    <DropdownContext.Provider value={context}>
      <div
        className={classNames}
        ref={ref}
        {...otherAttributes}
      >
        {children}
      </div>
    </DropdownContext.Provider>
  )
})
Dropdown.displayName = 'Dropdown'

export {
  Dropdown
}
