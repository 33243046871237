import { type CSSProperties, type ReactNode, forwardRef } from 'react'
import clsx from 'clsx'
import { type ThemeColor } from '../../..'

export interface TableProps {
  /**
   * Id of element
   */
  id?: string
  /**
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /**
   * Inline styles to pass to the containing element
   */
  style?: CSSProperties
  /**
   * Children of element
   */
  children?: ReactNode
  /**
   * Theme color for table (background color)
   */
  color?: ThemeColor
  /**
   * Adds borders to all sides the table and cells
   */
  bordered?: boolean
  /**
   * Removes all borders from the table
   */
  borderless?: boolean
  /**
   * Makes each row striped
   */
  striped?: true | 'columns'
  /**
   * Hover effect on rows
   */
  hover?: boolean
  /**
   * Size of the table to change padding
   */
  size?: 'sm'
  /**
   * Indicates to make sure the table is responsive, allowing it to be scrolled horizontally (always or up to certain breakpoint)
   */
  responsive?: true | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl'
  /**
   * Alternate position of the table caption
   */
  caption?: 'top'
  /**
   * Add rounded corners to all sides of the table
   */
  rounded?: boolean
}

/**
 * Table component to help style tables
 *
 * Due to the widespread use of `<table>` elements across third-party widgets like calendars and date pickers, Bootstrap’s tables are opt-in.
 * We add the base class `.table` to the `<Table>` component, then extend with modifier classes or custom styles.
 * All table styles are not inherited in Bootstrap, meaning any nested tables can be styled independent from the parent.
 *
 * Currently we only supply a `Table` component and no `tr`, `td`, `th`, etc., but we can add later if we feel it's needed.
 */
const Table = forwardRef<HTMLTableElement, TableProps>(({
  className,
  children,
  color,
  bordered = false,
  borderless = false,
  striped,
  hover = false,
  size,
  responsive,
  caption,
  rounded = false,
  ...otherAttributes
}, ref) => {
  const classNames = clsx(
    'table',
    rounded && 'table-rounded',
    color != null && `table-${color}`,
    bordered && 'table-bordered',
    borderless && 'table-borderless',
    striped != null && (striped === true ? 'table-striped' : `table-striped-${striped}`),
    hover && 'table-hover',
    size != null && `table-${size}`,
    caption != null && `caption-${caption}`,
    className
  )

  const table = (
    <table
      className={classNames}
      ref={ref}
      {...otherAttributes}
    >
      {children}
    </table>
  )

  if (responsive != null) {
    return (
      <div className={responsive === true ? 'table-responsive' : `table-responsive-${responsive}`}>
        {table}
      </div>
    )
  }

  return table
})
Table.displayName = 'Table'

export {
  Table
}
