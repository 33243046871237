import { getSerializedDiamondPageInitialProps } from './getSerializedDiamondInitialProps'
import { NaturalSerializedDiamondSellingInfo } from './NaturalSerializedDiamondSellingInfo'
import { SerializedDiamondDetails } from './SerializedDiamondDetails'
import { SerializedDiamondPageHeader } from './SerializedDiamondPageHeader'
import { SerializedDiamondSpecialRequest } from './SerializedDiamondSpecialRequest'
import type { SerializedDiamondPageType } from '../types'

/**
 * The Page that will render the content entries with the Content Page content type
 */
const SerializedDiamondPage: SerializedDiamondPageType = ({ serializedDiamond, canViewDiamondRebate }) => {
  // TODO: remove these logs
  console.log('diamond data', serializedDiamond)
  console.log('canViewDiamondRebate', canViewDiamondRebate)

  return (
    <div className='container-xxxl mw-xxl px-4'>
      <SerializedDiamondPageHeader serializedDiamond={serializedDiamond} />
      <SerializedDiamondDetails serializedDiamond={serializedDiamond} />
      {serializedDiamond?.isLabGrown === false &&
        <NaturalSerializedDiamondSellingInfo serializedDiamond={serializedDiamond} canViewDiamondRebate={canViewDiamondRebate} />}
      <SerializedDiamondSpecialRequest serializedDiamond={serializedDiamond} />
    </div>
  )
}

SerializedDiamondPage.getInitialProps = getSerializedDiamondPageInitialProps

export {
  SerializedDiamondPage
}
