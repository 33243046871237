import { type ReactElement, memo } from 'react'
import Link from 'next/link'
import clsx from 'clsx'

interface ShowcaseMarketRateProps {
  /**
   * The name of the market rate
   */
  name: string
  /**
   * The current market rate amount
   */
  currentRate: number
  /**
   * The previous market rate amount
   */
  previousRate: number
  /**
   * The url to view more info about market rates
   */
  url: string
  /**
   * Icon url to display
   */
  icon: string
}

/**
 * Single showcase market rate
 */
const ShowcaseMarketRate = memo(({
  name,
  currentRate,
  previousRate,
  url,
  icon
}: ShowcaseMarketRateProps): ReactElement => {
  const classNames = clsx(
    'fw-normal ps-2',
    currentRate >= previousRate ? 'text-success' : 'text-danger'
  )

  return (
    <div className='col-auto px-3 pb-2'>
      <img src={icon} alt={name} className='pe-3' />
      {name}:
      <Link
        className={classNames}
        href={url}
        target='_blank'
        rel='noreferrer'
      >
        {currentRate.toFixed(2)}
      </Link>
    </div>
  )
})
ShowcaseMarketRate.displayName = 'ShowcaseMarketRate'

export {
  ShowcaseMarketRate
}
