import { type ReactElement } from 'react'
import { COMPANY_PHONE_NUMBER } from '@stuller/shared/util/constants'
import { useAuth } from '@stuller/stullercom/feat/auth'

/**
 * Contact info for error
 */
function ErrorContact (): ReactElement | null {
  const { authUser, isShowcase } = useAuth()

  if (isShowcase) {
    return null
  }

  return (
    <p>
      Still can't find what you're looking for?<br />
      {authUser != null
        ? <>Use Live Chat at the bottom right corner or call <a href={`tel:1-${COMPANY_PHONE_NUMBER}`}>{COMPANY_PHONE_NUMBER}</a>.</>
        : <>Call <a href={`tel:1-${COMPANY_PHONE_NUMBER}`}>{COMPANY_PHONE_NUMBER}</a>.</>}
    </p>
  )
}

export {
  ErrorContact
}
