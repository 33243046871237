import { memo, type ReactElement } from 'react'
import { useAuth } from '@stuller/stullercom/feat/auth'
import { FooterSterling } from './FooterSterling'
import { FooterStullerCom } from './FooterStullerCom'

/**
 * Footer nav from CMS
 */
const FooterNav = memo((): ReactElement | null => {
  const { isShowcase, isSterling } = useAuth()

  if (isShowcase) {
    return null
  }

  if (isSterling) {
    return <FooterSterling />
  }

  return <FooterStullerCom />
})
FooterNav.displayName = 'FooterNav'

export {
  FooterNav
}
