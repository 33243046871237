import { type ReactElement } from 'react'
import { Badge } from '@stuller/stullercom/ui'
import { useGetCartItemCountQuery } from '@stuller/stullercom/data-access/apollo-queries'

/**
 * Shows the number of cart items
 */
function CartBadge (): ReactElement | null {
  const { data } = useGetCartItemCountQuery({
    fetchPolicy: 'cache-and-network',
    pollInterval: 300000,
    notifyOnNetworkStatusChange: true
  })
  const cartItemCount = data?.cartItemCount

  if (cartItemCount == null || cartItemCount === 0) {
    return null
  }

  return (
    <Badge color='green-300'>
      <span data-test='cart-count'>{cartItemCount}</span>
      <span className='visually-hidden'>Cart items</span>
    </Badge>
  )
}

export {
  CartBadge
}
