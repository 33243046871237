import { useContext } from 'react'
import { LayoutContext, type LayoutContextType } from './LayoutContext'

/**
 * Hook to fetch Layout information
 */
function useLayout (): LayoutContextType {
  return useContext(LayoutContext)
}

export { useLayout }
