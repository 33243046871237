import {
  type ReactNode,
  forwardRef,
  type ComponentPropsWithoutRef
} from 'react'
import clsx from 'clsx'

type FormTagProps = ComponentPropsWithoutRef<'form'>

interface FormProps extends FormTagProps {
  /**
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /**
   * Form element children
   */
  children?: ReactNode
  /**
   * Turns off form validation via `noValidate`
   * This is defaulted to `true` because we do custom validations
   */
  noValidate?: boolean
  /**
   * Indicates the form was validated by checking `form.checkValidity` (requires `noValidate`)
   * This works by styling `:invalid` and `:valid` that are applied
   */
  validated?: boolean
}

/**
 * A `<form>` element to contain all form inputs/buttons etc.
 * The `onSubmit` event should be used on the `Form` component along with a `<Button type='submit'>` to submit the form.
 *
 * Every group of form fields should reside in a `FormGroup` component.
 * This component can be supplied a `groupId` to configure label `for` and input `id`.
 * All input components should implement the `FormGroupContext` context to get the `groupId` and set `id`.
 *
 * Use `mb-4` on form groups to add the correct vertical spacing between them.
 *
 * Remember to use `value` or `checked` for controlled components.
 */
const Form = forwardRef<HTMLFormElement, FormProps>(({
  className,
  children,
  noValidate = true,
  validated = false,
  ...otherAttributes
}, ref) => {
  const classNames = clsx(
    validated && 'was-validated',
    className
  )

  return (
    <form
      className={classNames}
      noValidate={noValidate}
      ref={ref}
      {...otherAttributes}
    >
      {children}
    </form>
  )
})
Form.displayName = 'Form'

export {
  Form
}
