import type { TargetingMap } from '@stuller/contentstack/util/types'
import { useAuth } from '@stuller/stullercom/feat/auth'
import { useTargetingPreviewer } from './targetingPreviewer'

/**
 * Hook to check if a ship to account includes a specific price list
 */
function usePricelistTargeting (targeting: TargetingMap['PRICE_LIST']): boolean {
  const [targetingPreviewer] = useTargetingPreviewer()
  const { authUser } = useAuth()
  const priceList = authUser?.user?.defaultActiveShipToAccount?.priceList?.id

  // If the user is not signed in, check against the tools and supplies pricelist ID (6020)
  if (authUser == null) {
    return targeting.targetValue.includes('6020')
  }

  // If there’s no default shipto, return false
  return targetingPreviewer?.priceList != null
    ? targeting.targetValue.includes(targetingPreviewer?.priceList)
    : priceList == null
      ? false
      : targeting.targetValue.includes(priceList)
}

export { usePricelistTargeting }
