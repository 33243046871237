import { forwardRef, useMemo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'
import { Alert, AlertIcon, type AlertProps } from '../../..'
import { type IconDefinition } from '@fortawesome/fontawesome-common-types'

export interface ToastProps extends Pick<AlertProps, 'id' | 'className' | 'style' | 'children' | 'color'> {
  /**
   * Ignore, sent from `react-toastify`
   */
  toastProps?: any
  /**
   * Ignore, sent from `react-toastify`
   */
  closeToast?: any
  /**
   * Override the icon for the toast, send `false` to remove the icon
   */
  icon?: false | IconDefinition
}

/**
 * Provide toast messages for user actions and async updates.
 *
 * See `useToast` to use a toast.
 */
const Toast = forwardRef<HTMLDivElement, ToastProps>(({
  color,
  children,
  icon: iconIn,
  toastProps,
  closeToast,
  ...otherAttributes
}, ref) => {
  const icon = useMemo(() => {
    if (iconIn != null) {
      return iconIn === false ? null : iconIn
    }

    switch (color) {
      case 'success':
        return fas.faCircleCheck
      case 'danger':
        return fas.faCircleExclamation
      case 'warning':
        return fas.faTriangleExclamation
      default:
        return fas.faInfoCircle
    }
  }, [color, iconIn])

  return (
    <Alert
      color={color}
      transparent
      ref={ref}
      {...otherAttributes}
    >
      <div className='row align-items-center g-3'>
        {icon != null && (
          <div className='col-auto'>
            <AlertIcon>
              <FontAwesomeIcon icon={icon} />
            </AlertIcon>
          </div>)}
        <div className='col pe-0'>
          {children}
        </div>
      </div>
    </Alert>
  )
})
Toast.displayName = 'Toast'

export {
  Toast
}
