import { v4 as uuidv4 } from 'uuid'
import { parse as cookieParse, serialize as cookieSerialize, type CookieSerializeOptions } from 'cookie'
import Cookies from 'js-cookie'
import { type NextPageContext } from 'next'
import { throwIfNull } from '@stuller/shared/util/core'

const CART_SESSION_COOKIE_NAME = 'cart_session'

/**
 * Gets the cookie options regardless of client/server-side
 */
function getCookieOptions (): CookieSerializeOptions & Cookies.CookieAttributes {
  const expires = new Date()
  expires.setFullYear(expires.getFullYear() + 1)

  return {
    expires,
    path: '/',
    secure: true,
    sameSite: 'none'
  }
}

/**
 * Method to be used on the server-side to generate the cart session id cookie if it doesn't exist
 */
function setServerSideCartSessionId ({ req, res }: NextPageContext): void {
  req = throwIfNull(req, 'Request cannot be null')
  res = throwIfNull(res, 'Response cannot be null')

  const cookies = cookieParse(req.headers.cookie ?? '')
  if (cookies[CART_SESSION_COOKIE_NAME] != null) {
    return
  }

  let currentRespCookies = res.getHeader('Set-Cookie')
  if (!Array.isArray(currentRespCookies)) {
    currentRespCookies = currentRespCookies == null ? [] : [String(currentRespCookies)]
  }

  const cookieOptions = getCookieOptions()
  const sessionId = uuidv4()
  currentRespCookies.push(cookieSerialize(CART_SESSION_COOKIE_NAME, sessionId, cookieOptions))
  res.setHeader('Set-Cookie', currentRespCookies)
}

/**
 * Method to be used on the client-side to generate the cart session id cookie if it doesn't exist
 */
function setClientSideCartSessionId (): void {
  if (Cookies.get(CART_SESSION_COOKIE_NAME) != null) {
    return
  }

  const cookieOptions = getCookieOptions()
  const sessionId = uuidv4()
  Cookies.set(CART_SESSION_COOKIE_NAME, sessionId, cookieOptions)
}

/**
 * Ensures a cart session id cookie exists
 * If a new cookie is issued, it will have a 1 year expiration
 * Can be called from the client or server-side
 */
function ensureCartSessionId (ctx: NextPageContext): void {
  if (ctx.req != null) {
    setServerSideCartSessionId(ctx)
  } else {
    setClientSideCartSessionId()
  }
}

export {
  ensureCartSessionId
}
