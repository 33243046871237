import { STULLER_EVENT_PREFIX, stullerEvents } from './constants'
import { type StullerEvents } from './types'

/**
 * Helper function to dispatch Stuller events
 */
function dispatchStullerEvent<T extends keyof StullerEvents> (eventName: T, detail?: StullerEvents[T]): boolean {
  if (!(eventName in stullerEvents)) {
    throw new Error(`dispatchStullerEvent received an invalid eventName: ${eventName}`)
  }

  return (document.currentScript?.parentElement ?? window).dispatchEvent(new CustomEvent(`${STULLER_EVENT_PREFIX}${eventName}`, { cancelable: true, bubbles: true, detail }))
}

export {
  dispatchStullerEvent
}
