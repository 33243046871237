import { forwardRef, type ElementType } from 'react'
import { type ThemeColor, type Color } from '../../types/bootstrap'
import { type ProductStoneShape, productStoneShapeIconMap } from './product-stone-shape-map'

export interface ProductStoneShapeIconProps {
  /**
   * The shape "key" of the stone to display.
   */
  shape: ProductStoneShape
  /**
   * The size of the icon in pixels.
   */
  size?: number
  /**
   * The background color of the icon.
   */
  backgroundColor?: ThemeColor | Color
  /**
   * The background color of the icon when hovered.
   */
  backgroundHoverColor?: ThemeColor | Color
  /**
   * The shadow color of the icon.
   */
  shadowColor?: ThemeColor | Color
  /**
   * The shadow color of the icon when hovered.
   */
  shadowHoverColor?: ThemeColor | Color
  /**
   * The outline color of the icon.
   */
  outlineColor?: ThemeColor | Color
  /**
   * The outline color of the icon when hovered.
   */
  outlineHoverColor?: ThemeColor | Color
}

/**
 * A Stone Shape Icon is an svg icon visual representation of a stone shape.
 */
const ProductStoneShapeIcon = forwardRef<SVGElement, ProductStoneShapeIconProps>(({
  shape,
  size = 50,
  backgroundColor = 'white',
  backgroundHoverColor = backgroundColor,
  shadowColor = 'gray-100',
  shadowHoverColor = shadowColor,
  outlineColor = 'gray-500',
  outlineHoverColor = outlineColor,
  ...otherAttributes
}, ref) => {
  if (!(shape in productStoneShapeIconMap)) {
    return null
  }

  const Icon = productStoneShapeIconMap[shape] as ElementType

  return (
    <Icon
      className='svg-stone-icon'
      ref={ref}
      style={{
        '--svg-stone-icon-background': `var(--bs-${backgroundColor})`,
        '--svg-stone-icon-background-hover': `var(--bs-${backgroundHoverColor})`,
        '--svg-stone-icon-shadow': `var(--bs-${shadowColor})`,
        '--svg-stone-icon-shadow-hover': `var(--bs-${shadowHoverColor})`,
        '--svg-stone-icon-outline': `var(--bs-${outlineColor})`,
        '--svg-stone-icon-outline-hover': `var(--bs-${outlineHoverColor})`,
        width: size,
        height: size
      }}
      {...otherAttributes}
    />
  )
})
ProductStoneShapeIcon.displayName = 'ProductStoneShapeIcon'

export {
  ProductStoneShapeIcon
}
