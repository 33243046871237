import { type CSSProperties, type ReactNode, forwardRef } from 'react'
import clsx from 'clsx'

export interface ProgressProps {
  /**
   * Id of element
   */
  id?: string
  /**
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /**
   * Inline styles to pass to the containing element
   */
  style?: CSSProperties
  /**
   * Children of element
   */
  children?: ReactNode
  /**
   * Type of progress
   */
  type?: 'circle'
}

/**
 * Progress component to indicate the percent complete in a circle.
 *
 * Note: Bootstrap has it's own progress bar component, if needed, it can be added into this component.
 */
const Progress = forwardRef<HTMLDivElement & HTMLSpanElement, ProgressProps>(({
  className,
  children,
  type = 'circle',
  ...otherAttributes
}, ref) => {
  const classNames = clsx(
    'progress',
    type === 'circle' && 'progress-circle',
    className
  )

  return (
    <div
      className={classNames}
      role='progressbar'
      ref={ref}
      {...otherAttributes}
    >
      {children}
    </div>
  )
})
Progress.displayName = 'Progress'

export {
  Progress
}
