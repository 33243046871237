import { useFetch } from '@stuller/shared/util/react-hooks'
import { useAuth } from '@stuller/stullercom/feat/auth'
import { useMemo } from 'react'
import { buildAlgonomyUrl } from './util'
import { type RecommendationPlacementProps } from './types'

/**
 * Uses the useFetch hook to send a signal to the Algonomy API that a category page was visited
 */
function useCategoryRecommendations (placements: RecommendationPlacementProps[], categoryId: string): void {
  const auth = useAuth()
  const { authUser, sessionId } = auth

  const url = useMemo(() => buildAlgonomyUrl(placements, authUser?.user?.memberId, sessionId, { categoryId }), [placements, categoryId, authUser, sessionId])
  useFetch(url)
}

export {
  useCategoryRecommendations
}
