import { type ReactElement } from 'react'
import clsx from 'clsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'
import { type Notification, type NotificationSettings } from './types'

interface MessageTitleProps {
  important: Notification['important']
  title: Notification['metadata']['title']
  color: NotificationSettings['color']
  textColor: NotificationSettings['textColor']
  icon?: NotificationSettings['icon']
}

/**
 * The title of the message card with icon
 */
function MessageTitle ({ important, title, color, textColor, icon }: MessageTitleProps): ReactElement {
  const classNames = clsx(
    'fw-bold',
    important && 'text-danger',
    `text-${textColor}`
  )
  const iconClassNames = clsx(
    'me-2',
    important ? 'text-danger' : `text-${color}`
  )

  return (
    <>
      {important
        ? <FontAwesomeIcon icon={fas.faTriangleExclamation} className={iconClassNames} />
        : icon != null && <FontAwesomeIcon icon={icon} className={iconClassNames} />}
      <span className={classNames} title={title}>{title}</span>
    </>
  )
}

export {
  MessageTitle
}
