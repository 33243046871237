import { type ReactElement } from 'react'
import type { SerializedDiamond } from '../types'

interface SerializedDiamondDetailsProps {
  /**
   * The serialized diamond to display
   */
  serializedDiamond: SerializedDiamond
}

function SerializedDiamondDetails ({ serializedDiamond }: SerializedDiamondDetailsProps): ReactElement {
  return (
    <p>Details placeholder</p>
  )
}

export {
  SerializedDiamondDetails
}
