import { type ReactElement } from 'react'
import clsx from 'clsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'
import { useAuth } from '@stuller/stullercom/feat/auth'
import { Button } from '@stuller/stullercom/ui'
import { COMPANY_PHONE_NUMBER, OPERATING_HOURS } from '@stuller/shared/util/constants'
import { dispatchStullerEvent } from '@stuller/stullercom/feat/stuller-events'
import { CustomShowcaseSettingDetailType } from '@stuller/stullercom/data-access/apollo-queries'

interface ShowcaseContactLinksProps {
  /**
   * Whether to show the about us link
   */
  showAboutUs: boolean
  /**
   * Whether to show the contact us link
   */
  showContactUs: boolean
}

/**
 * Showcase contact links (only render when showcase and show nav)
 */
function ShowcaseContactLinks ({ showAboutUs, showContactUs }: ShowcaseContactLinksProps): ReactElement {
  const classNames = 'w-100 fw-normal bg-gray-800 border-gray-800 text-gray-200'

  return (
    <>
      {showAboutUs && (
        <div className='row'>
          <div className='col my-2'>
            <Button
              href='/aboutthisjeweler'
              tag='a'
              color='info'
              pill
              className={classNames}
            >
              About Us
            </Button>
          </div>
        </div>)}
      {showContactUs && (
        <div className='row'>
          <div className='col my-2'>
            <Button
              href='/contactthisjeweler'
              tag='a'
              color='info'
              pill
              className={classNames}
            >
              Contact Us
            </Button>
          </div>
        </div>)}
    </>
  )
}

/**
 * Company operating hours
 */
function OperatingHours (): ReactElement {
  return (
    <div className='row'>
      <div className='col text-uppercase text-gray-400 fs-7 text-uppercase lh-sm ls-1 ws-3 text-center mt-3'>
        {OPERATING_HOURS}
      </div>
    </div>
  )
}

/**
 * Live chat button
 */
function LiveChatButton (): ReactElement {
  const { isShowcase } = useAuth()
  const classNames = clsx(
    'w-100 fw-normal text-gray-200',
    isShowcase ? 'bg-gray-800 border-gray-800' : 'bg-cyan-700 border-cyan-700'
  )

  function handleClick (): void {
    dispatchStullerEvent('live-chat-open')
  }

  return (
    <div className='row'>
      <div className='col my-2'>
        <Button
          color='info'
          pill
          className={classNames}
          onClick={handleClick}
        >
          <FontAwesomeIcon icon={fas.faComments} className='text-white me-3 fs-4' />
          Live Chat
        </Button>
      </div>
    </div>
  )
}

/**
 * Call button (only render if not showcase or showcase has a phone number)
 */
function PhoneButton (): ReactElement {
  const { isShowcase, showcaseSettings } = useAuth()
  const classNames = clsx(
    'w-100 fw-normal text-gray-200 my-2',
    isShowcase ? 'bg-gray-800 border-gray-800' : 'bg-cyan-700 border-cyan-700'
  )

  return (
    <div className='row'>
      <div className='col'>
        <Button
          tag='a'
          color='info'
          href={`tel:${showcaseSettings?.phoneNumber ?? `1-${COMPANY_PHONE_NUMBER}`}`}
          pill
          className={classNames}
        >
          <FontAwesomeIcon icon={fas.faPhoneFlip} className='text-cyan-400 me-3 fs-4' />
          {showcaseSettings?.phoneNumber ?? COMPANY_PHONE_NUMBER}
        </Button>
      </div>
    </div>
  )
}

/**
 * The footer at the bottom of the mobile main nav
 */
function MobileFooter (): ReactElement | null {
  const { isShowcase, authUser, showcaseSettings, getCustomShowcaseSetting } = useAuth()
  const classNames = clsx(
    'row g-0',
    isShowcase ? 'bg-gray-700' : 'bg-cyan-500'
  )
  const showShowcasePhone = showcaseSettings?.phoneNumber != null
  const showShowcaseContactLinks = getCustomShowcaseSetting(CustomShowcaseSettingDetailType.HideContactLinksInHeader)?.[0] !== 'true'
  const showShowcaseAboutUs = showcaseSettings?.aboutUsIsChecked === true && showcaseSettings?.aboutUs != null
  const showShowcaseContactUs = showcaseSettings?.contactUsIsChecked === true && showcaseSettings?.contactUs != null

  // Hide footer in showcase if nothing to show
  if (isShowcase && !showShowcasePhone && !(showShowcaseContactLinks && (showShowcaseAboutUs || showShowcaseContactUs))) {
    return null
  }

  return (
    <div className={classNames}>
      <div className='col pb-6 pt-5 mt-2 px-5 mx-2'>
        {(!isShowcase || showShowcasePhone) && <PhoneButton />}
        {!isShowcase && authUser != null && <LiveChatButton />}
        {showShowcaseContactLinks && (
          <ShowcaseContactLinks
            showAboutUs={showShowcaseAboutUs}
            showContactUs={showShowcaseContactUs}
          />)}
        {!isShowcase && <OperatingHours />}
      </div>
    </div>
  )
}

export {
  MobileFooter
}
