import { forwardRef, useContext } from 'react'
import clsx from 'clsx'
import { CarouselContext } from '../../..'

export interface CarouselControlProps {
  /**
   * Id of element
   */
  id?: string
  /**
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /**
   * Direction of the carousel control
   */
  direction?: 'prev' | 'next'
  /**
   * Handle when button is pressed to move index (send `handlePrev` or `handleNext`)
   */
  onClick?: () => void
}

/**
 * Carousel control component for previous and next buttons
 */
const CarouselControl = forwardRef<HTMLButtonElement, CarouselControlProps>(({
  className,
  direction = 'prev',
  onClick,
  ...otherAttributes
}, ref) => {
  const { index, length, sliding } = useContext(CarouselContext)
  const classNames = clsx(
    `carousel-control-${direction}`,
    className
  )
  const iconClassNames = `carousel-control-${direction}-icon`

  // Handle clicking the button to update the current index
  function handleClick (): void {
    if (!sliding) {
      onClick?.()
    }
  }

  // Do not render if length or index is null to prevent errors
  if (length == null || length <= 1 || length === Infinity || index == null) {
    return null
  }

  return (
    <button
      type='button'
      className={classNames}
      onClick={handleClick}
      data-bs-slide={direction}
      ref={ref}
      {...otherAttributes}
    >
      <span className={iconClassNames} aria-hidden='true' />
      <span className='visually-hidden'>{direction}</span>
    </button>
  )
})
CarouselControl.displayName = 'CarouselControl'

export {
  CarouselControl
}
