import { type ReactElement } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'
import { Alert, AlertHeading, AlertIcon } from '@stuller/stullercom/ui'

/**
 * The error message for the cart popover when something goes wrong
 */
function CartCheckoutError (): ReactElement {
  return (
    <Alert color='danger' transparent className='mb-0'>
      <div className='row align-items-center justify-content-center mb-4 flex-nowrap'>
        <div className='col-auto'>
          <AlertIcon size='lg'>
            <FontAwesomeIcon icon={fas.faTriangleExclamation} />
          </AlertIcon>
        </div>
        <div className='col-auto flex-shrink-1'>
          <AlertHeading tag='h3' className='mb-0' data-test='unable-to-checkout-error'>
            Unable to Checkout
          </AlertHeading>
          Resolve or remove the items marked in red to continue
        </div>
      </div>
    </Alert>
  )
}

export {
  CartCheckoutError
}
