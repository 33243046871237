import { memo, type ReactElement } from 'react'
import { CmsContent } from '@stuller/stullercom/feat/cms-content'
import { useAuth } from '@stuller/stullercom/feat/auth'

/**
 * Site wide from CMS
 */
const SiteWide = memo((): ReactElement | null => {
  const { hasRole, isJewelerShowcase, showcaseSettings } = useAuth()

  if (hasRole('Consultant Mode')) {
    return null
  }

  // The layout should not be shown when on an invalid showcase
  if (isJewelerShowcase && showcaseSettings == null) {
    return null
  }

  return (
    <CmsContent
      contentContainerName='site-wide'
      skipLoading
      noRefetch
      data-gtm-click-section='site-wide-banner'
      className='d-print-none'
    />
  )
})
SiteWide.displayName = 'SiteWide'

export {
  SiteWide
}
