import type { Targeting, TargetType } from '@stuller/contentstack/util/types'
import { useContentstackTargeting } from './useContentstackTargeting'

/**
 * this is a workaround for a linting issue stating:
 * React Hook "useContentstackTargeting" may be executed more than once. Possibly because it is called in a loop.
 * React Hooks must be called in the exact same order in every component render
 */
const hookWorkAround = {
  useContentstackTargeting
}

/**
 * Hook to check any, all, and none logic for a grouping of targets
 */
function useGroupTargeting (targetingGroup: Targeting[], targetType: TargetType): boolean {
  let allTargetsPass = true
  let anyTargetsPass = false
  let noTargetsPass = true

  for (const targeting of targetingGroup) {
    const result = hookWorkAround.useContentstackTargeting(targeting)
    allTargetsPass = allTargetsPass ? result : false
    anyTargetsPass = anyTargetsPass ? true : result
    noTargetsPass = noTargetsPass ? !result : false
  }

  switch (targetType) {
    case 'ALL':
      return allTargetsPass
    case 'ANY':
      return anyTargetsPass
    case 'NONE':
      return noTargetsPass
    default:
      return false
  }
}

export { useGroupTargeting }
