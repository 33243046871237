import {
  forwardRef,
  type ReactNode,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
  useCallback,
  type CSSProperties
} from 'react'
import clsx from 'clsx'
import { useEventListener, usePrevious } from '@stuller/shared/util/react-hooks'
import { CarouselContext, type CarouselContextType, type Theme } from '../../..'
import { siteConfig } from '@stuller/stullercom/util/site-config'

const BootstrapCarouselImport = typeof window !== 'undefined' && (siteConfig.NODE_ENV === 'test' ? require('bootstrap/js/dist/carousel') : import('bootstrap/js/dist/carousel'))

export interface CarouselProps extends Omit<CarouselContextType, 'sliding'> {
  /**
   * Id of element
   */
  id?: string
  /**
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /**
   * Theme to use for the component
   */
  theme?: Theme
  /**
   * Inline styles to pass to the containing element
   */
  style?: CSSProperties
  /**
   * Children of element
   */
  children?: ReactNode
  /**
   * Indicates to animate with a fade instead of a slide
   */
  fade?: boolean
  /**
   * Indicates to use the dark variant
   */
  dark?: boolean
  /**
   * Indicates to popout carousel indicators and controls
   */
  popout?: boolean | 'indicators' | 'controls'
  /**
   * The amount of time to delay between automatically cycling an item
   * Set to `false` to turn off automatic cycle
   */
  interval?: number | false
  /**
   * Whether the carousel should react to keyboard events
   */
  keyboard?: boolean
  /**
   * Indicates when to pause the automatic cycling
   * Use 'hover' to pause on hover or leave cycling always on with `false`
   */
  pause?: 'hover' | false
  /**
   * Autoplays the carousel after the user manually cycles the first item (or hovering if `pause` is set to `'hover'`)
   * Set to 'carousel' to play on load.
   */
  ride?: 'carousel' | boolean
  /**
   * Indicates the carousel should cycle continuously or have hard stop
   */
  wrap?: boolean
  /**
   * Indicates the carousel should support left/right swipe interactions on touchscreen devices
   */
  touch?: boolean
  /**
   * Handler called when carousel is sliding
   */
  onSlide?: (event: Event) => void
  /**
   * Handler called when carousel is done sliding
   */
  onSlid?: (event: Event) => void
}

/**
 * A slideshow component for cycling through elements—images or slides of text—like a carousel.
 *
 * For easy carousel state management use `const [index, setIndex, handlePrev, handleNext] = useCounter(0, { max: array.length - 1 })`
 *
 * **Note**: the carousel components are built to work with a specific order as follows:
 *
 * 1. `CarouselInner`
 * 2. `CarouselControl` for 'prev'
 * 3. `CarouselControl` for 'next'
 * 4. `CarouselIndicators`
 */
const Carousel = forwardRef<HTMLDivElement, CarouselProps>(({
  className,
  children,
  index,
  length,
  onChange,
  fade = false,
  theme,
  popout = false,
  interval = 5000,
  keyboard = true,
  pause = 'hover',
  ride = false,
  wrap = true,
  touch = true,
  onSlide = () => {},
  onSlid = () => {},
  ...otherAttributes
}, ref) => {
  const [sliding, setSliding] = useState(false)
  const context = useMemo(() => ({ index, length, onChange, sliding }), [index, length, onChange, sliding])
  const innerRef = useRef<HTMLDivElement>(null)
  // Expose innerRef, must use non-null assertion because signature requires it and conditional is not allowed
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  useImperativeHandle(ref, () => innerRef.current!, [])
  const previousIndex = usePrevious(index)
  const [carousel, setCarousel] = useState<any>()
  const classNames = clsx(
    'carousel slide',
    fade && 'carousel-fade',
    (popout === true || popout === 'controls') && 'carousel-popout-controls',
    (popout === true || popout === 'indicators') && 'carousel-popout-indicators',
    className
  )

  // Init carousel
  // TODO: remove once SSR is safe https://github.com/twbs/bootstrap/pull/34989
  useEffect(() => {
    if (innerRef?.current != null && carousel == null && BootstrapCarouselImport !== false) {
      if (siteConfig.NODE_ENV !== 'test') {
        BootstrapCarouselImport?.then((bootstrapCarousel: any) => {
          const BootstrapCarousel = bootstrapCarousel.default
          setCarousel(new BootstrapCarousel(innerRef.current as Element, { interval, keyboard, pause, ride, wrap, touch }))
        })
      } else {
        setCarousel(new BootstrapCarouselImport(innerRef.current as Element, { interval, keyboard, pause, ride, wrap, touch }))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [innerRef.current, carousel, interval, keyboard, pause, ride, wrap, touch])

  // Handle slide
  const handleSlide = useCallback((event: Event & { to: number }) => {
    setSliding(true)
    onChange?.(event.to)
    onSlide(event)
  }, [onChange, onSlide])

  // Handle slid
  const handleSlid = useCallback((event: Event) => {
    setSliding(false)
    onSlid(event)
  }, [onSlid])

  // Assign event listeners
  useEventListener('slide.bs.carousel', handleSlide, innerRef)
  useEventListener('slid.bs.carousel', handleSlid, innerRef)

  // Dispose carousel
  useEffect(() => {
    return () => {
      carousel?.dispose?.()
    }
  }, [carousel])

  // Go to index
  useEffect(() => {
    if (carousel != null && index != null && index !== previousIndex) {
      carousel.to(index)
    }
  }, [carousel, index, previousIndex])

  return (
    <CarouselContext.Provider value={context}>
      <div
        className={classNames}
        data-bs-theme={theme}
        ref={innerRef}
        {...otherAttributes}
      >
        {children}
      </div>
    </CarouselContext.Provider>
  )
})
Carousel.displayName = 'Carousel'

export {
  Carousel
}
