import { useContext } from 'react'
import { AuthContext } from './AuthContext'
import { type Auth } from './types'

/**
 * Hook to fetch Auth information
 */
function useAuth (): Auth {
  return useContext(AuthContext)
}

export { useAuth }
