import type { TargetingMap } from '@stuller/contentstack/util/types'
import { useAuth } from '@stuller/stullercom/feat/auth'
import { useTargetingPreviewer } from './targetingPreviewer'

/**
 * Hook to check if user is a 302 member or not
 */
function use302MemberTargeting (targeting: TargetingMap['302_MEMBER']): boolean {
  const [targetingPreviewer] = useTargetingPreviewer()
  const auth = useAuth()
  const { authUser } = auth
  const is302Member = targetingPreviewer?.is302Member != null ? targetingPreviewer.is302Member : authUser?.user?.is302Member ?? false

  return targeting.targetValue ? is302Member : !is302Member
}

export { use302MemberTargeting }
