import { type ReactElement } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'
import Link from 'next/link'
import { useGetConsultantInfoQuery } from '@stuller/stullercom/data-access/apollo-queries'
import { Spinner } from '@stuller/stullercom/ui'
import { useAuth } from '@stuller/stullercom/feat/auth'

/**
 * Current consultant info
 */
function ConsultantModeInfo (): ReactElement {
  const { authUser } = useAuth()
  const { data, loading } = useGetConsultantInfoQuery()

  return (
    <div className='bg-red'>
      <div className='container-xxxl fs-3 fw-light text-white py-1'>
        {loading
          ? (
            <div className='row align-items-center justify-content-center'>
              <div className='col-auto'>
                <Spinner size='xs' color='white' />
              </div>
            </div>)
          : (
            <div className='row justify-content-between gx-4 mx-0'>
              <div className='col-auto'>
                {data?.authUser?.consultantInfo?.employee?.displayName ?? 'Not Set'}
              </div>
              <div className='col-auto'>
                <FontAwesomeIcon icon={fas.faIdCard} className='px-2' />
                <span className='fw-bold d-none d-lg-inline'>Current Account </span>
                <span>{authUser?.user?.defaultActiveShipToAccount?.id ?? 'Unknown'}
                  <Link href='/consultant' className='link-white px-2'>
                    <FontAwesomeIcon icon={fas.faSquarePen} />
                  </Link>
                </span>
              </div>
              <div className='col-auto'>
                <FontAwesomeIcon icon={fas.faGlobeAmericas} className='px-2' />
                <span className='fw-bold d-none d-lg-inline'>Time Zone </span>
                <span>{authUser?.user?.defaultActiveShipToAccount?.timeZone.id ?? 'Unknown'}</span>
              </div>
              {authUser?.user?.contact !== null &&
                <div className='col-auto'>
                  <FontAwesomeIcon icon={fas.faUserCircle} className='px-2' />
                  <span className='fw-bold d-none d-lg-inline'>Contact </span>
                  <span>{authUser?.user?.contact?.preferredName ?? authUser?.user?.contact?.displayName ?? 'Unknown'}</span>
                </div>}
            </div>)}
      </div>
    </div>
  )
}

export {
  ConsultantModeInfo
}
