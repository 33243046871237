import { createContext } from 'react'

export interface AccordionItemContextType {
  /**
   * The accordion item id target (for header/body)
   */
  itemId?: string
}

const AccordionItemContext = createContext<AccordionItemContextType>({})

export {
  AccordionItemContext
}
