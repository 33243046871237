import { type ReactElement, type ReactNode } from 'react'
import { type CmsOptionsInput } from '@stuller/stullercom/data-access/apollo-queries'
import { CmsContentOptionsContext } from './CmsContentOptionsContext'
import { useCmsContentOptionsContext } from './useCmsContentOptionsContext'

export interface CmsContentOptionsProviderProps extends CmsOptionsInput {
  children?: ReactNode
}

/**
 * Provides a way to set/pass context variables used when loading CMS content (via child `CmsContent` components)
 * This provider may be used recursively to set context at different levels in the component hierarchy, with the "lowest" instance taking precedence
 */
function CmsContentOptionsContextProvider ({ children, ...details }: CmsContentOptionsProviderProps): ReactElement {
  const outerContext = useCmsContentOptionsContext()

  return (
    <CmsContentOptionsContext.Provider value={{ ...outerContext, ...details }}>
      {children}
    </CmsContentOptionsContext.Provider>
  )
}

export { CmsContentOptionsContextProvider }
