import { type ReactNode } from 'react'
import { toast as toastifyToast, type ToastOptions as ToastifyOptions } from 'react-toastify'
import { type ThemeColor } from '../../..'
import { Toast, type ToastProps } from './Toast'

export interface ToastOptions extends Omit<ToastifyOptions, 'type' | 'icon'>, Pick<ToastProps, 'id' | 'icon'> {
  /**
   * Type of toast to show that controls the color of the toast border and default `Toast` color.
   */
  color?: ThemeColor
  /**
   * The className to send to the `Toast` component.
   */
  toastClassName?: ToastProps['className']
  /**
   * The style to send to the `Toast` component.
   */
  toastStyle?: ToastProps['style']
  /**
   * Override the default color for the `Alert` to allow different colors for the toast vs progress bar.
   */
  toastColor?: ToastProps['color']
}

const DEFAULT_OPTIONS: ToastOptions = {
  color: 'secondary'
}

/**
 * Show a toast message via `react-toastify` and wrap with our `Toast` component.
 *
 * See API docs: https://fkhadra.github.io/react-toastify/api/toast
 */
function toast (
  children: ReactNode,
  optionsIn: ToastOptions = {}
): ReturnType<typeof toastifyToast> {
  const options = { ...DEFAULT_OPTIONS, ...optionsIn }
  const toastifyOptions = {
    ...options,
    type: options.color,
    color: undefined,
    icon: undefined,
    toastClassName: undefined,
    toastStyle: undefined,
    toastColor: undefined
  }

  return toastifyToast((
    <Toast
      id={options.id}
      className={options.toastClassName}
      color={options.toastColor ?? options.color}
      icon={options.icon}
      style={options.toastStyle}
    >
      {children}
    </Toast>
  ), toastifyOptions as ToastifyOptions)
}

// Attach the functions from `react-toastify` to our `toast` function
// Prefer not using the `.success`, `.error`, etc. functions and using the `toast` function instead with `type` option
toast.loading = toastifyToast.loading
toast.promise = toastifyToast.promise
toast.dismiss = toastifyToast.dismiss
toast.clearWaitingQueue = toastifyToast.clearWaitingQueue
toast.isActive = toastifyToast.isActive
toast.update = toastifyToast.update
toast.done = toastifyToast.done
toast.onChange = toastifyToast.onChange
toast.play = toastifyToast.play
toast.pause = toastifyToast.pause

/**
 * Hook that returns a handler to show a toast message via `react-toastify`.
 * We override the default `toast` function call to `react-toastify` with our own `Toast` component and options.
 * Make sure to include the `ToastContainer` once in the app.
 *
 * See official `react-toastify` docs for more info:
 * - Intro: https://fkhadra.github.io/react-toastify/introduction
 * - API: https://fkhadra.github.io/react-toastify/api/toast
 *
 * #### Examples:
 *
 * ```tsx
 * const toast = useToast()
 * toast('Default message')
 * toast('Success message', { color: 'success' })
 * toast('Danger message', { color: 'danger' })
 * toast('Warning message', { color: 'warning' })
 * toast('No icon', { icon: false, color: 'success' })
 * toast('Alternate icon', { icon: fas.faHeart, color: 'danger' })
 * toast(
 *   <>
 *     <h4 className='mb-0'>Success</h4>
 *     But with secondary (body color) alert color
 *   </>, { color: 'success', toastColor: 'secondary' })
 * ```
 */
function useToast (): typeof toast {
  return toast
}

export {
  useToast
}
