import { memo, type ReactElement } from 'react'
import { type AccountRepBannerProps } from '@stuller/stullercom/feat/layout-context'
import { CmsContent } from '@stuller/stullercom/feat/cms-content'
import { useAuth } from '@stuller/stullercom/feat/auth'

/**
 * Account representative banner from CMS (how can we help, number, etc)
 */
const AccountRepBanner = memo(({
  enabled = false
}: AccountRepBannerProps): ReactElement | null => {
  const { isShowcase, authUser, isSterling } = useAuth()

  if (isShowcase || authUser == null || isSterling || !enabled) {
    return null
  }

  return (
    <div className='container-xxxl mw-xxl px-4 d-print-none'>
      <CmsContent
        contentContainerName='account-rep-banner'
        skipLoading
        noRefetch
      />
    </div>
  )
})
AccountRepBanner.displayName = 'AccountRepBanner'

export {
  AccountRepBanner
}
