import { type ReactElement } from 'react'
import clsx from 'clsx'
import Link from 'next/link'
import { Spinner, Collapse } from '@stuller/stullercom/ui'
import { useGetMainNavCategoriesQuery } from '@stuller/stullercom/data-access/apollo-queries'
import { useAuth } from '@stuller/stullercom/feat/auth'
import { useToggle } from '@stuller/shared/util/react-hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'
import { mainLinks, type MainLink as MainLinkType } from './links'

interface MainLinkSectionProps extends MainLinkType {
}

interface MainLinkSublinksProps extends MainLinkType {
  /**
   * The sublinks from `MainLink` results
   */
  sublinksResult: ReturnType<NonNullable<MainLinkType['sublinks']>>
}

interface MainLinkProps extends MainLinkType {
}

interface MainLinkSublinkProps extends MainLinkType {
}

/**
 * A single main link sublink (under collapse)
 */
function MainLinkSublink ({ label, url }: MainLinkSublinkProps): ReactElement {
  return (
    <div className='row g-0 px-4'>
      <div className='col'>
        <Link href={url} className='link-light text-decoration-none fw-normal d-block py-3'>
          {label}
        </Link>
      </div>
    </div>
  )
}

/**
 * A single main nav link with sublinks (with collapse)
 */
function MainLinkSublinks ({ label, sublinksResult }: MainLinkSublinksProps): ReactElement {
  const { isShowcase } = useAuth()
  const { loading, links, featured } = sublinksResult
  const [open, toggleOpen] = useToggle()
  const classNames = clsx(
    'row g-0 align-items-center text-white text-decoration-none p-4 cursor-pointer',
    isShowcase ? 'fs-base fw-light' : 'fw-normal fs-4'
  )
  const arrowClassNames = clsx(
    'transition-transform text-cyan-400 lh-xs fa-lg',
    open && 'fa-rotate-by'
  )

  return (
    <>
      <div onClick={toggleOpen} className={classNames}>
        <div className='col'>
          {label}
        </div>
        <div className='col-auto'>
          <FontAwesomeIcon
            icon={fas.faChevronDown}
            className={arrowClassNames}
            style={{ '--fa-rotate-angle': '-180deg' }}
          />
        </div>
      </div>
      <Collapse isOpen={open} className='pb-6'>
        {loading
          ? (
            <div className='row g-0 py-3 px-4 border-top border-gray-700'>
              <Spinner size='xs' color='white' />
            </div>)
          : ([...links, ...featured]).map(link => <MainLinkSublink key={link.label} {...link} />)}
      </Collapse>
    </>
  )
}

/**
 * A single main nav link without sublinks (not collapse)
 */
function MainLink ({ url, label }: MainLinkProps): ReactElement {
  const { isShowcase } = useAuth()
  const classNames = clsx(
    'text-white text-decoration-none d-block p-4',
    isShowcase ? 'fs-base fw-light' : 'fw-normal fs-4'
  )

  return (
    <Link href={url} className={classNames}>
      {label}
    </Link>
  )
}

/**
 * A single main nav link or collapse section for mobile popover
 */
function MainLinkSection (link: MainLinkSectionProps): ReactElement {
  const { isShowcase } = useAuth()
  const sublinksResult = link.sublinks?.()
  const classNames = clsx(
    'row g-0 border-top',
    isShowcase ? 'border-gray-700' : 'border-cyan-500'
  )

  return (
    <div className={classNames}>
      <div className='col lh-sm'>
        {sublinksResult != null
          ? <MainLinkSublinks {...link} sublinksResult={sublinksResult} />
          : <MainLink {...link} />}
      </div>
    </div>
  )
}

/**
 * Main nav mobile links
 */
function MobileMainLinks (): ReactElement {
  const { isShowcase } = useAuth()
  const { data, loading } = useGetMainNavCategoriesQuery()

  if (isShowcase && loading) {
    return (
      <div className='row g-0 py-4 justify-content-center border-top border-gray-700'>
        <Spinner size='sm' color='white' />
      </div>
    )
  }

  return (
    <>
      {isShowcase
        ? data?.mainNavCategories.map(category => (
          <MainLinkSection
            key={category.id}
            label={category.mainNavDisplayName}
            url={category.url}
          />
        ))
        : mainLinks.map(link => (
          <MainLinkSection
            key={link.label}
            label={link.label}
            url={link.url}
            sublinks={link.sublinks}
          />))}
    </>
  )
}

export {
  MobileMainLinks
}
