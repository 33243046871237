import { type ReactElement } from 'react'
import type { SerializedDiamond } from '../types'

interface NaturalSerializedDiamondSellingInfoProps {
  /**
   * The serialized diamond to display
   */
  serializedDiamond: SerializedDiamond
  /**
   * Whether the user can view the diamond rebate
   */
  canViewDiamondRebate?: boolean
}

function NaturalSerializedDiamondSellingInfo ({ serializedDiamond, canViewDiamondRebate }: NaturalSerializedDiamondSellingInfoProps): ReactElement {
  return (
    <p>NaturalSerializedDiamondSellingInfo placeholder</p>
  )
}

export {
  NaturalSerializedDiamondSellingInfo
}
