import { type ReactElement, useState } from 'react'
import { useRouter } from 'next/router'
import { Button } from '@stuller/stullercom/ui'
import { useLock } from '@stuller/shared/util/react-hooks'
import { MenuPopover, type MenuPopoverProps } from '../MenuPopover'
import { LoginForm, type LoginFormProps } from './LoginForm'

interface LoginPopoverProps extends MenuPopoverProps {
}

/**
 * The popover for login (form, etc.)
 */
function LoginPopover ({
  open,
  onOpenChange,
  reference
}: LoginPopoverProps): ReactElement {
  const { asPath } = useRouter()
  const encodedPath = encodeURIComponent(asPath)
  const recoverUrl = `/forgot?returnUrl=${encodedPath}`
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [rememberMe, setRememberMe] = useState(false)
  const [redirecting, setRedirecting] = useState(false)
  const [errors, setErrors] = useState<LoginFormProps['errors']>({})

  function handleUsernameChange (event: Parameters<LoginFormProps['onUsernameChange']>[0]): void {
    setUsername(event.target.value)
  }

  function handlePasswordChange (event: Parameters<LoginFormProps['onPasswordChange']>[0]): void {
    setPassword(event.target.value)
  }

  function handleRememberMeChange (event: Parameters<LoginFormProps['onRememberMeChange']>[0]): void {
    setRememberMe(event.target.checked)
  }

  const [handleSubmit, locked] = useLock(async (event: Parameters<LoginFormProps['onSubmit']>[0]) => {
    event.preventDefault()
    setErrors({})

    try {
      const response = await fetch('/loginjson', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          userName: username,
          password,
          rememberMe,
          returnUrl: encodedPath
        })
      })
      if (response.ok) {
        const data = await response.json()
        const serverErrors = data.errorMessages
        if (serverErrors == null || serverErrors.length === 0) {
          window.location.href = data.redirectUrl
          setRedirecting(true)
        } else {
          const tempErrors: LoginFormProps['errors'] = {}
          for (const error of serverErrors) {
            tempErrors[error.FieldName] = error.ErrorMessage
          }
          setErrors(tempErrors)
        }
      } else {
        throw new Error()
      }
    } catch (error) {
      setErrors({ Login: 'An unknown error occurred. Please try again later.' })
    }
  })

  return (
    <MenuPopover
      open={open}
      onOpenChange={onOpenChange}
      reference={reference}
    >
      <div className='p-6' data-gtm-click-subsection1='Log In'>
        <LoginForm
          username={username}
          password={password}
          rememberMe={rememberMe}
          onUsernameChange={handleUsernameChange}
          onPasswordChange={handlePasswordChange}
          onRememberMeChange={handleRememberMeChange}
          onSubmit={handleSubmit}
          loading={locked || redirecting}
          errors={errors}
        />
        <div className='mb-4'>
          <Button href={recoverUrl} tag='a' color='link' pill className='w-100'>
            Recover username or password
          </Button>
        </div>
        <div>
          <Button href='/accountsignup' tag='a' color='primary' outline pill className='w-100'>
            Apply for an Account
          </Button>
        </div>
      </div>
    </MenuPopover>
  )
}

export {
  LoginPopover
}
