import { AmericanExpressCardIcon, DiscoverCardIcon, MastercardCardIcon, UnknownCardIcon, VisaCardIcon } from '../images'

/**
 * Mapping of card types and icon image urls
 */
export const cardTypesAndIcons = [
  {
    type: 'Visa',
    icon: VisaCardIcon
  },
  {
    type: 'American Express',
    icon: AmericanExpressCardIcon
  },
  {
    type: 'Mastercard',
    icon: MastercardCardIcon
  },
  {
    type: 'Discover',
    icon: DiscoverCardIcon
  },
  {
    type: 'N/A',
    icon: UnknownCardIcon
  }
]
