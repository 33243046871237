import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { type ParsedUrlQuery } from 'querystring'
import Cookies from 'js-cookie'

const SFMC_CONVERSTION_TRACKING_COOKIE_NAME = 'sfmc_converstion_tracking'

enum SFMCConversionTracking {
  JOB_ID = 'j',
  SUBSCRIBER_ID = 'sfmc_sub',
  LIST_ID = 'l',
  LANDING_PAGE_URL_ID = 'u',
  JOB_BATCH_ID = 'jb',
  MEMBER_ID = 'mid'
}

/**
 * Parse the querystring and build SFMC object for cookie values
 */
function setCookie (queryParams: ParsedUrlQuery): void {
  const jobId = queryParams[SFMCConversionTracking.JOB_ID]?.toString() ?? undefined
  const subscriberId = queryParams[SFMCConversionTracking.SUBSCRIBER_ID]?.toString() ?? undefined
  const listId = queryParams[SFMCConversionTracking.LIST_ID]?.toString() ?? undefined
  const landingPageUrlId = queryParams[SFMCConversionTracking.LANDING_PAGE_URL_ID]?.toString() ?? undefined
  const jobBatchId = queryParams[SFMCConversionTracking.JOB_BATCH_ID]?.toString() ?? undefined
  const memberId = queryParams[SFMCConversionTracking.MEMBER_ID]?.toString() ?? undefined

  if (subscriberId !== undefined &&
    jobId !== undefined &&
    listId !== undefined &&
    landingPageUrlId !== undefined &&
    jobBatchId !== undefined &&
    memberId !== undefined
  ) {
    let sfmcCookie = ''
    sfmcCookie += `${SFMCConversionTracking.JOB_ID}=${jobId}`
    sfmcCookie += `&${SFMCConversionTracking.SUBSCRIBER_ID}=${subscriberId}`
    sfmcCookie += `&${SFMCConversionTracking.LIST_ID}=${listId}`
    sfmcCookie += `&${SFMCConversionTracking.LANDING_PAGE_URL_ID}=${landingPageUrlId}`
    sfmcCookie += `&${SFMCConversionTracking.JOB_BATCH_ID}=${jobBatchId}`
    sfmcCookie += `&${SFMCConversionTracking.MEMBER_ID}=${memberId}`

    Cookies.set(SFMC_CONVERSTION_TRACKING_COOKIE_NAME, sfmcCookie, { expires: 1, secure: true })
  }
}

/**
 * Update Expires date on cookie
 */
function updateCookieExpiration (cookieValues: string): void {
  Cookies.set(SFMC_CONVERSTION_TRACKING_COOKIE_NAME, cookieValues, { expires: 1, secure: true })
}

/**
 * SFMC Converstion Tracking hook
 */
function useSfmcConversionTracking (): void {
  const router = useRouter()

  useEffect(() => {
    if (router.query[SFMCConversionTracking.SUBSCRIBER_ID] != null && typeof window !== 'undefined') {
      const cookie = Cookies.get(SFMC_CONVERSTION_TRACKING_COOKIE_NAME)
      if (cookie == null) {
        setCookie(router.query)
      } else {
        updateCookieExpiration(cookie)
      }
    }
  }, [router.query])
}

export {
  useSfmcConversionTracking
}
