import { forwardRef, type ReactNode } from 'react'
import clsx from 'clsx'
import { MultiItemCarouselContext, type MultiItemCarouselContextType } from './MultiItemCarouselContext'

export interface MultiItemCarouselProps extends MultiItemCarouselContextType {
  /**
   * Id of element
   */
  id?: string
  /**
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /**
   * Children of element
   */
  children?: ReactNode
}

/**
 * A carousel component that displays multiple items in the viewport of its container.
 * Instead of one item per slide like the [Carousel](?path=/docs/components-carousel--docs) component, each "slide" moves to the scroll position of the first item that is hidden behind the container overflow.
 *
 * For easy carousel state management use `const multiItemCarouselProps = useMultiItemCarousel()`
 * Then pass it like `<MultiItemCarousel {...multiItemCarouselProps}>...`.
 *
 * **Note**: the carousel components are built to work with a specific order as follows:
 *
 * 1. `MultiItemCarouselInner`
 * 2. `MultiItemCarouselControl` for 'prev'
 * 3. `MultiItemCarouselControl` for 'next'
 * 4. `MultiItemCarouselIndicators`
 */
const MultiItemCarousel = forwardRef<HTMLDivElement, MultiItemCarouselProps>(({
  className,
  children,
  index,
  maxIndex,
  handleIndex,
  handleNext,
  handlePrev,
  multiItemCarouselInnerRef,
  ...otherAttributes
}, ref) => {
  const classNames = clsx(
    'carousel carousel-popout-indicators carousel-popout-controls p-3',
    className
  )

  return (
    <MultiItemCarouselContext.Provider value={{
      index,
      maxIndex,
      handleIndex,
      handleNext,
      handlePrev,
      multiItemCarouselInnerRef
    }}
    >
      <div
        className={classNames}
        ref={ref}
        {...otherAttributes}
      >
        {children}
      </div>
    </MultiItemCarouselContext.Provider>
  )
})
MultiItemCarousel.displayName = 'MultiItemCarousel'

export {
  MultiItemCarousel
}
