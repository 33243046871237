import { type AppContext } from 'next/app'
import { type GetAuth } from '@stuller/stullercom/feat/auth'

const REFRESH_CUTOFF_MINUTES = -65
const REFRESH_AUTH_PATH = '/homes/refreshauth?r='

/**
 * Redirect if auth user is due for a refresh
 */
async function refreshAuthUserRedirect (appContext: AppContext, auth: GetAuth): Promise<boolean> {
  const { ctx, router } = appContext
  const { authUser } = auth
  const refreshCutoff = new Date()
  refreshCutoff.setMinutes(REFRESH_CUTOFF_MINUTES)
  const returnPath = ctx.asPath

  if (authUser != null &&
    new Date(authUser.lastRefreshDate).getTime() < refreshCutoff.getTime() &&
    returnPath != null) {
    const path = `${REFRESH_AUTH_PATH}${encodeURIComponent(returnPath)}`

    if (ctx.res != null) {
      // Using 307 here to ensure http method is not changed in case this was a post
      ctx.res.writeHead(307, { Location: path })
      ctx.res.end()
    } else {
      await router.push(path)
    }

    return true
  }

  return false
}

export {
  refreshAuthUserRedirect,
  REFRESH_CUTOFF_MINUTES
}
