import { type CanonicalUrlProps } from '@stuller/stullercom/feat/layout-context'
import { type ReactElement, memo } from 'react'
import Head from 'next/head'
import { useAuth } from '@stuller/stullercom/feat/auth'
import { usePathname } from 'next/navigation'

/**
 * Customizable `<link rel='canonical'>` and `<meta property='og:url'>`
 */
const CanonicalUrl = memo(({
  children,
  disabled = false
}: CanonicalUrlProps): ReactElement | null => {
  const { host } = useAuth()
  const currentPathname = usePathname()

  if (disabled) {
    return null
  }

  const suppliedPathname = (children ?? '').trim()
  const pathname = suppliedPathname === '' ? currentPathname : suppliedPathname
  const url = `https://${host}${pathname}`.toLowerCase().replace(/\/$/, '')

  return (
    <Head>
      <meta property='og:url' key='og:url' content={url} />
      <link rel='canonical' key='canonical' href={url} />
    </Head>
  )
})
CanonicalUrl.displayName = 'CanonicalUrl'

export {
  CanonicalUrl
}
