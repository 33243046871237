import { type AppContext } from 'next/app'
import { type GetAuth } from '@stuller/stullercom/feat/auth'
import { isUnauthenticatedPath } from './isUnauthenticatedPath'

const FORCE_CHANGE_PASSWORD_PATH = '/forcechangepassword'

/**
 * Redirects the user to the force change password page if they need to change their password
 */
async function resetPasswordRedirect (appContext: AppContext, auth: GetAuth): Promise<boolean> {
  const { ctx, router } = appContext
  const { authUser, isStullerComShowcaseMode } = auth

  if (authUser?.user?.resetPassword === true &&
    !isStullerComShowcaseMode &&
    authUser?.consultantLogin == null &&
    authUser?.impersonatedBy == null &&
    !isUnauthenticatedPath(ctx.asPath)) {
    if (ctx.res != null) {
      ctx.res.writeHead(302, { Location: FORCE_CHANGE_PASSWORD_PATH })
      ctx.res.end()
    } else {
      await router.push(FORCE_CHANGE_PASSWORD_PATH)
    }

    return true
  }

  return false
}

export {
  resetPasswordRedirect
}
