import { productStoneShapeIconMap, productStoneShapeNameMap } from './product-stone-shape-map'
import { ProductStoneShapeIcon } from './ProductStoneShapeIcon'
import Link from 'next/link'
import { clsx } from 'clsx'
import { forwardRef, type ElementType } from 'react'

export interface ProductStoneShapeIconCardProps {
  /**
   * The shape "key" of the stone to display.
   */
  shape: keyof typeof productStoneShapeIconMap
  /**
   * The link href of the card.
   */
  href?: string
  /**
   * The link title of the card.
   */
  title?: string
}

/**
 * A Stone Shape Card is a clickable card (when an href is supplied)
 * that displays a Stone Shape Icon and the name of the shape.
 */
const ProductStoneShapeIconCard = forwardRef<HTMLDivElement | HTMLAnchorElement | null, ProductStoneShapeIconCardProps>(({
  shape,
  href,
  title,
  ...otherAttributes
}, ref) => {
  const hasHref = href != null && href !== ''
  const Tag = hasHref ? Link : 'div' as ElementType
  const iconOutlineHoverColor = hasHref ? 'gray-600' : undefined
  const iconShadowHoverColor = hasHref ? 'gray-200' : undefined
  const cardClasses = clsx(
    hasHref && 'border-gray-700-hover',
    'bg-white rounded-3 border align-items-center d-flex flex-column p-3 text-reset h-100 text-decoration-none stone-shape-icon-card'
  )

  if (shape == null || !(shape in productStoneShapeIconMap)) {
    return null
  }

  return (
    <Tag
      className={cardClasses}
      href={href ?? ''}
      ref={ref}
      title={title}
      {...otherAttributes}
    >
      <ProductStoneShapeIcon
        shape={shape}
        outlineColor='gray-700'
        outlineHoverColor={iconOutlineHoverColor}
        shadowHoverColor={iconShadowHoverColor}
      />
      <span className='mt-3 mb-0 h4 fw-normal'>{productStoneShapeNameMap[shape ?? 'round']}</span>
    </Tag>
  )
})
ProductStoneShapeIconCard.displayName = 'ProductStoneShapeIconCard'

export {
  ProductStoneShapeIconCard
}
