import { type CSSProperties, type ReactElement } from 'react'
import clsx from 'clsx'
import { type ThemeColor, type Color } from '../../..'

export interface ProgressCircleProps {
  /**
   * Id of element
   */
  id?: string
  /**
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /**
   * Inline styles to pass to the containing element
   */
  style?: CSSProperties
  /**
   * Percent complete
   */
  value: number
  /**
   * Color for the circle bar
   */
  color?: ThemeColor | Color | 'inherit'
  /**
   * Color for the circle background
   */
  backgroundColor?: ThemeColor | Color | 'inherit'
  /**
   * Fill color for the circle background
   */
  fillColor?: ThemeColor | Color | 'inherit'
}

/**
 * The progress circle bar SVG.
 */
function ProgressCircle ({
  className,
  style,
  value,
  color,
  backgroundColor,
  fillColor,
  ...otherAttributes
}: ProgressCircleProps): ReactElement {
  const classNames = clsx(
    'progress-circle-bar',
    className
  )

  return (
    <svg
      className={classNames}
      style={{
        ...style,
        '--bs-progress-bar-bg': color != null ? `var(--bs-${color})` : undefined,
        '--bs-progress-bg': backgroundColor != null ? `var(--bs-${backgroundColor})` : undefined,
        '--bs-progress-fill': fillColor != null ? `var(--bs-${fillColor})` : undefined
      }}
      viewBox='0 0 36 36'
      {...otherAttributes}
    >
      <path
        className='progress-circle-bg'
        d='M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831'
      />
      <path
        className='progress-circle-fg'
        strokeDasharray={`${value}, 100`}
        d='M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831'
      />
    </svg>
  )
}

export {
  ProgressCircle
}
