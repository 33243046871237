import { fas } from '@awesome.me/kit-3dbd93c064/icons'
import { Alert } from '../../..'
import { forwardRef } from 'react'

export interface CartPageLoadErrorProps {
  /**
   * Name of the cart where Error occurred
  */
  cartName: string
}

const CartPageLoadError = forwardRef<HTMLDivElement, CartPageLoadErrorProps>(({
  cartName
}, ref) => {
  return (
    <Alert color='danger' icon={fas.faExclamationCircle} ref={ref} className='fw-bold'>
      Something went wrong when loading the {cartName}. Try refreshing the page.
    </Alert>
  )
})

export { CartPageLoadError }
