/**
 * Given an array, return true if the value is null | undefined | empty array - otherwise return false
 */
function isArrayEmpty<T = any> (value: T[] | null | undefined): boolean {
  if (value == null || value.length === 0) {
    return true
  }

  return false
}

export {
  isArrayEmpty
}
