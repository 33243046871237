import { type CSSProperties, type ReactNode, forwardRef } from 'react'
import clsx from 'clsx'
import { type ThemeColor, type Color } from '../../..'

export interface BadgeProps {
  /**
   * Id of element
   */
  id?: string
  /**
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /**
   * Inline styles to pass to the containing element
   */
  style?: CSSProperties
  /**
   * Children of element
   */
  children?: ReactNode
  /**
   * Defined element type
   */
  tag?: 'div' | 'span'
  /**
   * Background color of the badge
   */
  color?: ThemeColor | Color
  /**
   * Border color for badge (defaults to the same as `color`)
   */
  border?: ThemeColor | Color
  /**
   * Text color for badge (defaults to white)
   */
  text?: ThemeColor | Color
  /**
   * Indicates to render badge as a pill
   */
  pill?: boolean
}

/**
* Badge component for labeling and notification badges.
*
* Our default badge is set to `pill`, send `pill={false}` to render as a square.
*
* The default badge has `secondary` background and border color, with white text.
* All badges have borders on them in order to make sure they are all the same height when border is needed.
* Remember `text-body` and `border-body` (body text color) are different than `bg-body` (white).
*
* By default badges are rendered as a `span` element so they can be displayed correctly next to text.
* You can change it to a `div` element via `tag='div'`.
*
* ⚠️ For product specific badges, see the [ProductBadge](?path=/docs/products-productbadge--docs) component.
*/

const Badge = forwardRef<HTMLDivElement & HTMLSpanElement, BadgeProps>(({
  className,
  children,
  tag: Tag = 'span',
  color = 'secondary',
  border,
  text,
  pill = true,
  ...otherAttributes
}, ref) => {
  const classNames = clsx(
    `badge bg-${color} border border-${border ?? color}`,
    text != null && `text-${text}`,
    pill && 'rounded-pill',
    className
  )

  return (
    <Tag
      className={classNames}
      ref={ref}
      {...otherAttributes}
    >
      {children}
    </Tag>
  )
})
Badge.displayName = 'Badge'

export {
  Badge
}
