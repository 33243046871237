import { type ReactNode, forwardRef } from 'react'
import clsx from 'clsx'

export interface PopoverBodyProps {
  /**
   * Id of element
   */
  id?: string
  /**
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /**
   * Children of element
   */
  children?: ReactNode
}

/**
 * Popover body component
 */
const PopoverBody = forwardRef<HTMLDivElement, PopoverBodyProps>(({
  className,
  children,
  ...otherAttributes
}, ref) => {
  const classNames = clsx('popover-body', className)

  return (
    <div
      className={classNames}
      ref={ref}
      {...otherAttributes}
    >
      {children}
    </div>
  )
})
PopoverBody.displayName = 'PopoverBody'

export {
  PopoverBody
}
