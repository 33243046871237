import {
  forwardRef,
  type ComponentPropsWithoutRef,
  useContext,
  type ChangeEventHandler
} from 'react'
import clsx from 'clsx'
import { type AsyncFunction } from '@stuller/shared/util/type-helpers'
import { FormGroupContext } from '../../../..'

type TextareaTagProps = Omit<ComponentPropsWithoutRef<'textarea'>, 'onChange'>

export interface TextareaProps extends TextareaTagProps {
  /**
   * Id of element
   */
  id?: string
  /**
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /**
   * Size of the input
   */
  size?: 'sm' | 'lg'
  /**
   * Indicates the textarea is invalid to show fail style
   */
  invalid?: boolean | string
  /**
   * Indicates the textarea is valid to show success style
   */
  valid?: boolean | string
  /**
   * On change handler with async
   */
  onChange?: AsyncFunction<ChangeEventHandler<HTMLTextAreaElement>>
}

/**
 * Textarea component with for rendering any `<textarea>` element.
 *
 * See `Form` for more usage examples.
 * Remember to use `value` for controlled components.
 */
const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(({
  id: idIn,
  className,
  size,
  invalid = false,
  valid = false,
  onChange,
  ...otherAttributes
}, ref) => {
  const { groupId } = useContext(FormGroupContext)
  const id = idIn ?? groupId
  const classNames = clsx(
    'form-control',
    size != null && `form-control-${size}`,
    invalid !== false && invalid !== '' && 'is-invalid',
    valid === true && 'is-valid',
    className
  )

  return (
    <textarea
      id={id}
      className={classNames}
      onChange={onChange}
      ref={ref}
      {...otherAttributes}
    />
  )
})
Textarea.displayName = 'Textarea'

export {
  Textarea
}
