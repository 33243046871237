import { type ReactElement } from 'react'
import { siteConfig } from '@stuller/stullercom/util/site-config'

/**
 * Google tag manager `noscript` tag
 * Use in Next.js `_document.tsx` `<body>`
 */
function GoogleTagManagerNoscript (): ReactElement | null {
  if (siteConfig.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID == null) {
    return null
  }

  return (
    <noscript>
      <iframe
        src={`https://www.googletagmanager.com/ns.html?id=${siteConfig.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID}${siteConfig.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_PARAMS ?? ''}`}
        className='h-0 w-0 d-none visibility-hidden'
        title='Google Tag Manager noscript'
      />
    </noscript>
  )
}

export {
  GoogleTagManagerNoscript
}
