import { forwardRef, type ReactNode, useContext, useMemo } from 'react'
import clsx from 'clsx'
import {
  AccordionContext,
  AccordionItemContext,
  type AccordionItemContextType,
  Collapse
} from '../../..'

export interface AccordionBodyProps extends AccordionItemContextType {
  /**
   * Id of element
   */
  id?: string
  /**
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /**
   * Children of element
   */
  children?: ReactNode
  /**
   * Additional class name(s) to give to the collapse that wraps the body element
   */
  collapseClassName?: string
}

/**
 * Accordion body component that holds the collapsed content of an accordion item.
 */
const AccordionBody = forwardRef<HTMLDivElement, AccordionBodyProps>(({
  className,
  children,
  itemId: itemIdIn,
  collapseClassName,
  ...otherAttributes
}, ref) => {
  const { open, faqSchema } = useContext(AccordionContext)
  const { itemId: itemIdContext } = useContext(AccordionItemContext)
  const itemId = itemIdIn ?? itemIdContext
  const isOpen = useMemo(() => itemId == null || (Array.isArray(open) ? open.includes(itemId) : open === itemId), [open, itemId])
  const faqSchemaMicrodataCollapse = faqSchema === true && { itemScope: true, itemProp: 'acceptedAnswer', itemType: 'https://schema.org/Answer' }
  const faqSchemaMicrodataBody = faqSchema === true && { itemProp: 'text' }
  const classNames = clsx('accordion-body', className)
  const collapseClassNames = clsx('accordion-collapse', collapseClassName)

  return (
    <Collapse
      className={collapseClassNames}
      isOpen={isOpen}
      {...faqSchemaMicrodataCollapse}
    >
      <div
        className={classNames}
        {...faqSchemaMicrodataBody}
        ref={ref}
        {...otherAttributes}
      >
        {children}
      </div>
    </Collapse>
  )
})
AccordionBody.displayName = 'AccordionBody'

export {
  AccordionBody
}
