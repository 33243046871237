import { forwardRef, type ReactNode } from 'react'

export interface MultiItemCarouselItemItemProps {
  /**
   * Id of element
   */
  id?: string
  /**
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /**
   * Children of element
   */
  children?: ReactNode
}

/**
 * MultiItemCarousel item component that holds a single 'item'
 */
const MultiItemCarouselItem = forwardRef<HTMLDivElement, MultiItemCarouselItemItemProps>(({
  className,
  id,
  children,
  ...otherAttributes
}, ref) => {
  return (
    <div
      id={id}
      className={className}
      ref={ref}
      {...otherAttributes}
    >
      {children}
    </div>
  )
})
MultiItemCarouselItem.displayName = 'MultiItemCarouselItem'

export {
  MultiItemCarouselItem
}
