/**
 * Removes surrounding strings given from a string
 */
function removeSurround (value: string, remove: string[]): string {
  const regex = new RegExp(`^(${remove.join('|')})(.*)\\1$`, 'gs')

  return value.replace(regex, '$2')
}

/**
 * Removes surrounding quotes/single quotes from a string
 */
function removeSurroundQuotes (value: string): string {
  return removeSurround(value, ["'", '"'])
}

export {
  removeSurround,
  removeSurroundQuotes
}
