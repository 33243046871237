import {
  type ReactNode,
  forwardRef,
  type ComponentPropsWithoutRef,
  type ElementType,
  type CSSProperties,
  useMemo
} from 'react'
import { type ThemeColor, Loading } from '../../..'
import clsx from 'clsx'
import Link, { type LinkProps } from 'next/link'

type ButtonAndATagProps = ComponentPropsWithoutRef<'button'> & Omit<ComponentPropsWithoutRef<'a'>, 'href'>
type LinkTagProps = Pick<LinkProps, 'replace' | 'scroll' | 'prefetch' | 'shallow'> & {
  href?: LinkProps['href']
}

export interface ButtonProps extends ButtonAndATagProps, LinkTagProps {
  /**
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /**
   * Children of element
   */
  children?: ReactNode
  /**
   * Defined element type
   */
  tag?: 'a' | 'button'
  /**
   * Disable element
   */
  disabled?: boolean
  /**
   * Theme color for button
   */
  color?: ThemeColor | 'link' | 'link-dark' | 'anchor' | 'anchor-dark' | 'stullerpay' | 'gold'
  /**
   * To use a color that is not in the list of brand colors, set custom color with a HEX code
   *
   * If set, this will override the `color` prop
   */
  customColor?: string
  /**
   * Outline button style or outline-fill button style
   */
  outline?: boolean | 'fill'
  /**
   * Indicates to render button as a pill
   */
  pill?: boolean
  /**
   * Size of button non-default
   */
  size?: 'sm' | 'lg'
  /**
   * If button is active
   */
  active?: boolean
  /**
   * Is a close button
   */
  close?: boolean
  /**
   * Tab index of the button
   */
  tabIndex?: number
  /**
   * Aria label
   */
  'aria-label'?: string
  /**
   * If there should be a loading spinner
   */
  loading?: boolean
}

/**
 * Button component with button styles for actions in forms, dialogs, and more with support for multiple sizes, states, and more.
 *
 * Always use this component when doing `onClick` handlers rather than `<a>` tags.
 * **Anchor tags should only be used for navigation purposes.**
 * If you need a button to look exactly like a link, use `color='anchor'` or another 'anchor' color variant.
 * The default button color is `primary`.
 */
const Button = forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonProps>(({
  className,
  children,
  tag = 'button' as ElementType,
  disabled = false,
  href: hrefIn,
  color = 'primary',
  customColor,
  outline = false,
  pill = false,
  size,
  active = false,
  close = false,
  tabIndex: tabIndexIn,
  'aria-label': ariaLabelIn,
  loading = false,
  style,
  ...otherAttributes
}, ref) => {
  const styles = useMemo(() => {
    let styles: CSSProperties = { ...style }

    if (customColor != null) {
      if (outline === false) {
        styles = {
          ...styles,
          '--bs-btn-bg': customColor
        }
      } else {
        styles = {
          ...styles,
          '--bs-btn-color': customColor
        }
      }
    }

    return styles
  }, [style, outline, customColor])

  const classNames = clsx(
    active && 'active',
    close
      ? 'btn-close'
      : [
          'btn',
          customColor != null
            ? `btn-custom${outline !== false ? '-outline' : ''}`
            : `btn${outline !== false ? '-outline' : ''}-${color}`
        ],
    size != null && `btn-${size}`,
    disabled && 'disabled',
    pill && 'rounded-pill',
    outline === 'fill' && 'btn-outline-fill',
    loading && 'btn-loading',
    className
  )
  const Tag = tag === 'a' ? Link : tag
  const typeOrRole = { [tag === 'button' ? 'type' : 'role']: 'button' }
  const ariaLabel = ariaLabelIn ?? (close ? 'Close' : undefined)
  const href = tag === 'a' ? hrefIn ?? '' : undefined
  const tabIndex = tabIndexIn ?? (tag === 'a' && disabled ? -1 : undefined)

  return (
    <Tag
      className={classNames}
      {...typeOrRole}
      disabled={disabled}
      href={href}
      aria-label={ariaLabel}
      aria-disabled={disabled}
      tabIndex={tabIndex}
      ref={ref}
      style={styles}
      {...otherAttributes}
    >
      {loading
        ? <Loading loading size='xs' color='inherit' inline>{children}</Loading>
        : children}
    </Tag>
  )
})
Button.displayName = 'Button'

export {
  Button
}
