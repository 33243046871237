import { type ReactElement } from 'react'
import { useAuth } from '@stuller/stullercom/feat/auth'
import { MenuPopover, type MenuPopoverProps } from '../MenuPopover'
import { ShowcaseMode } from './ShowcaseMode'
import { StullercomMode } from './StullercomMode'

interface ShowcasePopoverProps extends MenuPopoverProps {
}

/**
 * The popover for showcase (showcase/stullercom mode)
 */
function ShowcasePopover ({
  open,
  onOpenChange,
  reference
}: ShowcasePopoverProps): ReactElement {
  const { isShowcase } = useAuth()

  return (
    <MenuPopover
      open={open}
      onOpenChange={onOpenChange}
      reference={reference}
    >
      <div className='p-6' data-gtm-click-subsection1='Showcase'>
        {isShowcase
          ? <ShowcaseMode />
          : <StullercomMode />}
      </div>
    </MenuPopover>
  )
}

export {
  ShowcasePopover
}
