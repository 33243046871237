import { forwardRef, useContext } from 'react'
import clsx from 'clsx'
import { MultiItemCarouselContext } from './MultiItemCarouselContext'

export interface MultiItemCarouselControlProps {
  /**
   * Id of element
   */
  id?: string
  /**
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /**
   * Direction of the carousel control
   */
  direction?: 'prev' | 'next'
}

/**
 * MultiItemCarousel control component for previous and next buttons
 */
const MultiItemCarouselControl = forwardRef<HTMLButtonElement, MultiItemCarouselControlProps>(({
  className,
  direction = 'prev',
  ...otherAttributes
}, ref) => {
  const { maxIndex, handleNext, handlePrev } = useContext(MultiItemCarouselContext)
  const classNames = clsx(
    `carousel-control-${direction} d-none d-md-flex`,
    className
  )
  const iconClassNames = `carousel-control-${direction}-icon`

  // Handle clicking the button to update the current index
  function handleClick (): void {
    switch (direction) {
      case 'prev': {
        handlePrev()
        break
      }
      case 'next': {
        handleNext()
        break
      }
    }
  }

  // Do not render if max index < 1
  if (maxIndex < 1) {
    return null
  }

  return (
    <button
      type='button'
      className={classNames}
      onClick={handleClick}
      data-bs-slide={direction}
      ref={ref}
      {...otherAttributes}
    >
      <span className={iconClassNames} aria-hidden='true' />
      <span className='visually-hidden'>{direction}</span>
    </button>
  )
})
MultiItemCarouselControl.displayName = 'MultiItemCarouselControl'

export {
  MultiItemCarouselControl
}
