import { type ReactElement, memo } from 'react'
import { type HeaderProps } from '@stuller/stullercom/feat/layout-context'
import { StullerFirstBanner } from '@stuller/stullercom/feat/stuller-first'
import { ConsultantModeNav } from '@stuller/stullercom/feat/consultant-mode-nav'
import { useAuth } from '@stuller/stullercom/feat/auth'
import { SiteWide } from './SiteWide'
import { ImpersonationNav } from './impersonation-nav/ImpersonationNav'
import { UtilityNav } from './utility-nav/UtilityNav'
import { SearchNav } from './search-nav/SearchNav'
import { MainNav } from './main-nav/MainNav'
import { ToastContainer } from '@stuller/stullercom/ui'

/**
 * Header component for the site
 * Consultant mode, site wide, stuller first banner, impersonation nav, utility nav, toast container, search nav, main nav
 */
const Header = memo(({
  disabled = false
}: HeaderProps): ReactElement => {
  const { isJewelerShowcasePunchout } = useAuth()
  if (disabled || isJewelerShowcasePunchout) {
    return <ToastContainer />
  }

  return (
    <>
      <ConsultantModeNav />
      <SiteWide />
      <StullerFirstBanner />
      <ImpersonationNav />
      <UtilityNav />
      <ToastContainer />
      <SearchNav />
      <MainNav />
    </>
  )
})
Header.displayName = 'Header'

export {
  Header
}
