import { type ReactElement, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import Cookies from 'js-cookie'
import { DropdownItem } from '@stuller/stullercom/ui'
import { ContentstackTargetingPreviewer } from './ContentstackTargetingPreviewer'

const CMS_EDITING_COOKIE_NAME = 'CMS_Editing_Cookie'

interface CmsToolsProps {
  /**
   * Callback to toggle the tools dropdown
   */
  onToggleTools: () => void
}

/**
 * Shows CMS related tools
 */
function CmsTools ({ onToggleTools }: CmsToolsProps): ReactElement {
  const router = useRouter()
  const [cmsMode, setCmsMode] = useState(false)

  /**
   * Handle CMS Edit
   */
  function handleToggleCmsMode (): void {
    Cookies.set(CMS_EDITING_COOKIE_NAME, (!cmsMode).toString(), { secure: true })
    router.reload()
  }

  /**
   * On component load, read cookie and set defaults
   */
  useEffect(() => {
    setCmsMode(Cookies.get(CMS_EDITING_COOKIE_NAME)?.toLowerCase() === 'true')
  }, [])

  return (
    <>
      <DropdownItem onClick={handleToggleCmsMode}>
        {cmsMode ? 'Disable' : 'Enable'} CMS Mode
      </DropdownItem>
      <DropdownItem tag='a' href='/cms'>
        Legacy CMS
      </DropdownItem>
      <DropdownItem tag='a' href='https://app.contentstack.com/#!/stack/blt9ce5c28715b98857/entries'>
        Contentstack
      </DropdownItem>
      {cmsMode && <ContentstackTargetingPreviewer onToggleTools={onToggleTools} />}
    </>
  )
}

export {
  CmsTools
}
