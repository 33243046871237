import { forwardRef, useMemo, type CSSProperties } from 'react'
import { cardTypesAndIcons } from '../../util/payment-methods/constants'
import clsx from 'clsx'

export interface CreditCardIconProps {
  /**
   * Id of element
   */
  id?: string
  /**
   * The type of credit card to display.
   */
  displayCardType?: string | undefined | null
  /**
   * The width of the icon.
   */
  width?: number
  /**
   * Additional class name(s) to give to the containing element.
   */
  className?: string
  /**
   * Additional styles to apply to the containing element.
   */
  style?: CSSProperties
}

/**
 * CreditCardIcon component for rendering a credit card icon.
 */
const CreditCardIcon = forwardRef<HTMLSpanElement, CreditCardIconProps>(({
  id,
  displayCardType,
  width = 30,
  className,
  style,
  ...otherAttributes
}, ref) => {
  const classNames = clsx('form-credit-card-icon', className)
  const matchingCardType = useMemo(() => {
    let cardType = cardTypesAndIcons.filter(cardType => cardType.type.toLowerCase() === displayCardType?.toLowerCase())
    if (displayCardType == null || cardType == null || cardType.length === 0) {
      cardType = cardTypesAndIcons.filter(cardType => cardType.type.toLowerCase() === 'n/a')
    }

    return cardType
  }, [displayCardType])

  const iconStyles = useMemo(() => {
    return { width: `${width}px` }
  }, [width])

  return (
    <span id={id} className={classNames} style={style} {...otherAttributes}>
      <img src={matchingCardType[0].icon} alt={matchingCardType[0].type} style={iconStyles} />
    </span>
  )
})

export { CreditCardIcon }
