import type { TargetingMap } from '@stuller/contentstack/util/types'
import { useAuth } from '@stuller/stullercom/feat/auth'
import { useTargetingPreviewer } from './targetingPreviewer'

/**
 * Hook to check if website is in Showcase Mode or Jeweler Showcase mode, or not in either
 */
function useShowcaseTargeting (targeting: TargetingMap['SHOWCASE']): boolean {
  const [targetingPreviewer] = useTargetingPreviewer()
  const auth = useAuth()
  const { isShowcase } = auth
  const isTargetingShowcase = targetingPreviewer?.isShowcase != null ? targetingPreviewer.isShowcase : isShowcase

  return targeting.targetValue ? isTargetingShowcase : !isTargetingShowcase
}

export { useShowcaseTargeting }
