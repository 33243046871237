import React, { type ReactElement } from 'react'
import { ApolloProvider } from '@apollo/client'
import { useBootstrap } from '@stuller/stullercom/ui'
import { Layout } from '@stuller/stullercom/feat/layout'
import { useStullerEvents } from '@stuller/stullercom/feat/stuller-events'
import { useSfmcConversionTracking } from '@stuller/stullercom/feat/sfmc-tracking'
import { useFormBuilder } from '@stuller/stullercom/feat/form-builder'
import { useApollo } from '@stuller/stullercom/data-access/apollo-client'
import { AuthProvider } from '@stuller/stullercom/feat/auth'
import { UserAgentProvider } from '@stuller/stullercom/feat/user-agent'
import { ReactMount } from '@stuller/stullercom/feat/react-mount'
import { CmsContentOptionsContextProvider } from '@stuller/stullercom/feat/cms-content'
import { useDatadogClientLogs } from '@stuller/stullercom/feat/datadog-logs'
import { useDatadogRum } from '@stuller/stullercom/feat/datadog-rum'
import { Error } from '@stuller/stullercom/feat/error-pages'
import { useGetAuthUserQuery } from '@stuller/stullercom/data-access/apollo-queries'
import { getInitialProps } from './getInitialProps'
import { type AppProps } from './types'

// Suppress `useLayoutEffect` (and its warnings) when not running in a browser
if (typeof window === 'undefined') {
  React.useLayoutEffect = () => {}
}

/**
 * Custom `App` to initialize all pages
 * See https://nextjs.org/docs/advanced-features/custom-app
 * Note that CSS imports must still be done in `_app.tsx`
 */
function App ({
  Component,
  pageProps,
  host,
  asPath,
  authUser: authUserIn,
  sessionId,
  showcaseSettings,
  ua,
  apolloClient: apolloClientIn,
  apolloState
}: AppProps): ReactElement | null {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const apolloClient = useApollo(apolloClientIn, apolloState)
  const { data } = useGetAuthUserQuery({ client: apolloClient })
  const authUser = data?.authUser ?? authUserIn
  useDatadogClientLogs(authUser)
  useDatadogRum(authUser)
  useBootstrap()
  useStullerEvents()
  useSfmcConversionTracking()
  useFormBuilder()

  // If http code is set for redirect, don't render anything
  if (pageProps.statusCode != null && [301, 302, 303, 307, 308].includes(pageProps.statusCode as unknown as number)) {
    return null
  }

  return (
    <ApolloProvider client={apolloClient}>
      <AuthProvider authUser={authUser} host={host} sessionId={sessionId} showcaseSettings={showcaseSettings}>
        <UserAgentProvider ua={ua}>
          <CmsContentOptionsContextProvider url={asPath}>
            <Layout layoutProps={Component.layout}>
              {pageProps.statusCode != null
                ? <Error statusCode={pageProps.statusCode} errorTemplate={pageProps.errorTemplate} />
                : <Component key={asPath} {...pageProps} />}
            </Layout>
          </CmsContentOptionsContextProvider>
          <ReactMount />
        </UserAgentProvider>
      </AuthProvider>
    </ApolloProvider>
  )
}

App.getInitialProps = getInitialProps

export {
  App
}
