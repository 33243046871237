import { type ReactElement, type ReactNode, useMemo } from 'react'
import clsx from 'clsx'
import { FormGroupContext, type FormGroupContextType } from '../../../..'

interface FormGroupProps extends FormGroupContextType {
  /**
   * Id of element
   */
  id?: string
  /**
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /**
   * Form input/label etc.
   */
  children?: ReactNode
  /**
   * Indicates the group is for a checkbox, radio, or switch
   */
  check?: boolean | 'switch'
  /**
   * Indicates to reverse the order of the label and input for checkbox, radio, or switch
   */
  reverse?: boolean
  /**
   * Indicates the form group inline for checkbox, radio, or switch
   */
  inline?: boolean
  /**
   * Indicates the form group uses a floating label
   */
  floating?: boolean
}

/**
 * Form group wrapper for form inputs that can automatically send `htmlFor`/`id` to input/labels.
 */
function FormGroup ({
  className,
  children,
  groupId,
  check = false,
  reverse = false,
  inline = false,
  floating = false,
  ...otherAttributes
}: FormGroupProps): ReactElement {
  const context = useMemo(() => ({ groupId }), [groupId])
  const classNames = clsx(
    check !== false && [
      'form-check',
      check === 'switch' && 'form-switch',
      reverse && 'form-check-reverse',
      inline && 'form-check-inline'
    ],
    floating && 'form-floating',
    className
  )

  return (
    <FormGroupContext.Provider value={context}>
      <div
        className={classNames}
        {...otherAttributes}
      >
        {children}
      </div>
    </FormGroupContext.Provider>
  )
}

export {
  FormGroup
}
