import { type ComponentRef, type ReactElement, useState } from 'react'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'
import { useToggle } from '@stuller/shared/util/react-hooks'
import { MenuItem } from '../MenuItem'
import { LoginPopover } from './LoginPopover'

/**
 * Menu item and dropdown for login
 */
function MenuLogin (): ReactElement {
  const [open, handleToggle, handleOpenChange] = useToggle()
  const [reference, setReference] = useState<ComponentRef<typeof MenuItem> | null>(null)

  return (
    <>
      <MenuItem
        icon={fas.faUser}
        text='Login'
        open={open}
        onClick={handleToggle}
        setReference={setReference}
      />
      <LoginPopover
        open={open}
        onOpenChange={handleOpenChange}
        reference={reference}
      />
    </>
  )
}

export {
  MenuLogin
}
