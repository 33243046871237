import { type ReactElement, memo } from 'react'
import { useAuth } from '@stuller/stullercom/feat/auth'
import { ImpersonationInfo } from './ImpersonationInfo'
import { EnvironmentAlert } from './EnvironmentAlert'
import { siteConfig } from '@stuller/stullercom/util/site-config'
import { AdminTools } from './AdminTools'

/**
 * Current impersonation info if user can impersonate
 */
const ImpersonationNav = memo((): ReactElement | null => {
  const { hasRole, isJewelerShowcase, authUser } = useAuth()
  const showCmsTools = !isJewelerShowcase &&
    (hasRole('CMS Admin') || hasRole('CMS Editor') || hasRole('CMS Previewer') || hasRole('CMS Publisher'))
  const showDevtools = !isJewelerShowcase &&
    (hasRole('CMS Admin') || hasRole('CMS Editor') || hasRole('CMS Previewer') || hasRole('CMS Publisher'))
  const showAdminTools = showCmsTools || showDevtools
  const showEnvironmentAlert = siteConfig.NEXT_PUBLIC_ENVIRONMENT_ALERT != null
  const showImpersonationInfo = !isJewelerShowcase && (hasRole('Can Impersonate Users') || authUser?.impersonatedBy != null)

  if (!showAdminTools && !showEnvironmentAlert && !showImpersonationInfo) {
    return null
  }

  return (
    <header className='bg-gray-100 d-print-none'>
      <div className='container-xxxl px-4'>
        <div className='row gx-5 justify-content-center align-items-center text-nowrap py-2'>
          <div className='col'>
            {showAdminTools && <AdminTools showCmsTools={showCmsTools} showDevTools={showDevtools} />}
          </div>
          <div className='col-auto'>
            {showEnvironmentAlert && <EnvironmentAlert />}
          </div>
          <div className='col text-end'>
            {showImpersonationInfo && <ImpersonationInfo />}
          </div>
        </div>
      </div>
    </header>
  )
})
ImpersonationNav.displayName = 'ImpersonationNav'

export {
  ImpersonationNav
}
