import { type ReactNode, forwardRef } from 'react'
import clsx from 'clsx'

export interface CarouselCaptionProps {
  /**
   * Id of element
   */
  id?: string
  /**
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /**
   * Children of element
   */
  children?: ReactNode
}

/**
 * Carousel caption component for a carousel item
 *
 * This component will only be shown on `md` and up by default
 */
const CarouselCaption = forwardRef<HTMLDivElement, CarouselCaptionProps>(({
  className,
  children,
  ...otherAttributes
}, ref) => {
  const classNames = clsx(
    'carousel-caption',
    className
  )

  return (
    <div
      className={classNames}
      ref={ref}
      {...otherAttributes}
    >
      {children}
    </div>
  )
})
CarouselCaption.displayName = 'CarouselCaption'

export {
  CarouselCaption
}
