import {
  forwardRef,
  type ReactNode,
  type CSSProperties,
  useContext,
  useImperativeHandle
} from 'react'
import clsx from 'clsx'
import { MultiItemCarouselContext } from './MultiItemCarouselContext'
import { ShadowContainer } from '../../..'

export interface MultiItemCarouselInnerProps {
  /**
   * Id of element
   */
  id?: string
  /**
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /**
   * Inline styles to pass to the containing element
   */
  style?: Omit<CSSProperties, 'gap'>
  /**
   * Value of gap between product carousel items
   */
  gap?: string | number
  /**
   * Children of element
   */
  children?: ReactNode
}

/**
 * A slideshow component for cycling through elements—images or slides of text—like a carousel.
 */
const MultiItemCarouselInner = forwardRef<HTMLDivElement, MultiItemCarouselInnerProps>(({
  className,
  children,
  style,
  gap = '1.5rem',
  ...otherAttributes
}, ref) => {
  const { multiItemCarouselInnerRef } = useContext(MultiItemCarouselContext)
  // Expose innerRef, must use non-null assertion because signature requires it and conditional is not allowed
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  useImperativeHandle(ref, () => multiItemCarouselInnerRef.current!, [multiItemCarouselInnerRef])
  const classNames = clsx(
    'd-flex overflow-md-hidden',
    className
  )

  return (
    <ShadowContainer
      className={classNames}
      shadowClassName='carousel-inner'
      style={{ gap, ...style }}
      orientation='horizontal'
      ref={multiItemCarouselInnerRef}
      {...otherAttributes}
    >
      {children}
    </ShadowContainer>
  )
})
MultiItemCarouselInner.displayName = 'MultiItemCarouselInner'

export {
  MultiItemCarouselInner
}
