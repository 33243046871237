import { useState } from 'react'
import {
  useIntersectionObserver,
  type IntersectionObserverOptions,
  type UseIntersectionObserver
} from './useIntersectionObserver'

type TrackVisibilityOptions = IntersectionObserverOptions

type UseTrackVisibility = [
  UseIntersectionObserver[0],
  UseIntersectionObserver[1] & {
    /**
     * Indicates whether the element is currently visible
     */
    visible: boolean
    /**
     * Indicates whether the element has ever been visible
     */
    wasEverVisible: boolean
  }
]

/**
 * Hook that wraps `useIntersectionObserver` if you just care about tracking visibility (not the whole entry etc.)
 * See https://github.com/onderonur/react-intersection-observer-hook
 */
function useTrackVisibility (args?: TrackVisibilityOptions): UseTrackVisibility {
  const [ref, result] = useIntersectionObserver(args)
  const visible = result.entry?.isIntersecting === true
  const [wasEverVisible, setWasEverVisible] = useState(visible)

  if (visible && !wasEverVisible) {
    setWasEverVisible(true)
  }

  return [ref, { ...result, visible, wasEverVisible }]
}

export {
  useTrackVisibility
}
