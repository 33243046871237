import { forwardRef, type ReactNode } from 'react'
import { clsx } from 'clsx'

export interface NavItemProps {
  /**
   * Id of element
   */
  id?: string
  /**
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /**
   * Children of element
   */
  children?: ReactNode
}

/**
 * NavItem component to hold navigation items
 */
const NavItem = forwardRef<HTMLLIElement, NavItemProps>(({
  className,
  children,
  ...otherAttributes
}, ref) => {
  const classNames = clsx('nav-item', className)

  return (
    <li
      className={classNames}
      ref={ref}
      {...otherAttributes}
    >
      {children}
    </li>
  )
})
NavItem.displayName = 'NavItem'

export {
  NavItem
}
