import { useState, useCallback } from 'react'

interface UseToggleKeyStateType {
  /**
   * The currently open item(s)
   */
  open: string | null | string[]
  /**
   * Function to call when an item is toggled
   */
  onToggle: (key: string) => void
}

type UseToggleKeyState = [
  UseToggleKeyStateType['open'],
  UseToggleKeyStateType['onToggle'],
  (open: UseToggleKeyStateType['open']) => void
]

/**
 * State management for toggling certain key(s) (useful for accordions)
 *
 * Send `defaultOpen` as an array to allow items to stay "toggled" when another is toggled.
 */
function useToggleKey (defaultOpen: UseToggleKeyStateType['open'] = null): UseToggleKeyState {
  const [open, setOpen] = useState(defaultOpen)

  // Handles updating the accordion open state
  const handleToggle = useCallback((key: string) => {
    if (Array.isArray(open)) {
      if (open.includes(key)) {
        setOpen(open.filter(k => k !== key))
      } else {
        setOpen([...open, key])
      }
    } else {
      if (key === open) {
        setOpen(null)
      } else {
        setOpen(key)
      }
    }
  }, [open, setOpen])

  return [open, handleToggle, setOpen]
}

export {
  useToggleKey
}
