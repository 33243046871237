/**
 * Given a value, throw if the value is null | undefined
 */
function throwIfNull<T> (value: T | null | undefined, errorMessage: string): T {
  if (value == null) {
    throw new Error(errorMessage)
  }

  return value
}

export {
  throwIfNull
}
