import { type ReactNode, forwardRef } from 'react'
import clsx from 'clsx'

export interface PaginationItemProps {
  /**
   * ID of the element
   */
  id?: string
  /**
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /**
   * Children of element
   */
  children?: ReactNode
  /**
   * Whether the item is active
   */
  active?: boolean
  /**
   * Whether the item is disabled
   */
  disabled?: boolean
}

const PaginationItem = forwardRef<HTMLLIElement, PaginationItemProps>(({
  id,
  className,
  children,
  active = false,
  disabled = false,
  ...otherAttributes
}, ref) => {
  const classNames = clsx(
    'page-item',
    active && 'active',
    disabled && 'disabled',
    className
  )

  return (
    <li id={id} className={classNames} {...otherAttributes} ref={ref}>
      {children}
    </li>
  )
})

export {
  PaginationItem
}
