import { type RefObject, createContext, createRef } from 'react'

export interface MultiItemCarouselContextType {
  /**
   * Index of the current item in the carousel
   */
  index: number
  /**
   * The max index in the carousel
   */
  maxIndex: number
  /**
   * Handles updating the index directly
   */
  handleIndex: (index: number) => void
  /**
   * Handles going to previous
   */
  handlePrev: () => void
  /**
   * Handles going to next
   */
  handleNext: () => void
  /**
   * The ref for `MultiItemCarouselInner`
   */
  multiItemCarouselInnerRef: RefObject<HTMLDivElement>
}

const MultiItemCarouselContext = createContext<MultiItemCarouselContextType>({
  index: 0,
  maxIndex: 0,
  handleIndex: () => {},
  handlePrev: () => {},
  handleNext: () => {},
  multiItemCarouselInnerRef: createRef()
})

export {
  MultiItemCarouselContext
}
