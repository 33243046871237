import { type ComponentType } from 'react'
import dynamic from 'next/dynamic'

const TestMount = dynamic<any>(async () => await import('./TestMount').then((i) => i.TestMount))

/**
 * React components that can be used for 'react-mount'
 */
const components: Record<string, ComponentType<any>> = {
  TestMount
}

export {
  components
}
