import { type ReactElement, type MouseEvent } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { far, fas } from '@awesome.me/kit-3dbd93c064/icons'
import { NotificationStatus } from '@stuller/stullercom/data-access/apollo-queries'
import { type Notification } from './types'

interface MessageReadIconProps {
  status: Notification['status']
  /**
   * Handler to call to toggle read/unread
   */
  onClick: (event: MouseEvent<HTMLDivElement>) => Promise<void>
}

/**
 * The read/unread icon for the message card
 */
function MessageReadIcon ({ status, onClick }: MessageReadIconProps): ReactElement {
  const title = `Mark as ${status === NotificationStatus.Read ? 'unread' : 'read'}`

  return (
    <div className='text-secondary cursor-pointer fs-3' onClick={onClick} title={title}>
      <FontAwesomeIcon icon={status === NotificationStatus.Read ? fas.faCircleCheck : far.faCircle} className='d-inline-block' />
      <FontAwesomeIcon icon={fas.faCircleCheck} className='d-none text-primary' />

      <style jsx>{`
        div:hover :global(.d-none) {
          display: inline-block !important;
        }
        div:hover :global(.d-inline-block) {
          display: none !important;
        }
      `}
      </style>
    </div>
  )
}

export {
  MessageReadIcon
}
