import {
  useState,
  useCallback,
  useEffect,
  useRef,
  useMemo,
  useImperativeHandle,
  type MutableRefObject
} from 'react'

export type IntersectionObserverOptions = Omit<IntersectionObserverInit, 'root'>

type IntersectionObserverHookRefNode = any

type IntersectionObserverHookRootRefNode = any

export type IntersectionObserverHookRef = MutableRefObject<IntersectionObserverHookRefNode>

export type IntersectionObserverHookRootRef = MutableRefObject<IntersectionObserverHookRootRefNode>

export type UseIntersectionObserver = [
  IntersectionObserverHookRef,
  {
    /**
     * The current intersection entry
     */
    entry: IntersectionObserverEntry | undefined
    /**
     * The ref to the root element to track intersection
     */
    rootRef: IntersectionObserverHookRootRef
  }
]

/**
 * Hook to help easily track if a component is visible or not
 * Can be used to create lazy loading images, trigger animations on entering or leaving the screen etc
 * See https://github.com/onderonur/react-intersection-observer-hook
 */
function useIntersectionObserver (args?: IntersectionObserverOptions): UseIntersectionObserver {
  const rootMargin = useMemo(() => args?.rootMargin ?? '0px', [args?.rootMargin])
  const threshold = useMemo(() => args?.threshold ?? [0], [args?.threshold])
  const nodeRef = useRef<IntersectionObserverHookRefNode>(null)
  const rootRef = useRef<IntersectionObserverHookRootRefNode>(null)
  const observerRef = useRef<IntersectionObserver | null>(null)
  const [entry, setEntry] = useState<IntersectionObserverEntry>()

  /**
   * Disconnect the current observer (if there is one)
   */
  const unobserve = useCallback(() => {
    const currentObserver = observerRef.current
    currentObserver?.disconnect()
    observerRef.current = null
  }, [])

  /**
   * Create a observer for current node with given options
   */
  const observe = useCallback(() => {
    const node = nodeRef.current

    if (node != null) {
      const root = rootRef.current
      const options = { root, rootMargin, threshold }
      const observer = new IntersectionObserver(([newEntry]) => {
        setEntry(newEntry)
      }, options)
      observer.observe(node as Element)
      observerRef.current = observer
    }
  }, [rootMargin, threshold])

  /**
   * Unobserve the current node and observe it again with the new options
   */
  const initializeObserver = useCallback(() => {
    unobserve()
    observe()
  }, [observe, unobserve])

  // Wrap the node ref to initialize the observer
  useImperativeHandle(nodeRef, () => {
    initializeObserver()

    return nodeRef.current
  }, [nodeRef, initializeObserver])

  // Wrap the root ref to initialize the observer
  useImperativeHandle(rootRef, () => {
    initializeObserver()

    return rootRef.current
  }, [rootRef, initializeObserver])

  // Initialize
  useEffect(() => {
    // After React 18, StrictMode unmounts and mounts components to be sure if they are resilient effects being mounted and destroyed multiple times.
    // This a behavior to be sure nothing breaks when off-screen components can preserve their state with future React versions.
    // So in StrictMode, React unmounts the component, clean-up of this useEffect gets triggered and we stop observing the node.
    // But we need to start observing after component re-mounts with its preserved state.
    // So to handle this case, we call initializeObserver here.
    // https://reactjs.org/blog/2022/03/08/react-18-upgrade-guide.html#updates-to-strict-mode
    initializeObserver()

    return () => {
      // Disconnect the observer on unmount to prevent memory leaks etc.
      unobserve()
    }
  }, [initializeObserver, unobserve])

  return [nodeRef, { entry, rootRef }]
}

export {
  useIntersectionObserver
}
