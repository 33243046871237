/**
 * Strip out special characters/spacing; with optional prefix param
 */
function stringToHtmlId (text: string | null | undefined, prefix?: string): string {
  const regex = /[^a-zA-Z0-9]/g
  const newString = text?.replace(regex, '')?.toLowerCase()

  if (text == null) {
    return ''
  }

  if (prefix != null) {
    return `${prefix}${newString}`
  }

  return `${newString}`
}

export {
  stringToHtmlId
}
