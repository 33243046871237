import { type ReactElement, useContext, useEffect, useState } from 'react'
import {
  useGetCartItemCountQuery,
  useGetCartLazyQuery,
  useCancelScheduledOrderMutation
} from '@stuller/stullercom/data-access/apollo-queries'
import { MenuCartContext } from './MenuCartContext'
import { CartPopoverContext } from './CartPopoverContext'
import { MenuPopover, type MenuPopoverProps } from '../MenuPopover'
import { CartPopoverInner } from './CartPopoverInner'
import { CartLinkButton } from './CartLinkButton'
import { CartScheduledOrder } from './CartScheduledOrder'
import { CartCheckoutButton } from './CartCheckoutButton'
import { logger } from '@stuller/stullercom/feat/datadog-logs'

interface CartPopoverProps extends MenuPopoverProps {
}

/**
 * The popover for cart (cart items, cart links, scheduled order, etc.)
 */
function CartPopover ({
  open,
  onOpenChange,
  reference
}: CartPopoverProps): ReactElement {
  const { hasScheduledOrder } = useContext(MenuCartContext)
  const [countChanged, setCountChanged] = useState(false)
  const cartItemCount = useGetCartItemCountQuery({
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true
  })
  const [, cart] = useGetCartLazyQuery({
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true
  })
  const [cancelScheduledOrder, scheduledOrderCancel] = useCancelScheduledOrderMutation({
    onError: (error: any) => {
      logger.error('Could not cancel scheduled order', {}, error)
    },
    onCompleted: () => {
      void cartItemCount.refetch()
    }
  })

  // Trigger fetching data on start, or if count changes
  useEffect(() => {
    if (cartItemCount.data == null) {
      return
    }

    // If the cart item count changed set a flag to refetch cart
    const cartItemCountChanged = cartItemCount.data?.cartItemCount !== cartItemCount.previousData?.cartItemCount
    const scheduledOrderChanged = cartItemCount.data?.viewer?.scheduledOrder?.id !== cartItemCount.previousData?.viewer?.scheduledOrder?.id
    if (cartItemCountChanged || scheduledOrderChanged) {
      setCountChanged(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartItemCount.data])

  // Refetch on open if a query errored or the unread count changed
  useEffect(() => {
    if (open === true) {
      setCountChanged(false)
      if (cartItemCount.error != null) {
        void cartItemCount.refetch()
      } else if (cart.error != null || countChanged) {
        void cart.refetch()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  return (
    <MenuPopover
      open={open}
      onOpenChange={onOpenChange}
      reference={reference}
    >
      <div data-gtm-click-subsection1='Cart' data-test='cart-dropdown'>
        <CartPopoverContext.Provider
          value={{
            cart,
            cartItemCount,
            cancelScheduledOrder,
            scheduledOrderCancel
          }}
        >
          <CartLinkButton />
          <CartCheckoutButton />
          {hasScheduledOrder && <CartScheduledOrder />}
          <CartPopoverInner />
        </CartPopoverContext.Provider>
      </div>
    </MenuPopover>
  )
}

export {
  CartPopover
}
