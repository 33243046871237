import clsx from 'clsx'
import { forwardRef } from 'react'

export interface DropdownDividerProps {
  /**
   * Id of element
   */
  id?: string
  /**
   * Additional class name(s) to give to the containing element
   */
  className?: string
}

/**
 * Dropdown list item divider item to display in a dropdown menu
 */
const DropdownDivider = forwardRef<HTMLLIElement, DropdownDividerProps>(({
  className,
  ...otherAttributes
}, ref) => {
  const classNames = clsx('dropdown-divider', className)

  return (
    <li ref={ref}>
      <hr className={classNames} {...otherAttributes} />
    </li>
  )
})
DropdownDivider.displayName = 'DropdownDivider'

export {
  DropdownDivider
}
