import { fas } from '@awesome.me/kit-3dbd93c064/icons'
import { NotificationApp, NotificationType } from '@stuller/stullercom/data-access/apollo-queries'
import { type NotificationSettings } from './types'

export const NOTIFICATION_APP = NotificationApp.Stullercom

export const SHIPMENT_TYPES: NotificationType[] = [
  NotificationType.ShipmentNew,
  NotificationType.ShipmentUpdate,
  NotificationType.ShipmentSummary,
  NotificationType.ShipmentGreenCheck,
  NotificationType.ShipmentGreenTruck,
  NotificationType.ShipmentYellowExclamation
]
export const MESSAGES_UPDATES_TYPES: NotificationType[] = [
  NotificationType.AccountUpdate,
  NotificationType.FeatureNew,
  NotificationType.MessageNew,
  NotificationType.MembershipUpdate,
  NotificationType.ProjectUpdate,
  NotificationType.ReturnUpdate,
  NotificationType.StatementNew,
  NotificationType.BlueCheck,
  NotificationType.BlueHourglass,
  NotificationType.BlueInfo,
  NotificationType.GreenCheck,
  NotificationType.RedExclamation,
  NotificationType.NewGreenCalendar,
  NotificationType.NewGreenInfo,
  NotificationType.NewGreenSparkles,
  NotificationType.NewGreenTag,
  NotificationType.YellowExclamation
]

export const NOTIFICATIONS_SETTINGS: Record<NotificationType, NotificationSettings> = {
  [NotificationType.ShipmentNew]: {
    color: 'green-300',
    textColor: 'green-500',
    backgroundColor: 'green-100',
    icon: fas.faBoxArchive
  },
  [NotificationType.ShipmentUpdate]: {
    color: 'green-300',
    textColor: 'green-500',
    backgroundColor: 'green-100',
    icon: fas.faBoxArchive
  },
  [NotificationType.ShipmentSummary]: {
    color: 'green-300',
    textColor: 'green-500',
    backgroundColor: 'green-100',
    icon: fas.faBoxArchive
  },
  [NotificationType.ShipmentGreenCheck]: {
    color: 'green-300',
    textColor: 'green-500',
    backgroundColor: 'green-100',
    icon: fas.faCircleCheck
  },
  [NotificationType.ShipmentGreenTruck]: {
    color: 'green-300',
    textColor: 'green-500',
    backgroundColor: 'green-100',
    icon: fas.faTruckFast
  },
  [NotificationType.ShipmentYellowExclamation]: {
    color: 'yellow-300',
    textColor: 'yellow-600',
    backgroundColor: 'yellow-100',
    icon: fas.faTriangleExclamation
  },
  [NotificationType.AccountUpdate]: {
    color: 'yellow-300',
    textColor: 'yellow-600',
    backgroundColor: 'yellow-100',
    icon: fas.faTriangleExclamation
  },
  [NotificationType.FeatureNew]: {
    color: 'blue-300',
    textColor: 'blue-500',
    backgroundColor: 'blue-100',
    icon: fas.faCircleInfo
  },
  [NotificationType.MessageNew]: {
    color: 'blue-300',
    textColor: 'blue-500',
    backgroundColor: 'blue-100',
    icon: fas.faCircleInfo
  },
  [NotificationType.MembershipUpdate]: {
    color: 'yellow-300',
    textColor: 'yellow-600',
    backgroundColor: 'yellow-100',
    icon: fas.faTriangleExclamation
  },
  [NotificationType.ProjectUpdate]: {
    color: 'blue-300',
    textColor: 'blue-500',
    backgroundColor: 'blue-100',
    icon: fas.faCircleInfo
  },
  [NotificationType.ReturnUpdate]: {
    color: 'blue-300',
    textColor: 'blue-500',
    backgroundColor: 'blue-100',
    icon: fas.faCircleInfo
  },
  [NotificationType.StatementNew]: {
    color: 'blue-300',
    textColor: 'blue-500',
    backgroundColor: 'blue-100',
    icon: fas.faCircleInfo
  },
  [NotificationType.BlueCheck]: {
    color: 'blue-300',
    textColor: 'blue-500',
    backgroundColor: 'blue-100',
    icon: fas.faCircleCheck
  },
  [NotificationType.BlueHourglass]: {
    color: 'blue-300',
    textColor: 'blue-500',
    backgroundColor: 'blue-100',
    icon: fas.faHourglassHalf
  },
  [NotificationType.BlueInfo]: {
    color: 'blue-300',
    textColor: 'blue-500',
    backgroundColor: 'blue-100',
    icon: fas.faCircleInfo
  },
  [NotificationType.GreenCheck]: {
    color: 'green-300',
    textColor: 'green-500',
    backgroundColor: 'green-100',
    icon: fas.faCircleCheck
  },
  [NotificationType.RedExclamation]: {
    color: 'red-300',
    textColor: 'red-500',
    backgroundColor: 'red-100',
    icon: fas.faCircleExclamation
  },
  [NotificationType.NewGreenCalendar]: {
    color: 'new-green-300',
    textColor: 'new-green-400',
    backgroundColor: 'new-green-100',
    icon: fas.faCalendarDays
  },
  [NotificationType.NewGreenInfo]: {
    color: 'new-green-300',
    textColor: 'new-green-400',
    backgroundColor: 'new-green-100',
    icon: fas.faCircleInfo
  },
  [NotificationType.NewGreenSparkles]: {
    color: 'new-green-300',
    textColor: 'new-green-400',
    backgroundColor: 'new-green-100',
    icon: fas.faSparkles
  },
  [NotificationType.NewGreenTag]: {
    color: 'new-green-300',
    textColor: 'new-green-400',
    backgroundColor: 'new-green-100',
    icon: fas.faTag
  },
  [NotificationType.YellowExclamation]: {
    color: 'yellow-300',
    textColor: 'yellow-600',
    backgroundColor: 'yellow-100',
    icon: fas.faTriangleExclamation
  }
}
