import { useContext } from 'react'
import { CmsContentOptionsContext } from './CmsContentOptionsContext'
import { type CmsOptionsInput } from '@stuller/stullercom/data-access/apollo-queries'

/**
 * Retrieves current CMS content context variables
 */
function useCmsContentOptionsContext (): CmsOptionsInput {
  return useContext(CmsContentOptionsContext)
}

export { useCmsContentOptionsContext }
