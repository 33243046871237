import { type CSSProperties, type ReactElement, type ReactNode, forwardRef } from 'react'
import clsx from 'clsx'
import { type ThemeColor, type Color } from '../../..'

export interface SpinnerProps {
  /**
   * Id of element
   */
  id?: string
  /**
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /**
   * Inline styles to pass to the containing element
   */
  style?: CSSProperties
  /**
   * Children of the visually hidden span (default 'Loading...')
   */
  children?: ReactNode
  /**
   * Type of spinner
   */
  type?: 'border' | 'grow'
  /**
   * Defined element type
   */
  tag?: 'div' | 'span'
  /**
   * Color for spinner (use 'inherit' to match current text color)
   */
  color?: ThemeColor | Color | 'inherit'
  /**
   * Size of spinner non-default
   */
  size?: 'xs' | 'sm' | 'lg'
}

export interface SpinnerBorderCircleProps {
  /**
   * Additional classes
   */
  className?: string
}

export interface SpinnerBorderCircleClipperProps {
  /**
   * Circle clipper children
   */
  children: ReactNode
}

/**
 * Spinner border circle
 */
function SpinnerBorderCircle ({ className }: SpinnerBorderCircleProps): ReactElement {
  return <div className={clsx(className, 'circle rounded-circle h-100 position-absolute top-0 bottom-0 end-0 w-200')} />
}

/**
 * Spinner border circle clipper side
 */
function SpinnerBorderCircleClipper ({ children }: SpinnerBorderCircleClipperProps): ReactElement {
  return (
    <div className='circle-clipper d-inline-block h-100 w-50 overflow-hidden position-relative'>
      {children}
    </div>
  )
}

/**
 * Spinner border inner layer
 */
function SpinnerBorder (): ReactElement {
  return (
    <div className='spinner-layer h-100 w-100 position-absolute'>
      <SpinnerBorderCircleClipper>
        <SpinnerBorderCircle className='left border-end-transparent start-0' />
      </SpinnerBorderCircleClipper>
      <div className='gap-patch h-100 overflow-hidden position-absolute w-10 top-0'>
        <SpinnerBorderCircle />
      </div>
      <SpinnerBorderCircleClipper>
        <SpinnerBorderCircle className='right border-start-transparent start-n100' />
      </SpinnerBorderCircleClipper>
    </div>
  )
}

/**
 * Spinner component to indicate the loading state of a component or page.
 *
 * Use margin, flexbox, float, text alignment, or other utilities to align the spinner via `className`.
 * You can put spinners on buttons and anywhere else you need them.
 */
const Spinner = forwardRef<HTMLDivElement & HTMLSpanElement, SpinnerProps>(({
  className,
  children = 'Loading...',
  type = 'border',
  tag: Tag = 'div',
  color = 'blue-400',
  size,
  ...otherAttributes
}, ref) => {
  const classNames = clsx(
    size != null && `spinner-${type}-${size}`,
    `spinner-${type}`,
    color != null && color !== 'inherit' && `text-${color}`,
    'border-0',
    className
  )
  const spinnerBorder = type === 'border' && <SpinnerBorder />

  return (
    <Tag
      role='status'
      className={classNames}
      ref={ref}
      {...otherAttributes}
    >
      {spinnerBorder}
      <span className='visually-hidden'>
        {children}
      </span>
    </Tag>
  )
})
Spinner.displayName = 'Spinner'

export {
  Spinner
}
