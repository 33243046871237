import { type ReactElement } from 'react'
import Link from 'next/link'
import { Button, Form, Input } from '@stuller/stullercom/ui'
import { useAuth } from '@stuller/stullercom/feat/auth'

/**
 * Shows the impersonation info
 */
function ImpersonationInfo (): ReactElement | null {
  const { authUser } = useAuth()

  if (authUser == null) {
    return null
  }

  return (
    <>
      <span className='text-danger'>Logged in as <b>{authUser.username}</b> </span>
      <Link className='link-dark' href='/userimpersonation/login'>change</Link>
      {authUser.impersonatedBy != null && (
        <Form className='d-inline' method='post' action='/userimpersonation/login'>
          <span className='text-danger'> or </span>
          <Input type='hidden' name='loginId' value={authUser.impersonatedBy} />
          <Button type='submit' color='anchor-dark'>back to {authUser.impersonatedBy}</Button>
        </Form>)}
    </>
  )
}

export {
  ImpersonationInfo
}
