import { type ReactNode, type ReactElement } from 'react'
import { type ToastOptions } from 'react-toastify'
import { Button } from '../../..'

type ToastifyCloseButton = Extract<ToastOptions['closeButton'], (props: any) => ReactNode>

export type ToastCloseButtonProps = Parameters<ToastifyCloseButton>[0]

/**
 * The close button for a toast.
 */
const ToastCloseButton: ToastifyCloseButton = ({
  closeToast,
  type,
  theme,
  ...otherAttributes
}): ReactElement => {
  return (
    <Button
      close
      onClick={closeToast}
      {...otherAttributes}
    />
  )
}

export {
  ToastCloseButton
}
