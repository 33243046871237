import { type AppContext } from 'next/app'
import { type GetAuth } from '@stuller/stullercom/feat/auth'
import { type CustomAppProps } from './types'
import { isUnauthenticatedPath } from './isUnauthenticatedPath'

const AUTHENTICATED_SHOWCASE_LOGIN_PATH = '/showcaselogin'

/**
 * Redirects to showcaselogin page if jeweler showcase site requiring auth and user is not authenticated
 */
async function authenticatedShowcaseRedirect (appContext: AppContext, auth: GetAuth, showcaseSettings?: CustomAppProps['showcaseSettings']): Promise<boolean> {
  const { ctx, router } = appContext
  const { isJewelerShowcase, isJewelerShowcasePunchout, authUser } = auth
  const isAuthenticatedShowcase = isJewelerShowcase && !isJewelerShowcasePunchout && showcaseSettings?.requiresAuthentication === true

  // If isAuthenticatedShowcase & no auth cookie & path should not be ignored
  if (isAuthenticatedShowcase && authUser == null && !isUnauthenticatedPath(ctx.asPath)) {
    // If router isReady, check current pathname
    if (ctx.res != null) {
      ctx.res.writeHead(302, { Location: AUTHENTICATED_SHOWCASE_LOGIN_PATH })
      ctx.res.end()
    } else {
      await router.push(AUTHENTICATED_SHOWCASE_LOGIN_PATH)
    }

    return true
  }

  return false
}

export {
  authenticatedShowcaseRedirect
}
