import { type ReactElement } from 'react'
import type { SerializedDiamond } from '../types'

interface SerializedDiamondPageHeaderProps {
  /**
   * The serialized diamond to display
   */
  serializedDiamond: SerializedDiamond
}

function SerializedDiamondPageHeader ({ serializedDiamond }: SerializedDiamondPageHeaderProps): ReactElement {
  return (
    <h1>Header placeholder</h1>
  )
}

export {
  SerializedDiamondPageHeader
}
