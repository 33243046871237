import { createContext } from 'react'

export interface FormGroupContextType {
  /**
   * Group id used to tie label/input pairs
   */
  groupId?: string
}

const FormGroupContext = createContext<FormGroupContextType>({})

export {
  FormGroupContext
}
