import type { TargetingMap } from '@stuller/contentstack/util/types'
import { useAuth } from '@stuller/stullercom/feat/auth'
import { useTargetingPreviewer } from './targetingPreviewer'

/**
 * Hook to check if website is in Jeweler Showcase mode or not
 */
function useJewelerShowcaseTargeting (targeting: TargetingMap['JEWELER_SHOWCASE']): boolean {
  const [targetingPreviewer] = useTargetingPreviewer()
  const auth = useAuth()
  const isJewelerShowcase = targetingPreviewer?.isJewelerShowcase != null ? targetingPreviewer.isJewelerShowcase : auth.isJewelerShowcase

  return targeting.targetValue === isJewelerShowcase
}

export { useJewelerShowcaseTargeting }
