import { type CSSProperties, forwardRef, type ReactNode } from 'react'
import { clsx } from 'clsx'

export interface CardProps {
  /**
   * Id of element
   */
  id?: string
  /**
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /**
   * Inline styles to pass to the containing element
   */
  style?: CSSProperties
  /**
   * Children of element
   */
  children?: ReactNode
}

/**
 * Card component for a flexible content container
 *
 * Options for headers and footers, content, contextual background colors, and display options
 */
const Card = forwardRef<HTMLDivElement, CardProps>(({
  className,
  children,
  ...otherAttributes
}, ref) => {
  const classNames = clsx('card', className)

  return (
    <div
      className={classNames}
      ref={ref}
      {...otherAttributes}
    >
      {children}
    </div>
  )
})
Card.displayName = 'Card'

export {
  Card
}
