import clsx from 'clsx'
import { type ReactNode, forwardRef } from 'react'

export interface DropdownTextProps {
  /**
   * Id of element
   */
  id?: string
  /**
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /**
   * Children of element
   */
  children?: ReactNode
}

/**
 * Dropdown list item text item to display in a dropdown menu
 */
const DropdownText = forwardRef<HTMLLIElement, DropdownTextProps>(({
  className,
  children,
  ...otherAttributes
}, ref) => {
  const classNames = clsx('dropdown-item-text', className)

  return (
    <li ref={ref}>
      <span
        className={classNames}
        {...otherAttributes}
      >
        {children}
      </span>
    </li>
  )
})
DropdownText.displayName = 'DropdownText'

export {
  DropdownText
}
