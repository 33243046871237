import {
  type CSSProperties,
  forwardRef,
  type ReactNode,
  useImperativeHandle,
  useMemo,
  useRef,
  useId
} from 'react'
import {
  arrow,
  autoUpdate,
  flip,
  limitShift,
  type Middleware,
  offset,
  type Placement,
  safePolygon,
  shift,
  useDismiss,
  useFloating,
  useHover,
  useInteractions,
  useDelayGroup,
  type OffsetOptions
} from '@floating-ui/react'
import clsx from 'clsx'

export interface PopoverProps {
  /**
   * Id of element
   */
  id?: string
  /**
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /**
   * Indicates to skip the default popover class name
   */
  skipClassName?: boolean
  /**
   * Inline styles to pass to the containing element (except style attributes used by the component)
   * A good use case for this is overriding the default width of the popover like `style={{ maxWidth: 380 }}`
   */
  style?: Omit<CSSProperties, 'position' | 'top' | 'left'>
  /**
   * Children of element
   */
  children?: ReactNode
  /**
   * Defined element type
   */
  tag?: 'div' | 'ul'
  /**
   * Indicates to show the popover
   */
  open?: boolean
  /**
   * Function to call when popover is opened or closed
   */
  onOpenChange?: (open: boolean) => void
  /**
   * The reference element (not a ref) to map the popover to
   */
  reference?: Element | null
  /**
   * Placement of the popover ('center' is an additional option provided outside of the Floating UI library)
   */
  placement?: Placement | 'center'
  /**
   * Indicates to include the popover arrow
   */
  arrow?: boolean
  /**
   * Additional class name(s) to give to the arrow element
   */
  arrowClassName?: string
  /**
   * Inline styles to pass to the arrow (except style attributes used by the arrow)
   */
  arrowStyle?: Omit<CSSProperties, 'top' | 'left'>
  /**
   * The amount of offset padding between the popover and the reference element (doesn't apply to 'center' placement)
   */
  offset?: OffsetOptions
  /**
   * Indicates to flip the popover if it overflows the viewport
   */
  flip?: boolean
  /**
   * Indicates to shift the popover if it overflows the viewport
   */
  shift?: boolean
  /**
   * Indicates to show the popover on hover of the reference element
   */
  hover?: boolean
  /**
   * Override default `useHover` props
   */
  hoverProps?: Omit<NonNullable<Parameters<typeof useHover>[1]>, 'enabled'>
  /**
   * Indicates to dismiss the popover when the user clicks outside of it and the reference element
   */
  dismiss?: boolean
  /**
   * Override default `useDismiss` props
   */
  dismissProps?: Omit<NonNullable<Parameters<typeof useDismiss>[1]>, 'enabled'>
  /**
   * Indicates to use the `useDelayGroup` hook
   * **Note**: this value will not change after the initial render to prevent different number of hooks called between renders
   */
  delayGroup?: boolean
  /**
   * Full custom middleware if needed (the arrow middleware will be included if `arrow` is `true`)
   */
  middleware?: Middleware[]
}

/**
 * Popover to show additional content for an element.
 * This popover uses [Floating UI](https://floating-ui.com) and simplifies the API for interacting with a popover.
 * We aren't using the Bootstrap JS popover because it doesn't work well with React and doesn't allow dynamic content in the popover.
 *
 * For easy popover state management use `useToggle`, like:
 * - `const [open, handleToggle, handleOpenChange] = useToggle()`
 * - Use `onClick={handleToggle}` on the reference element or somewhere else to toggle the popover
 * - Then send the handler to `Popover` like `onOpenChange={handleOpenChange}`
 *
 * Since we pass the `reference` element in, it should be stored in state rather than a ref, to ensure reactive updating, like:
 * - `const [reference, setReference] = useState<ComponentRef<typeof Button> | null>(null)`
 *   - Note the reference element doesn't need to be a `Button`
 *   - You can use `ComponentRef` to get the ref type from a component that uses `forwardRef`
 * - Then on the reference element set `ref={setReference}`
 *
 * While the component supplies plenty of options for customization, you can use the `middleware` prop to override the default middleware completely.
 *
 * We don't have a current need for a popover header component like Bootstrap has.
 * Header's shouldn't be needed as the context for a 'header' should be provided by the reference element.
 */
const Popover = forwardRef<HTMLDivElement | HTMLUListElement, PopoverProps>(({
  className,
  skipClassName = false,
  style,
  children,
  tag: Tag = 'div',
  open = false,
  onOpenChange,
  reference = null,
  placement: placementIn = 'bottom',
  arrow: arrowIn = false,
  arrowClassName,
  arrowStyle,
  offset: offsetIn = 9,
  flip: flipIn = true,
  shift: shiftIn = true,
  hover = false,
  hoverProps,
  dismiss = false,
  dismissProps,
  delayGroup: delayGroupIn = false,
  middleware: middlewareIn,
  ...otherAttributes
}, ref) => {
  const classNames = clsx(
    !skipClassName && 'popover bs-popover-auto',
    className
  )
  const arrowClassNames = clsx('popover-arrow', arrowClassName)
  const arrowRef = useRef<HTMLDivElement>(null)
  const delayGroup = useRef(delayGroupIn)
  const delayGroupItemId = useId()
  const middleware = useMemo<Middleware[]>(() => {
    const middlewareTemp = middlewareIn ?? [
      placementIn === 'center'
        ? offset(({ rects }) => -rects.reference.height / 2 - rects.floating.height / 2)
        : offset(offsetIn),
      flipIn && flip(),
      shiftIn && shift({
        padding: 10,
        limiter: limitShift({
          offset: 10
        })
      })
    ]

    // Add arrow middleware if needed
    if (arrowIn) {
      middlewareTemp.push(arrow({
        element: arrowRef,
        padding: 3
      }))
    }

    return middlewareTemp.filter(m => m !== false) as Middleware[]
  }, [middlewareIn, placementIn, offsetIn, flipIn, shiftIn, arrowIn, arrowRef])
  const {
    x,
    y,
    strategy,
    refs,
    placement,
    context,
    middlewareData: {
      arrow: arrowData
    }
  } = useFloating({
    elements: {
      reference
    },
    placement: placementIn === 'center' ? undefined : placementIn,
    open,
    onOpenChange,
    whileElementsMounted: autoUpdate,
    middleware
  })
  const { getFloatingProps } = useInteractions([
    useHover(context, {
      enabled: hover,
      handleClose: safePolygon(),
      delay: { close: placementIn === 'center' ? 10 : 0 },
      ...hoverProps
    }),
    useDismiss(context, {
      enabled: dismiss,
      ...dismissProps
    })
  ])
  if (delayGroup.current) {
    /* eslint-disable-next-line react-hooks/rules-of-hooks */
    useDelayGroup(context, {
      id: delayGroupItemId
    })
  }
  const placementSide = placement.split('-')[0] ?? ''
  useImperativeHandle(ref, () => refs.floating.current as HTMLDivElement, [refs])

  if (!open) {
    return null
  }

  return (
    <Tag
      data-popper-placement={placementSide}
      {...getFloatingProps({
        className: classNames,
        ref: refs.setFloating,
        role: 'tooltip',
        style: {
          position: strategy,
          top: y ?? 0,
          left: x ?? 0,
          ...style
        },
        ...otherAttributes
      })}
    >
      {children}
      {arrowIn && (
        <div
          ref={arrowRef}
          className={arrowClassNames}
          style={{
            left: arrowData?.x ?? '',
            top: arrowData?.y ?? '',
            ...arrowStyle
          }}
        />)}
    </Tag>
  )
})
Popover.displayName = 'Popover'

export {
  Popover
}
