import { type ReactElement } from 'react'
import { CmsContent } from '@stuller/stullercom/feat/cms-content'
import { type MainLink } from './links'

interface MainLinksContentProps extends MainLink {
}

/**
 * Shows the navigation content and the carousel content in the main nav popover
 */
function MainLinksContent ({
  contentContainerId,
  contentContainerName
}: MainLinksContentProps): ReactElement {
  const showContentContainer = contentContainerId != null || contentContainerName != null

  return (
    <>
      {showContentContainer && (
        <div className='col p-6 overflow-hidden'>
          <CmsContent
            contentContainerId={contentContainerId}
            contentContainerName={contentContainerName}
            loadingProps={{ color: 'white' }}
          />
        </div>)}
    </>
  )
}

export {
  MainLinksContent
}
