import { urlParts } from '@stuller/shared/util/core'
import { type CustomNextPageContext, type StatusCodeProps } from '@stuller/stullercom/feat/layout-context'

/**
 * Handle authorization scenarios on pages using initial props
 */
function getUnauthorizedInitial (
  context: CustomNextPageContext
): StatusCodeProps {
  const { res, req, auth } = context
  const { isJewelerShowcase, authUser, showcaseSettings } = auth
  let url

  if (res != null && req != null) {
    const parts = urlParts({ req })
    url = parts.url
  } else {
    url = window.location.href
  }

  const siteSupportsLogin = !isJewelerShowcase || showcaseSettings?.requiresAuthentication === true
  // If the user is not logged in and the site supports login, 302 to login
  if (authUser == null && siteSupportsLogin) {
    return { statusCode: 302, location: `/login?ReturnUrl=${encodeURIComponent(url)}` }
  } else {
    // If the user is not logged in but the site doesn’t support logging in
    // If the user is logged in but unauthorized
    return { statusCode: 403 }
  }
}

export {
  getUnauthorizedInitial
}
