import { type ComponentRef, type ReactElement, useMemo, useState } from 'react'
import { type IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { far, fas } from '@awesome.me/kit-3dbd93c064/icons'
import { useToggle } from '@stuller/shared/util/react-hooks'
import { useAuth } from '@stuller/stullercom/feat/auth'
import { MenuCartContext, type MenuCartContextType } from './MenuCartContext'
import { MenuItem } from '../MenuItem'
import { CartPopover } from './CartPopover'
import { CartBadge } from './CartBadge'

/**
 * Menu item and dropdown for cart
 */
function MenuCart (): ReactElement {
  const [open, handleToggle, handleOpenChange] = useToggle()
  const [reference, setReference] = useState<ComponentRef<typeof MenuItem> | null>(null)
  const { isJewelerShowcase, authUser, hasRole } = useAuth()
  const hasScheduledOrder = authUser?.user?.scheduledOrder?.id != null
  const [icon, title] = useMemo(() => {
    let [icon, title]: [IconDefinition, MenuCartContextType['title']] = [fas.faCartShopping, 'Cart']

    if (isJewelerShowcase && !hasRole('Order Requires Approval') && !hasRole('Order Requires Approval Bypass Checkout')) {
      icon = fas.faBagShopping
      title = 'Quote'
    } else if (authUser?.user?.scheduledOrder?.id != null) {
      icon = far.faClock
    }

    return [icon, title]
  }, [isJewelerShowcase, authUser, hasRole])

  return (
    <MenuCartContext.Provider
      value={{
        title,
        icon,
        hasScheduledOrder
      }}
    >
      <MenuItem
        icon={icon}
        text={title}
        open={open}
        onClick={handleToggle}
        setReference={setReference}
      >
        <CartBadge />
      </MenuItem>
      <CartPopover
        open={open}
        onOpenChange={handleOpenChange}
        reference={reference}
      />
    </MenuCartContext.Provider>
  )
}

export {
  MenuCart
}
