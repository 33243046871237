import { forwardRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'
import { Input, type InputProps, InputGroup, InputGroupText } from '../../../..'

interface DatePickerInputProps extends InputProps {}

/**
 * The custom input component that is used with the DatePicker component
 */
const DatePickerInput = forwardRef<HTMLInputElement, DatePickerInputProps>(({
  type,
  pill,
  size,
  invalid,
  valid,
  onClick,
  ...otherAttributes
}, ref) => {
  return (
    <InputGroup
      pill={pill}
      size={size}
      invalid={invalid}
      valid={valid}
    >
      <Input
        type='text'
        invalid={invalid}
        valid={valid}
        onClick={onClick}
        ref={ref}
        {...otherAttributes}
      />
      <InputGroupText className='cursor-pointer' onClick={onClick}>
        <FontAwesomeIcon icon={fas.faCalendarAlt} size='lg' />
      </InputGroupText>
    </InputGroup>
  )
})
DatePickerInput.displayName = 'DatePickerInput'

export {
  DatePickerInput
}
