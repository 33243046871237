import { useAuth } from '@stuller/stullercom/feat/auth'
import type { TargetingMap } from '@stuller/contentstack/util/types'
import { useTargetingPreviewer } from './targetingPreviewer'

/**
 * Hook to check any, all, and none logic for a country targeting
 */
function useCountryTargeting (targeting: TargetingMap['COUNTRY']): boolean {
  const [targetingPreviewer] = useTargetingPreviewer()
  const { authUser } = useAuth()
  const country = targetingPreviewer?.country != null ? targetingPreviewer.country : authUser?.user?.defaultActiveShipToAccount?.address?.country ?? 'US'

  return targeting?.targetValue.includes(country) ?? false
}

export { useCountryTargeting }
