import { forwardRef, type ReactNode } from 'react'
import clsx from 'clsx'

export interface AlertIconProps {
  /**
   * Id of element
   */
  id?: string
  /**
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /**
   * Children of the loading indicator
   */
  children?: ReactNode
  /**
   * Size of icon non-default
   */
  size?: 'lg'
}

/**
 * Alert icon component
 */
const AlertIcon = forwardRef<HTMLDivElement, AlertIconProps>(({
  className,
  children,
  size,
  ...otherAttributes
}, ref) => {
  const classNames = clsx(
    'alert-icon',
    size != null && `alert-icon-${size}`,
    className
  )

  return (
    <div
      className={classNames}
      ref={ref}
      {...otherAttributes}
    >
      {children}
    </div>
  )
})
AlertIcon.displayName = 'AlertIcon'

export {
  AlertIcon
}
