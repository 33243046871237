import { type ApolloError } from '@apollo/client/errors'

export function getClientValidationErrors (...apolloErrors: Array<ApolloError | null | undefined>): string[] {
  const validationErrors = []
  for (const err of apolloErrors) {
    if (err == null) {
      continue
    }
    for (const graphQLError of err.graphQLErrors) {
      if (graphQLError.extensions?.code != null && graphQLError.extensions.code === 'BAD_USER_INPUT') {
        validationErrors.push(graphQLError.message)
      }
    }
  }

  return validationErrors
}
