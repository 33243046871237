import { type ReactNode } from 'react'
import Link from 'next/link'
import { type BadgeProps } from '../../shared/badge/Badge'

export type ProductBadgeConfiguredProps = 'children' | 'color' | 'border' | 'text'

export interface ProductBadgeDetails extends Pick<BadgeProps, ProductBadgeConfiguredProps> {
  /**
   * Popover content to display when hovering over the badge
   */
  popover?: ReactNode
}

export type ProductBadgeType =
  'best-seller' |
  'deal' |
  'die-struck' |
  'ever-and-ever' |
  'hollow' |
  'digital-imprinting' |
  'lab-grown' |
  'machined' |
  'metal-mold' |
  'new' |
  'new-deal' |
  '302' |
  '3c'

const productBadges: Record<ProductBadgeType, ProductBadgeDetails> = {
  'best-seller': {
    children: <>Bestseller</>,
    color: 'gray-700',
    popover: (
      <>
        <b>Bestseller: </b>
        Best selling item based on the total number of purchases from all buyers.
      </>
    )
  },
  deal: {
    children: <>Deal</>,
    color: 'red-400',
    popover: (
      <>
        <b>Deal: </b>
        Products with reduced pricing.
      </>
    )
  },
  'die-struck': {
    children: <>Die-Struck</>,
    color: 'orange-300',
    popover: (
      <>
        <b>Die-Struck: </b>
        Pressure and molds are used to form metal into jewelry. This results in pieces that are dense, durable, and more resistant to corrosion.
      </>
    )
  },
  'ever-and-ever': {
    children: <>ever&ever<sup>®</sup></>,
    color: 'purple-500',
    popover: (
      <>
        <b>ever&ever<sup>®</sup>: </b>
        Stuller's complete bridal program featuring high-quality customizable engagement rings, marketing support, and in-store prototype selling kits. <Link href='/everandever' className='primary-link'>Learn More</Link>
      </>
    )
  },
  hollow: {
    children: <>Hollow</>,
    color: 'gray-500',
    popover: (
      <>
        <b>Hollow: </b>
        Chain links have a hollow core, giving the chain a lighter weight.
      </>
    )
  },
  'digital-imprinting': {
    children: <>Imprinting Available</>,
    color: 'white',
    text: 'gray-700',
    border: 'gray-300'
  },
  'lab-grown': {
    children: <>Lab-Grown</>,
    color: 'lab-grown-blue-300',
    popover: (
      <>
        <b>Lab-grown: </b>
        Lab-Grown diamonds.
      </>
    )
  },
  machined: {
    children: <>Machined</>,
    color: 'gray-800',
    popover: (
      <>
        <b>Machined: </b>
        Industrial CNC machines use extreme precision to shape metal into the desired design.
      </>
    )
  },
  'metal-mold': {
    children: <>Metal Mold</>,
    color: 'orange-500',
    popover: (
      <>
        <b>Metal Mold: </b>
        Molten metal is cast into jewelry. This process allows for more intricate jewelry designs.
      </>
    )
  },
  new: {
    children: <>New</>,
    color: 'new-green-300',
    popover: (
      <>
        <b>New: </b>
        Newly released products.
      </>
    )
  },
  'new-deal': {
    children: <>New Deal</>,
    color: 'red-100',
    text: 'red-400',
    popover: (
      <>
        <b>New Deal: </b>
        Products that have been recently added to deals.
      </>
    )
  },
  302: {
    children: <>302<sup>®</sup> Fine Jewelry</>,
    color: 'indigo-500',
    popover: (
      <>
        <b>302<sup>®</sup> Fine Jewelry: </b>
        Curated collections of trending styles with new product releases every year, ready-to-post social media content, and marketing support. <Link href='/302finejewelry' className='primary-link'>Learn More</Link>
      </>
    )
  },
  '3c': {
    children: <>3C Design</>,
    color: 'blue-300',
    popover: (
      <>
        <b>3C Design: </b>
        Choose, Change, Create® Designs can be customized using Stuller's 3C™ Digital Design experience.
      </>
    )
  }
}

export {
  productBadges
}
