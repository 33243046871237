import { type ComponentPropsWithoutRef, type ReactNode, forwardRef, type ElementType } from 'react'
import clsx from 'clsx'
import Link from 'next/link'

type ButtonTagProps = ComponentPropsWithoutRef<'button'> & ComponentPropsWithoutRef<'a'>

export interface DropdownItemProps extends ButtonTagProps {
  /**
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /**
   * Children of element
   */
  children?: ReactNode
  /**
   * Defined element type
   */
  tag?: 'a' | 'button'
  /**
   * Disable element
   */
  disabled?: boolean
  /**
   * Href for if the button is a link
   */
  href?: string
  /**
   * If button is active
   */
  active?: boolean
}

/**
 * Dropdown list item button/link item to display in a dropdown menu
 */
const DropdownItem = forwardRef<HTMLLIElement, DropdownItemProps>(({
  className,
  children,
  tag = 'button' as ElementType,
  disabled = false,
  href: hrefIn,
  active = false,
  tabIndex: tabIndexIn,
  'aria-label': ariaLabel,
  ...otherAttributes
}, ref) => {
  const classNames = clsx(
    'dropdown-item',
    active && 'active',
    disabled && 'disabled',
    className
  )
  const Tag = tag === 'a' ? Link : tag
  const typeOrRole = { [Tag === 'button' ? 'type' : 'role']: 'button' }
  const href = tag === 'a' ? hrefIn ?? '' : undefined
  const tabIndex = tabIndexIn ?? (tag === 'a' && disabled ? -1 : undefined)

  return (
    <li ref={ref}>
      <Tag
        className={classNames}
        {...typeOrRole}
        disabled={disabled}
        href={href}
        aria-label={ariaLabel}
        aria-disabled={disabled}
        tabIndex={tabIndex}
        {...otherAttributes}
      >
        {children}
      </Tag>
    </li>
  )
})
DropdownItem.displayName = 'DropdownItem'

export {
  DropdownItem
}
