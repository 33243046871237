import { type ReactElement, type ReactNode, useMemo } from 'react'
import { AuthContext } from './AuthContext'
import { type Auth } from './types'
import { getAuth } from './getAuth'

export interface AuthProviderProps {
  /**
   * Children under the provider
   */
  children?: ReactNode
  /**
   * The auth user to set in context
   */
  authUser: Auth['authUser']
  /**
   * The host to set in context
   */
  host: Auth['host']
  /**
   * The session id to set in context
   */
  sessionId: string
  /**
   * Showcase settings
   */
  showcaseSettings: Auth['showcaseSettings']
}

/**
 * Get the auth provider to be set as the provider value and returned in the `useAuth` hook
 */
function getAuthProvider (authUser: Auth['authUser'], host: Auth['host'], sessionId: string, showcaseSettings: Auth['showcaseSettings']): Auth {
  return {
    ...getAuth(authUser, host, sessionId),
    showcaseSettings,
    getCustomShowcaseSetting: (type) => showcaseSettings?.customShowcaseSettings?.details.find(x => x.type === type)?.values ?? null
  }
}

/**
 * Provides Auth info to context
 */
function AuthProvider ({ authUser, host, children, sessionId, showcaseSettings }: AuthProviderProps): ReactElement {
  const auth = useMemo<Auth>(() => getAuthProvider(authUser, host, sessionId, showcaseSettings), [authUser, host, sessionId, showcaseSettings])

  return (
    <AuthContext.Provider value={auth}>
      {children}
    </AuthContext.Provider>
  )
}

export {
  getAuthProvider,
  AuthProvider
}
