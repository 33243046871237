import { type RefObject, useEffect, useRef } from 'react'

type ResizeObserverInit = ResizeObserverOptions & {
  /**
   * Watch subchildren for resize also
   * This is useful for watching subchildren that are loading images or dynamic content
   */
  subchildren?: boolean
}

/**
 * Set a resize observer on the ref given
 */
function useResizeObserver (
  handler: ResizeObserverCallback,
  element?: RefObject<Element>,
  options: ResizeObserverInit = { subchildren: true }
): void {
  // Create a ref that stores handler
  const savedHandler = useRef<ResizeObserverCallback>()

  // Update savedHandler ref on changes to use latest without needing to pass to `useEffect` deps
  useEffect(() => {
    savedHandler.current = handler
  }, [handler])

  useEffect(() => {
    // Define the listening target
    const targetElement = element != null ? element.current : document.body
    if (targetElement == null) {
      return
    }

    // Create callback that calls handler function stored in ref
    const callback: ResizeObserverCallback = (...args) => {
      if (savedHandler?.current != null) {
        savedHandler.current(...args)
      }
    }

    const observer = new ResizeObserver(callback)
    observer.observe(targetElement, options)

    // Force resize to watch subchildren
    if (options.subchildren === true) {
      for (const child of targetElement.children) {
        observer.observe(child)
      }
    }

    // Remove callback on cleanup
    return () => {
      observer.disconnect()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [element, options.subchildren])
}

export {
  useResizeObserver
}
