import { dispatchStullerEvent } from './dispatchStullerEvent'

/**
 * Attaches JavaScript helper to window to dispatch Stuller events (`window.dispatchStullerEvent`)
 */
function useStullerEvents (): void {
  if (typeof window !== 'undefined' && (window as any).dispatchStullerEvent == null) {
    (window as any).dispatchStullerEvent = dispatchStullerEvent
  }
}

export {
  useStullerEvents
}
