import { createContext } from 'react'
import { type IconDefinition } from '@fortawesome/fontawesome-svg-core'

export interface MenuCartContextType {
  /**
   * The text to display for the cart
   */
  title: 'Cart' | 'Quote'
  /**
   * The icon to use for the cart
   */
  icon: IconDefinition
  /**
   * Indicates if the user has a scheduled order
   */
  hasScheduledOrder: boolean
}

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
const MenuCartContext = createContext<MenuCartContextType>({} as MenuCartContextType)

export {
  MenuCartContext
}
