import { makeVar, useReactiveVar } from '@apollo/client'

export type TargetingPreviewer = {
  isAuthenticated?: boolean
  is302Member?: boolean
  billToAccount?: string
  excludedCategory?: string
  country?: string
  isEverandEverMember?: boolean
  isJewelerShowcase?: boolean
  queryStringValues?: string
  shipToAccount?: string
  isStullerComShowcaseMode?: boolean
  subdomain?: string
  isShowcase?: boolean
  isSterling?: boolean
  isStullerFirstEligible?: boolean
  isStullerFirstEnrolled?: boolean
  isEcommerceMerchandisingMode?: boolean
  priceList?: string
} | null

const targetingPreviewerVar = makeVar<TargetingPreviewer>(null)

/**
 * Hook to get and set the targeting previewer state
 */
function useTargetingPreviewer (): [TargetingPreviewer, typeof targetingPreviewerVar] {
  const targetingPreviewer = useReactiveVar(targetingPreviewerVar)

  return [targetingPreviewer, targetingPreviewerVar]
}

export {
  useTargetingPreviewer
}
