import { type CSSProperties, type ReactNode, forwardRef } from 'react'
import { clsx } from 'clsx'

interface CardBodyType {
  /**
   * Id of element
   */
  id?: string
  /**
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /**
   * Inline styles to pass to the containing element
   */
  style?: CSSProperties
  /**
   * Children of element
   */
  children?: ReactNode
}

/**
 * React implementation/wrapper of bootstrap's 'card-body' component to wrap Card
 * subcomponents like CardTitle, CardSubtitle, CardImage, etc (h# should always be bigger than CardTitle)
 */
const CardBody = forwardRef<HTMLDivElement, CardBodyType>(({
  className,
  children,
  ...otherAttributes
}, ref) => {
  const classNames = clsx('card-body', className)

  return (
    <div
      className={classNames}
      ref={ref}
      {...otherAttributes}
    >
      {children}
    </div>
  )
})
CardBody.displayName = 'CardBody'

export {
  CardBody
}
