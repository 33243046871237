import { memo, type ReactElement } from 'react'
import { CmsContent } from '@stuller/stullercom/feat/cms-content'
import { useAuth } from '@stuller/stullercom/feat/auth'

/**
 * Ankle nav from CMS (frequently visited categories)
 */
const AnkleNav = memo((): ReactElement | null => {
  const { isShowcase, isSterling } = useAuth()

  if (isShowcase || isSterling) {
    return null
  }

  return (
    <div className='container-xxxl mw-xxl px-4 d-none d-md-block d-print-none'>
      <CmsContent
        contentContainerName='ankle-nav'
        skipLoading
        noRefetch
        data-gtm-click-section='ankle-nav'
      />
    </div>
  )
})
AnkleNav.displayName = 'AnkleNav'

export {
  AnkleNav
}
