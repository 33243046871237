import { type ReactElement } from 'react'
import { type ProgressCircleProps } from './ProgressCircle'
import clsx from 'clsx'

interface ProgressCirclePercentProps extends ProgressCircleProps {
}

/**
 * Standard display for progress circle percent.
 */
function ProgressCirclePercent ({
  className,
  value,
  ...otherAttributes
}: ProgressCirclePercentProps): ReactElement {
  const classNames = clsx('progress-circle-percent', className)

  return (
    <div
      className={classNames}
      {...otherAttributes}
    >
      {value}%
    </div>
  )
}

export {
  ProgressCirclePercent
}
