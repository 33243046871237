import { type ReactElement, useRef } from 'react'
import { Modal, ModalBody, ModalHeader, Zone } from '@stuller/stullercom/ui'
import { CmsContent } from '@stuller/stullercom/feat/cms-content'

interface ConsultantModeHelpProps {
  /**
   * Modal open state
   */
  isOpen: boolean
  /**
   * Toggle modal open state
   */
  onToggle: () => void
}

/**
 * Consultant mode help modal
 */
function ConsultantModeHelpModal ({ isOpen, onToggle }: ConsultantModeHelpProps): ReactElement {
  const portalRef = useRef(typeof window !== 'undefined' ? document.body : undefined)

  return (
    <Modal isOpen={isOpen} onToggle={onToggle} size='lg' portalRef={portalRef}>
      <ModalHeader onToggle={onToggle}>
        Help
      </ModalHeader>
      <ModalBody>
        <h3>Contact Extensions</h3>
        <CmsContent contentContainerName='dot-help-content' />
        <h3 className='mt-5'>Content Only Visible to DOT</h3>
        <Zone color='consultant-mode' className='p-2'>
          <b>Hidden To Customers</b>: Only Consultants will see this.
        </Zone>
      </ModalBody>
    </Modal>
  )
}

export {
  ConsultantModeHelpModal
}
