import { type ReactElement, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import Cookies from 'js-cookie'
import { DropdownItem } from '@stuller/stullercom/ui'

const DEV_UI_ENABLED_COOKIE_NAME = 'dev_ui_enabled'

/**
 * Shows dev related tools
 */
function DevTools (): ReactElement {
  const router = useRouter()
  const [devUIEnabled, setDevUIEnabled] = useState(false)

  /**
   * Handle Dev UI mode toggle
   */
  function handleToggleDevUIMode (): void {
    Cookies.set(DEV_UI_ENABLED_COOKIE_NAME, (!devUIEnabled).toString(), { secure: true })
    router.reload()
  }

  /**
   * On component load, read cookie and set defaults
   */
  useEffect(() => {
    setDevUIEnabled(Cookies.get(DEV_UI_ENABLED_COOKIE_NAME)?.toLowerCase() === 'true')
  }, [])

  return (
    <DropdownItem onClick={handleToggleDevUIMode}>
      {devUIEnabled ? 'Disable' : 'Enable'} Dev UI Mode
    </DropdownItem>
  )
}

export {
  DevTools
}
