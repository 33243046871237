import { type ReactElement } from 'react'
import { components, type InputProps, type GroupBase } from 'react-select'

/**
 * Custom select container so we can JSX things like `data-test` attributes
 */
function Input<
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>
> ({ children, ...props }: InputProps<Option, IsMulti, Group>): ReactElement {
  return (
    <components.Input {...props} readOnly={(props.selectProps as any).readOnly}>
      {children}
    </components.Input>
  )
}

export {
  Input
}
