import { memo, type ReactElement } from 'react'
import Script from 'next/script'
import { useAuth } from '@stuller/stullercom/feat/auth'

/**
 * Shows the McAfee trustmark
 */
const McAfeeTrustmark = memo((): ReactElement | null => {
  const { authUser, isShowcase, isSterling } = useAuth()

  if (authUser != null || isShowcase || isSterling) {
    return null
  }

  return (
    <>
      <Script type='text/javascript' src='https://cdn.ywxi.net/js/1.js' async />

      <style jsx global>{`
        @media print {
          #trustedsite-tm-image {
            display: none;
          }
        }
      `}
      </style>
    </>
  )
})
McAfeeTrustmark.displayName = 'McAfeeTrustmark'

export {
  McAfeeTrustmark
}
