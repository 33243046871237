import clsx from 'clsx'
import { forwardRef } from 'react'
import { PhoneInput, type PhoneInputRefType, type CountryIso2, type PhoneInputProps, type ParsedCountry } from 'react-international-phone'
import 'react-international-phone/style.css'

interface PhoneNumberInputProps extends PhoneInputProps {
  /**
   * Id of the element
   */
  id?: string
  /**
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /**
   * Input name field
   */
  name?: string
  /**
   * Input value
   */
  value?: string
  /**
   * Default country code
   */
  defaultCountry?: CountryIso2
  /**
   * Preferred countries will appear at the top of the country selector dropdown
   */
  preferredCountries?: CountryIso2[]
  /**
   * Invalid styling
   */
  invalid?: boolean | string
  /**
   * Valid styling
   */
  valid?: boolean | string
}

/**
 * PhoneNumberInput component for rendering a phone number input field.
 */
const PhoneNumberInput = forwardRef<PhoneInputRefType, PhoneNumberInputProps>(({
  id,
  className,
  name,
  value,
  placeholder,
  onChange,
  defaultCountry = 'us',
  preferredCountries = ['us', 'ca'],
  invalid = false,
  valid = false,
  disabled = false,
  required = false,
  ...otherAttributes
}, ref) => {
  const classNames = clsx(
    'form-phone-number',
    disabled && 'is-disabled',
    invalid !== false && invalid !== '' && 'is-invalid',
    valid === true && 'is-valid',
    className
  )

  const handlePhoneNumberChange = (phone: string, meta: { country: ParsedCountry, inputValue: string }): void => {
    onChange?.(phone, meta)
  }

  return (
    <div id={id} ref={ref} className={classNames} {...otherAttributes}>
      <PhoneInput
        name={name}
        inputClassName='form-control'
        defaultCountry={defaultCountry}
        preferredCountries={preferredCountries}
        value={value}
        onChange={handlePhoneNumberChange}
        required={required}
        disabled={disabled}
        inputProps={{
          placeholder
        }}
      />
    </div>
  )
})
PhoneNumberInput.displayName = 'PhoneNumberInput'

export { PhoneNumberInput }
