import { CmsContent } from '@stuller/stullercom/feat/cms-content'
import { type ReactElement } from 'react'

/**
 * Sterling footer nav
 */
function FooterSterling (): ReactElement {
  return (
    <footer className='border-top pb-6 py-md-7 w-100 d-print-none'>
      <h6 id='copyright' className='fw-bold text-center'>Copyright &copy;{new Date().getFullYear()}  Stuller. All rights reserved</h6>
      <div className='d-md-none text-center'>
        <h3 className='fw-bold'>Contact Us</h3>
        <CmsContent contentContainerName='signet-contact-info' />
      </div>
    </footer>
  )
}

export {
  FooterSterling
}
