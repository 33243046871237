import { type ReactElement, memo } from 'react'
import { useAuth } from '@stuller/stullercom/feat/auth'
import { ConsultantModeInfo } from './ConsultantModeInfo'
import { ConsultantModeQuickBar } from './ConsultantModeQuickBar'

/**
 * Current consultant nav
 */
const ConsultantModeNav = memo((): ReactElement | null => {
  const { hasRole } = useAuth()

  if (!hasRole('Consultant Mode')) {
    return null
  }

  return (
    <div className='position-sticky z-sticky top-0 shadow d-print-none'>
      <ConsultantModeInfo />
      <ConsultantModeQuickBar />
    </div>
  )
})
ConsultantModeNav.displayName = 'ConsultantModeNav'

export {
  ConsultantModeNav
}
