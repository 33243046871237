import { datadogRum } from '@datadog/browser-rum'
import { useEffect } from 'react'
import { type AuthProviderProps } from '@stuller/stullercom/feat/auth'
import { siteConfig } from '@stuller/stullercom/util/site-config'

const env = siteConfig.NEXT_PUBLIC_DATADOG_ENVIRONMENT ?? ''
const sessionSampleRate = siteConfig.NEXT_PUBLIC_DATADOG_RUM_SESSION_SAMPLE_RATE
const enabled = ['dev', 'prod'].includes(env) && sessionSampleRate > 0

/**
 * Initalizes Datadog RUM client-side
 */
function initDatadogRum (): void {
  if (enabled) {
    datadogRum.init({
      applicationId: 'efd86d57-c4fb-4b9e-a302-3ed1a13e6ba0',
      clientToken: 'pub03251035c9cb20aeebe6aaae86381389',
      site: 'datadoghq.com',
      service: 'stullercom-site',
      env,
      version: '1.0.0',
      sessionSampleRate,
      sessionReplaySampleRate: 0,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input'
    })
  }
}

// Call init on file include to make sure it's called ASAP
initDatadogRum()

/**
 * Adds Datadog RUM to the page with user information
 *
 * To enable:
 * Set `NEXT_PUBLIC_DATADOG_ENVIRONMENT` to `dev` or `prod`.
 * Set `NEXT_PUBLIC_DATADOG_RUM_SESSION_SAMPLE_RATE` to a number greater than 0.
 */
function useDatadogRum (authUser: AuthProviderProps['authUser']): void {
  useEffect(() => {
    if (enabled) {
      datadogRum.setUser({
        id: authUser?.user?.memberId ?? undefined
      })
    }
  }, [authUser])
}

export {
  useDatadogRum
}
