import { type CSSProperties, forwardRef, type ReactNode, useMemo } from 'react'
import clsx from 'clsx'
import { AccordionContext, type AccordionContextType } from '../../..'

export interface AccordionProps extends AccordionContextType {
  /**
   * Id of element
   */
  id?: string
  /**
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /**
   * Inline styles to pass to the containing element
   */
  style?: CSSProperties
  /**
   * Children of element
   */
  children?: ReactNode
  /**
   * Renders the accordion flush (edge-to-edge), without default background, borders, etc.
   */
  flush?: boolean
  /**
   * Renders the accordion item buttons as chevrons or plus/minus icons
   */
  buttonType?: 'chevron' | 'plus-minus'
  /**
   * Indicates to include schema.org FAQPage microdata
   */
  faqSchema?: boolean
}

/**
 * Build vertically collapsing accordions that use the `Collapse` component.
 *
 * For easy accordion state management use `useToggleKey`.
 * Use an array `defaultOpen` for always open.
 */
const Accordion = forwardRef<HTMLDivElement, AccordionProps>(({
  className,
  children,
  open,
  onToggle,
  flush = false,
  buttonType = 'chevron',
  faqSchema = false,
  ...otherAttributes
}, ref) => {
  const context = useMemo(() => ({ open, onToggle, faqSchema }), [open, onToggle, faqSchema])
  const faqSchemaMicrodata = faqSchema && { itemScope: true, itemType: 'https://schema.org/FAQPage' }
  const classNames = clsx(
    'accordion',
    flush && 'accordion-flush',
    className,
    buttonType === 'plus-minus' && 'plus-minus-button'
  )

  return (
    <AccordionContext.Provider value={context}>
      <div
        className={classNames}
        {...faqSchemaMicrodata}
        ref={ref}
        {...otherAttributes}
      >
        {children}
      </div>
    </AccordionContext.Provider>
  )
})
Accordion.displayName = 'Accordion'

export {
  Accordion
}
