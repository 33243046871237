import type { TargetingMap } from '@stuller/contentstack/util/types'
import { useAuth } from '@stuller/stullercom/feat/auth'
import { useTargetingPreviewer } from './targetingPreviewer'

/**
 * Hook to check Sterling context
 */
function useSterlingTargeting (targeting: TargetingMap['STERLING']): boolean {
  const auth = useAuth()
  const [targetingPreviewer] = useTargetingPreviewer()
  const { isSterling } = auth
  const isSterlingTarget = targetingPreviewer?.isSterling != null ? targetingPreviewer.isSterling : isSterling ?? false

  return targeting.targetValue ? isSterlingTarget : !isSterlingTarget
}

export { useSterlingTargeting }
