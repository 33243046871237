import { memo } from 'react'
import {
  ToastContainer as ToastifyContainer,
  type ToastContainerProps as ToastifyContainerProps,
  Slide
} from 'react-toastify'
import { ToastCloseButton } from './ToastCloseButton'

interface ToastContainerProps extends ToastifyContainerProps {}

/**
 * Toast container with defaults to show toasts from any page.
 *
 * See API: https://fkhadra.github.io/react-toastify/api/toast-container
 */
const ToastContainer = memo(({
  ...otherAttributes
}: ToastContainerProps) => {
  return (
    <div className='d-print-none position-sticky z-toast top-0'>
      <ToastifyContainer
        autoClose={5000}
        closeButton={ToastCloseButton}
        closeOnClick={false}
        draggable={false}
        hideProgressBar={false}
        icon={false}
        limit={3}
        newestOnTop={false}
        pauseOnFocusLoss
        pauseOnHover
        position='top-right'
        rtl={false}
        theme='light'
        transition={Slide}
        {...otherAttributes}
      />
    </div>
  )
})
ToastContainer.displayName = 'ToastContainer'

export {
  ToastContainer
}
