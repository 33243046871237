import dayjs from 'dayjs'
import Cookies from 'js-cookie'

const cookieName = 'split_test_version'

interface SplitTestVersion {
  key: string
  expirationDate: Date
}

/**
 * Parses a split test value string into it's entries
 */
function getValuesFromSplitTestVersionKey (splitTestVersionKey: string): SplitTestVersion[] {
  const keys = splitTestVersionKey.split('&')

  const data: SplitTestVersion[] = keys.map(x => {
    const parts = x.split('|')
    const key = parts[0]
    const expirationDate = new Date(parts[1]) // formatted YYYY-MM-DD

    return { key, expirationDate }
  })

  return data
}

/**
 * Serializes split test values into a string
 */
function serializeSplitTestVersions (versions: SplitTestVersion[]): string {
  const data: Record<string, string> = {}
  for (const splitTest of versions) {
    data[splitTest.key] = dayjs(splitTest.expirationDate).toISODateString()
  }

  const values = Object.entries(data).map(x => `${x[0]}|${x[1]}`)

  return values.join('&')
}

/**
 * Parses the split test version key and updates the browsers cookie with any newly added values that are no longer expired.
 * Removes expired values from the cookie.
 */
function updateSplitTestCookie (splitTestVersionsKey: string): void {
  if (splitTestVersionsKey.length === 0) {
    return
  }
  const expires = new Date()
  expires.setDate(expires.getDate() + 30)
  const cookieAttributes: Cookies.CookieAttributes = {
    expires,
    secure: true,
    sameSite: 'None'
  }

  const valueOfCookie = Cookies.get(cookieName)
  if (valueOfCookie == null) {
    Cookies.set(cookieName, splitTestVersionsKey, cookieAttributes)

    return
  }

  const splitTestData = getValuesFromSplitTestVersionKey(valueOfCookie)
    .concat(getValuesFromSplitTestVersionKey(splitTestVersionsKey))
    .filter(x => x.expirationDate > new Date(dayjs().toISODateString()))

  const serializedKey = serializeSplitTestVersions(splitTestData)
  Cookies.set(cookieName, serializedKey, cookieAttributes)
}

export {
  updateSplitTestCookie
}
