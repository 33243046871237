import { type ReactElement } from 'react'
import { components, type ContainerProps, type GroupBase } from 'react-select'

/**
 * Custom select container so we can JSX things like `data-test` attributes
 */
function SelectContainer<
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>
> ({ children, ...props }: ContainerProps<Option, IsMulti, Group>): ReactElement {
  const innerProps: any = {
    ...props.innerProps,
    'data-test': (props.selectProps as any)['data-test']
  }

  return (
    <components.SelectContainer {...props} innerProps={innerProps}>
      {children}
    </components.SelectContainer>
  )
}

export {
  SelectContainer
}
