import { type ElementType, forwardRef, type ReactNode } from 'react'
import { clsx } from 'clsx'
import Link from 'next/link'

export interface NavLinkProps {
  /**
   * Id of element
   */
  id?: string
  /**
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /**
   * Children of element
   */
  children?: ReactNode
  /**
   * Indicates the nav link is active
   */
  active?: boolean
  /**
   * Disable click and show as disabled
   */
  disabled?: boolean
  /**
   * The url (href) for the nav link
   */
  href?: string
  /**
   * Handle on fire if not disabled
   */
  onClick?: () => void
  /**
   * Defined element type
   */
  tag?: 'a' | 'button'
}

/**
 * NavLink component to hold navigation item links
 */
const NavLink = forwardRef<HTMLButtonElement, NavLinkProps>(({
  className,
  children,
  active = false,
  disabled = false,
  href: hrefIn,
  onClick,
  tag = 'a' as ElementType,
  ...otherAttributes
}, ref) => {
  const classNames = clsx(
    'nav-link',
    active && 'active',
    disabled && 'disabled',
    className
  )
  const Tag = tag === 'a' ? Link : tag
  const href = tag === 'a' ? hrefIn ?? '' : undefined

  return (
    <Tag
      className={classNames}
      ref={ref}
      href={href}
      disabled={disabled}
      aria-disabled={disabled}
      onClick={onClick}
      {...otherAttributes}
    >
      {children}
    </Tag>
  )
})
NavLink.displayName = 'NavLink'

export {
  NavLink
}
