import { flip, limitShift, offset, shift } from '@floating-ui/react'
import clsx from 'clsx'
import { forwardRef, useMemo } from 'react'
import ReactDatePicker, {
  type DatePickerProps as ReactDatePickerProps
} from 'react-datepicker'
import { DatePickerInput } from './DatePickerInput'
import { type InputProps } from '../../../..'

type KeepReactDatePickerProps = ReactDatePickerProps & {
  startDate?: Date | null
  endDate?: Date | null
  'data-test'?: string
  selectsRange?: never
  selectsMultiple?: never
  onChange?: (date: Date | null, event?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => void
}

type KeepInputProps = Pick<InputProps, 'pill' | 'size' | 'invalid' | 'valid'>

export type DatePickerProps = KeepReactDatePickerProps & KeepInputProps

/**
 * A simple and reusable date picker component that wraps `react-datepicker`.
 * See docs at https://reactdatepicker.com.
 *
 * Some notes about our implementation:
 * - The `dateFormat` will be automatically set based on the type of date picker, but can be overridden
 * - All time related props are currently disabled
 * - Does not currently support `selectsRange` or `selectsMultiple`
 * - Some other props are disabled to prevent misuse/styling issues
 */
const DatePicker = forwardRef<ReactDatePicker, DatePickerProps>(({
  className,
  dateFormat: dateFormatIn,
  showYearPicker = false,
  showMonthYearPicker = false,
  showQuarterYearPicker = false,
  selectsStart = false,
  selectsEnd = false,
  startDate,
  endDate,
  pill = false,
  size,
  invalid = false,
  valid = false,
  'data-test': dataTest,
  ...otherAttributes
}, ref) => {
  const wrapperClassName = clsx(
    'form-date-picker',
    selectsStart && 'form-date-picker-start',
    selectsEnd && 'form-date-picker-end',
    invalid !== false && invalid !== '' && 'is-invalid',
    valid === true && 'is-valid',
    className
  )
  const popperClassNames = clsx(
    'form-date-picker-popover popover bs-popover-auto',
    showYearPicker && 'form-date-picker-popover-year',
    showMonthYearPicker && 'form-date-picker-popover-month-year',
    showQuarterYearPicker && 'form-date-picker-popover-quarter-year'
  )
  const dateFormat = useMemo(() => {
    if (dateFormatIn != null) {
      return dateFormatIn
    } else if (showYearPicker) {
      return 'yyyy'
    } else if (showMonthYearPicker) {
      return 'MM/yyyy'
    } else if (showQuarterYearPicker) {
      return 'yyyy, QQQ'
    } else {
      return 'MM/dd/yyyy'
    }
  }, [dateFormatIn, showYearPicker, showMonthYearPicker, showQuarterYearPicker])

  return (
    <ReactDatePicker
      wrapperClassName={wrapperClassName}
      dropdownMode='select'
      showMonthDropdown
      showYearDropdown
      showYearPicker={showYearPicker}
      showMonthYearPicker={showMonthYearPicker}
      showQuarterYearPicker={showQuarterYearPicker}
      popperClassName={popperClassNames}
      popperPlacement='bottom'
      dateFormat={dateFormat}
      selectsStart={selectsStart}
      selectsEnd={selectsEnd}
      startDate={startDate ?? undefined}
      endDate={endDate ?? undefined}
      formatWeekDay={date => date.substring(0, 1)}
      popperModifiers={[
        offset(9),
        flip(),
        shift({
          padding: 10,
          limiter: limitShift({
            offset: 10
          })
        })
      ]}
      customInput={
        <DatePickerInput
          pill={pill}
          size={size}
          invalid={invalid}
          valid={valid}
          data-test={dataTest}
        />
      }
      ref={ref}
      {...otherAttributes}
    />
  )
})
DatePicker.displayName = 'DatePicker'

export {
  DatePicker
}
