import { type ReactElement } from 'react'
import { CmsContent } from '@stuller/stullercom/feat/cms-content'

/**
 * Showcase promotion banner from CMS
 */
function ShowcasePromotionBanner (): ReactElement {
  return (
    <div className='container-xxxl mw-xxl px-4 d-none d-md-block'>
      <div className='row'>
        <div className='col-12 text-center'>
          <CmsContent
            contentContainerName='showcase-promotion-banner'
            skipLoading
            noRefetch
          />
        </div>
      </div>
    </div>
  )
}

export {
  ShowcasePromotionBanner
}
