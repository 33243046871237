import { type ReactElement, memo } from 'react'
import Link from 'next/link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'

interface MarketRateProps {
  /**
   * The name of the market rate
   */
  name: string
  /**
   * The current market rate amount
   */
  currentRate: number
  /**
   * The previous market rate amount
   */
  previousRate: number
  /**
   * The url to view more info about market rates
   */
  url: string
}

/**
 * Single market rate
 */
const MarketRate = memo(({
  name,
  currentRate,
  previousRate,
  url
}: MarketRateProps): ReactElement => {
  return (
    <div className='col-12 col-md-auto lh-sm'>
      {name}
      <Link
        className='link-white text-decoration-none fw-normal ps-2'
        href={url}
        target='_blank'
        rel='noreferrer'
      >
        {currentRate.toFixed(2)}
      </Link>
      {currentRate >= previousRate
        ? <FontAwesomeIcon icon={fas.faCaretUp} className='text-cyan-200 ps-3 align-middle' />
        : <FontAwesomeIcon icon={fas.faCaretDown} className='text-cyan-200 ps-3' />}
    </div>
  )
})
MarketRate.displayName = 'MarketRate'

export {
  MarketRate
}
