import { type ReactElement, type ReactNode } from 'react'
import Link from 'next/link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'
import { Button, Collapse, Form, Input, InputGroup } from '@stuller/stullercom/ui'
import { useToggle } from '@stuller/shared/util/react-hooks'
import {
  useGetUserHasSpecialOrderMessagesQuery, useGetAccountSettingsUserMenuPermissionsQuery
} from '@stuller/stullercom/data-access/apollo-queries'
import { clsx } from 'clsx'

interface MainLinkProps {
  /**
   * The name of the link
   */
  children?: ReactNode
  /**
  * classNames of children's containing element
  */
  childrenClass?: string
  /**
   * The url for the link
   */
  href: string
}

/**
 * Main link for the main nav (desktop and mobile)
 */
function MainLink ({ children, childrenClass, href }: MainLinkProps): ReactElement {
  const classNames = clsx('col', childrenClass)

  return (
    <div className='col-auto flex-shrink-1 text-md-center'>
      <Link href={href} className='link-dark text-decoration-none d-block py-2 py-md-4 px-4 text-wrap h-100 bg-gray-200-hover'>
        <div className='row align-items-center h-100'>
          <div className={classNames}>
            {children}
          </div>
        </div>
      </Link>
    </div>
  )
}

/**
 * Main links for main nav
 */
function MainLinks (): ReactElement {
  const { data } = useGetUserHasSpecialOrderMessagesQuery()
  const { data: accountPermissions } = useGetAccountSettingsUserMenuPermissionsQuery()
  const uniqueRequestsClass = clsx(data?.viewer?.hasUnreadSpecialOrderMessages === true && 'bg-red-200 text-red-600 rounded')

  return (
    <>
      <MainLink href='/browse'>Products</MainLink>
      <MainLink href='/quickorder'>Quick Order</MainLink>
      <MainLink href='/uniquerequests/orders' childrenClass={uniqueRequestsClass}>Unique Requests</MainLink>
      <MainLink href='/favorite'>Favorites</MainLink>
      <MainLink href='/orders'>Order History</MainLink>
      {accountPermissions?.viewerPermissions.canViewSignetCustomDesignHub === true && <MainLink href='/cadcamservices'>Custom Design Hub</MainLink>}
    </>
  )
}

/**
 * Simple search form that submits to the search page
 */
function Search (): ReactElement {
  return (
    <div className='col col-md col-xl-4 py-2 px-4'>
      <div className='row align-items-center h-100'>
        <div className='col'>
          <Form method='get' action='/search/results'>
            <Input type='hidden' name='pg' value='1' />
            <InputGroup className='flex-nowrap'>
              <Input
                type='search'
                name='query'
                maxLength={500}
                style={{ minWidth: 150 }}
                placeholder='Enter Keywords or Item #'
              />
              <Button type='submit' outline>
                Search
              </Button>
            </InputGroup>
          </Form>
        </div>
      </div>
    </div>
  )
}

/**
 * Main nav for mobile (search and main links collapse)
 */
function MobileNav (): ReactElement {
  const [open, handleToggle] = useToggle(false)

  return (
    <div className='d-md-none row gx-0 align-items-stretch'>
      <Search />

      <div className='col-auto d-md-none px-4'>
        <div className='row align-items-center h-100 cursor-pointer' onClick={handleToggle}>
          <div className='col-auto pt-2 border rounded'>
            <FontAwesomeIcon icon={fas.faBars} className='col-auto fs-3 fs-force' />
          </div>
        </div>
      </div>

      <Collapse isOpen={open} className='col-12 pb-2'>
        <MainLinks />
      </Collapse>
    </div>
  )
}

/**
 * Main nav for desktop (search and main links)
 */
function DesktopNav (): ReactElement {
  return (
    <div className='d-none d-md-flex row gx-0 align-items-stretch flex-nowrap'>
      <Search />
      <MainLinks />
    </div>
  )
}

/**
 * Sterling main nav
 */
function MainNavSterling (): ReactElement {
  return (
    <nav className='bg-gray-100 d-print-none'>
      <div className='container-xxxl p-0'>
        <DesktopNav />
        <MobileNav />
      </div>
    </nav>
  )
}

export {
  MainNavSterling
}
