import { META_DESCRIPTION_DEFAULT, META_DESCRIPTION_SHOWCASE_DEFAULT, META_TITLE_SHOWCASE_REPLACE } from '@stuller/shared/util/constants'
import { type MetaDescriptionProps } from '@stuller/stullercom/feat/layout-context'
import { type ReactElement, memo } from 'react'
import { useAuth } from '@stuller/stullercom/feat/auth'
import Head from 'next/head'

/**
 * Customizable `<meta name='description'>` with 'Stuller' replacement
 */
const MetaDescription = memo(({
  children,
  disabled = false
}: MetaDescriptionProps): ReactElement | null => {
  const { isShowcase, showcaseSettings } = useAuth()
  if (disabled) {
    return null
  }

  let description = (children ?? '').trim()
  if (isShowcase) {
    description = description.replace(META_TITLE_SHOWCASE_REPLACE, '').trim()
    if (description === '') {
      description = showcaseSettings?.title ?? META_DESCRIPTION_SHOWCASE_DEFAULT
    }
  } else if (description === '') {
    description = META_DESCRIPTION_DEFAULT
  }

  return (
    <Head>
      <meta name='description' content={description} />
      <meta property='og:description' key='og:description' content={description} />
      <meta property='twitter:description' key='twitter:description' content={description} />
    </Head>
  )
})
MetaDescription.displayName = 'MetaDescription'

export {
  MetaDescription
}
