import { createContext } from 'react'
import { type PopoverProps } from '../../..'

type DropdownContextPopoverProps = Pick<PopoverProps, (
  'open' |
  'onOpenChange' |
  'reference' |
  'placement' |
  'offset' |
  'shift' |
  'hover' |
  'dismiss'
)>

export interface DropdownContextType extends DropdownContextPopoverProps {
  /**
   * The type of dropdown
   */
  type: 'dropdown' | 'dropup' | 'dropend' | 'dropstart'
  /**
   * Function to call to toggle the dropdown (used in `DropdownToggle`)
   */
  onToggle?: () => void
}

const DropdownContext = createContext<DropdownContextType>({
  type: 'dropdown'
})

export {
  DropdownContext
}
