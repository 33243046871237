import { createContext, type Dispatch, type SetStateAction } from 'react'
import {
  type GetNotificationsFeedQueryHookResult,
  type GetNotificationsUnreadCountQueryHookResult,
  type UpdateNotificationsFeedReadMutationHookResult
} from '@stuller/stullercom/data-access/apollo-queries'

export interface MessagesPopoverContextType {
  /**
   * Result of the notifications feed query
   */
  feed: GetNotificationsFeedQueryHookResult
  /**
   * Result of the notifications unread count query
   */
  unreadCount: GetNotificationsUnreadCountQueryHookResult
  /**
   * Mutation function to update the notification feed as read
   */
  updateFeedRead: UpdateNotificationsFeedReadMutationHookResult[0]
  /**
   * Result of the update notification feed as read mutation
   */
  feedReadUpdate: UpdateNotificationsFeedReadMutationHookResult[1]
  /**
   * Filter for unread important messages only
   */
  unreadImportantOnly: boolean
  setUnreadImportantOnly: Dispatch<SetStateAction<boolean>>
  /**
   * Filter for shipment messages
   */
  shipments: boolean
  setShipments: Dispatch<SetStateAction<boolean>>
  /**
   * Filter for messages/updates messages
   */
  messagesUpdates: boolean
  setMessagesUpdates: Dispatch<SetStateAction<boolean>>
}

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
const MessagesPopoverContext = createContext<MessagesPopoverContextType>({} as MessagesPopoverContextType)

export {
  MessagesPopoverContext
}
