import { useMemo } from 'react'

interface UseCurrencyOptions {
  /**
   * Locale culture to use, for example `en-US`
   */
  culture?: string
  /**
   * Currency code symbol to use, for example `USD`
   */
  code?: string
}

/**
 * Hook to make it easy to format currency value for display
 */
function useCurrency (
  value: number = 0,
  {
    culture = 'en-US',
    code = 'USD'
  }: UseCurrencyOptions = {}
): [string, Intl.NumberFormatPart[]] {
  return useMemo(() => {
    const currencyFormatter = Intl.NumberFormat(culture, { style: 'currency', currency: code })

    return [currencyFormatter.format(value), currencyFormatter.formatToParts(value)]
  }, [value, culture, code])
}

export {
  useCurrency
}
