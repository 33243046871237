import clsx from 'clsx'
import { forwardRef, type ReactNode } from 'react'

interface OptionCardDescriptionProps {
  /**
   * Id of element
   */
  id?: string
  /**
   * Content for the option card description
   */
  children?: ReactNode
  /**
   * Additional class name(s) to give to the containing element
   */
  className?: string
}

const OptionCardDescription = forwardRef<HTMLDivElement, OptionCardDescriptionProps>(({
  id,
  children,
  className,
  ...otherAttributes
}, ref) => {
  const classNames = clsx(
    'form-option-card-description',
    className
  )

  return (
    <div
      id={id}
      className={classNames}
      ref={ref}
      {...otherAttributes}
    >
      {children}
    </div>
  )
})

export {
  OptionCardDescription
}
