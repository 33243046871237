import {
  type ReactElement,
  type ReactNode,
  type ComponentPropsWithoutRef,
  useContext
} from 'react'
import clsx from 'clsx'
import { FormGroupContext } from './FormGroupContext'

type FormLabelTagProps = ComponentPropsWithoutRef<'label'>

interface FormLabelProps extends FormLabelTagProps {
  /**
   * Id of element
   */
  id?: string
  /**
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /**
   * Content of the label
   */
  children?: ReactNode
  /**
   * Id of element
   */
  htmlFor?: string
  /**
   * Indicates the label is for a checkbox, radio, or switch
   */
  check?: boolean
  /**
   * Indicates the label is hidden
   */
  hidden?: boolean
  /**
   * Indicates label size
   */
  size?: 'sm' | 'lg'
  /**
   * Indicates a horizontal form label as a column with optional label sizing
   * You still need to specify the column sizing with `className` with a `col*` class
   */
  column?: boolean
}

/**
 * Form `<label>` that can be used for any form layout and input type.
 */
function FormLabel ({
  htmlFor: htmlForIn,
  className,
  children,
  check = false,
  hidden = false,
  column = false,
  size,
  ...otherAttributes
}: FormLabelProps): ReactElement {
  const { groupId } = useContext(FormGroupContext)
  const htmlFor = htmlForIn ?? groupId
  const classNames = clsx(
    check && 'form-check-label',
    hidden && 'visually-hidden',
    column
      ? [
          'col-form-label',
          size != null && `col-form-label-${size}`
        ]
      : [
          !check && 'form-label',
          size != null && `form-label-${size}`
        ],
    className
  )

  return (
    <label
      htmlFor={htmlFor}
      className={classNames}
      {...otherAttributes}
    >
      {children}
    </label>
  )
}

export {
  FormLabel
}
