import { type IncomingMessage } from 'http'

export interface UrlParts {
  protocol: string
  host: string
  path: string
  origin: string
  url: string
  urlObject: URL | null
}

export interface UrlPartsServerInput {
  req: IncomingMessage
  path?: string
}

const X_FORWARDED_HOST = 'x-forwarded-host'
const X_FORWARDED_PROTO = 'x-forwarded-proto'

/**
 * Gets url parts from req or window as needed
 */
function urlParts (serverInput?: UrlPartsServerInput): UrlParts {
  let host = ''
  let protocol = ''
  let path = ''
  let origin = ''
  let url = ''

  // Populate with server-side or client-side parts
  if (serverInput?.req != null) {
    host = serverInput.req.headers.host ?? ''
    protocol = /^localhost(:\d+)?$/.test(host) ? 'http:' : 'https:'
    path = serverInput.path ?? serverInput.req.url ?? ''

    // Reverse proxy overrides
    const forwardedHost = serverInput.req?.headers[X_FORWARDED_HOST]
    const forwardedProto = serverInput.req?.headers[X_FORWARDED_PROTO]
    if (forwardedHost != null && typeof forwardedHost === 'string') {
      host = forwardedHost
    }
    if (forwardedProto != null && typeof forwardedProto === 'string') {
      protocol = forwardedProto
    }

    // Fix if protocol doesn't end in `:`
    if (!protocol.endsWith(':')) {
      protocol += ':'
    }

    origin = protocol.length > 0 && host.length > 0 ? `${protocol}//${host}` : ''
    url = `${origin}${path}`
  } else if (typeof window !== 'undefined') {
    protocol = window.location.protocol
    host = window.location.host
    path = window.location.pathname
    origin = window.location.origin
    url = window.location.href
  }

  return {
    protocol,
    host,
    path,
    origin,
    url,
    urlObject: url !== '' ? new URL(url) : null
  }
}

export {
  urlParts
}
