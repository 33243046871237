import { type CSSProperties, type ReactNode, forwardRef } from 'react'
import { clsx } from 'clsx'
import { type HeadingTag } from '../../types/bootstrap'

interface CardTitleType {
  /**
   * Id of element
   */
  id?: string
  /**
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /**
   * Children of element
   */
  children?: ReactNode
  /**
   * Inline styles to pass to the containing element
   */
  style?: CSSProperties
  /**
   * HTML element tag type, must be h1-h5
   */
  tag?: Exclude<HeadingTag, 'h6'>
}

/**
 * React component wrapper of bootstrap's card-title component to serve as
 * header element for Card component
 */
const CardTitle = forwardRef<HTMLHeadingElement, CardTitleType>(({
  className,
  children,
  tag: Tag = 'h5',
  ...otherAttributes
}, ref) => {
  const classNames = clsx('card-title', className)

  return (
    <Tag
      className={classNames}
      ref={ref}
      {...otherAttributes}
    >
      {children}
    </Tag>
  )
})
CardTitle.displayName = 'CardTitle'

export {
  CardTitle
}
