import React, { forwardRef, type ReactNode } from 'react'
import { Card } from '../../card/Card'
import { Input, type InputProps } from '../input/Input'
import clsx from 'clsx'
import { CardBody } from '../../card/CardBody'
import { FormLabel } from '../form/FormLabel'

export interface OptionCardProps extends InputProps {
  /**
   * Id of the element
   */
  id?: string
  /**
   * Name of the input element
   */
  name?: string
  /**
   * String for the value of the input
   */
  value?: string
  /**
   * Boolean for if the input is selected
   */
  checked?: boolean
  /**
   * onChange handler
   */
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  /**
   * Input type of the cards
   */
  type?: 'radio' | 'checkbox'
  /**
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /**
   * Content for the option card
   */
  children?: ReactNode
  /**
   * Indicates something in the input group is invalid to show fail message
   */
  invalid?: boolean | string
  /**
   * Indicates something in the input group is valid to show success message
   */
  valid?: boolean | string
  /**
   * Disabled functionality and styling
   */
  disabled?: boolean
}

/**
 * Option Card component for rendering radio or checkbox option cards
 */
const OptionCard = forwardRef<HTMLDivElement, OptionCardProps>(({
  id,
  name,
  className,
  value,
  checked,
  onChange,
  type = 'radio',
  children,
  invalid = false,
  valid = false,
  disabled = false,
  ...otherAttributes
}, ref) => {
  const classNames = clsx(
    'form-option-card',
    checked === true && 'is-checked',
    invalid !== false && invalid !== '' && 'is-invalid',
    valid === true && 'is-valid',
    disabled && 'is-disabled',
    className
  )

  return (
    <Card id={id} className={classNames} ref={ref}>
      <CardBody>
        <FormLabel>
          <Input
            type={type}
            name={name}
            value={value}
            disabled={disabled}
            checked={checked}
            onChange={onChange}
            {...otherAttributes}
          />
          <div>
            {children}
          </div>
        </FormLabel>
      </CardBody>
    </Card>
  )
})
OptionCard.displayName = 'OptionCard'

export {
  OptionCard
}
