import { CmsContent } from '@stuller/stullercom/feat/cms-content'
import { type ReactElement } from 'react'

/**
 * Sterling search nav
 */
function SearchNavSterling (): ReactElement {
  return (
    <header className='container-xxxl d-print-none'>
      <div className='row align-items-center m-0 pb-3'>
        <div className='col-auto text-start'>
          <img className='img-fluid' src='https://meteor.stullercloud.com/das/113139950?size=422,70' alt='Home' />
        </div>
        <div className='col text-end d-none d-md-block'>
          <CmsContent
            contentContainerName='signet-contact-info'
            loadingProps={{
              position: 'right'
            }}
          />
        </div>
      </div>
    </header>
  )
}

export {
  SearchNavSterling
}
