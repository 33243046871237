import { type ReactElement, memo } from 'react'
import { type FooterProps } from '@stuller/stullercom/feat/layout-context'
import { LiveChat } from '@stuller/stullercom/feat/live-chat'
import { useAuth } from '@stuller/stullercom/feat/auth'
import { AnkleNav } from './AnkleNav'
import { AccountRepBanner } from './AccountRepBanner'
import { FooterNav } from './FooterNav'
import { ShowcaseFooter } from './showcase-footer/ShowcaseFooter'

/**
 * Footer component for the site
 */
const Footer = memo(({
  disabled = false,
  accountRepBanner,
  liveChat
}: FooterProps): ReactElement | null => {
  const { isEmbeddedJewelerShowcase, isJewelerShowcasePunchout } = useAuth()
  if (disabled || isEmbeddedJewelerShowcase || isJewelerShowcasePunchout) {
    return null
  }

  return (
    <>
      <AnkleNav />
      <AccountRepBanner {...accountRepBanner} />
      <ShowcaseFooter />
      <LiveChat {...liveChat} />
      <FooterNav />
    </>
  )
})
Footer.displayName = 'Footer'

export {
  Footer
}
