import { type ReactElement } from 'react'
import Link from 'next/link'
import { Title } from '@stuller/stullercom/feat/layout'
import { Button } from '@stuller/stullercom/ui'
import { useAuth } from '@stuller/stullercom/feat/auth'
import { ErrorContact } from './ErrorContact'
import { ERROR_403_IMAGE } from '@stuller/shared/util/constants'

/**
 * 403 error page (no layout)
 */
function Error403 (): ReactElement {
  const { authUser, isShowcase } = useAuth()

  /**
   * Go back to previous page
   */
  function handleGoBack (): void {
    window.history.back()
  }

  return (
    <div className='container-xxxl mw-xxl px-4 py-4'>
      <Title>Nothing to See Here - 403 Error</Title>
      <div>
        <h2 className='text-center'>Nothing to See Here (403 Error)</h2>
        <div className='row'>
          <div className='col-md-6'>
            <p>Unfortunately, the page you are trying to view is forbidden and access is not allowed.</p>
            <p>So what do you do now?  Well, here are a few options:</p>

            <ul>
              {authUser != null && !isShowcase && <li><Link href='/login'>Try logging into Stuller.com</Link></li>}
              <li><Button color='anchor' onClick={handleGoBack}>Go Back to the page you were on</Button></li>
              <li><Link href='/'>Return to the Home Page</Link></li>
            </ul>

            <ErrorContact />
          </div>

          <div className='col-md-6 text-center d-none d-md-block'>
            <img className='img-fluid' src={ERROR_403_IMAGE} alt='Page Not Found' />
          </div>
        </div>
      </div>
    </div>
  )
}

export {
  Error403
}
