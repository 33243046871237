import { type ChangeEvent, type ReactElement, useCallback, useContext, useEffect, useMemo } from 'react'
import { Form, FormGroup, FormLabel, Input } from '@stuller/stullercom/ui'
import { MessagesPopoverContext } from './MessagesPopoverContext'
import Cookies from 'js-cookie'
import { useAuth } from '@stuller/stullercom/feat/auth'

function MessagesFilters (): ReactElement {
  const {
    unreadCount,
    unreadImportantOnly,
    setUnreadImportantOnly,
    shipments,
    setShipments,
    messagesUpdates,
    setMessagesUpdates
  } = useContext(MessagesPopoverContext)
  const unreadCountImportant = unreadCount.data?.viewer?.notificationsUnreadCount.important ?? 0
  const { authUser } = useAuth()

  const cookieName = useMemo(() => `Persist-messages_settings_${authUser?.user?.id ?? ''}`, [authUser])

  const getCookie = useCallback(() => {
    return JSON.parse(Cookies.get(cookieName) ?? '{}') as { shipments?: boolean, messagesUpdates?: boolean }
  }, [cookieName])

  function handleUnreadImportantOnly (event: ChangeEvent<HTMLInputElement>): void {
    setUnreadImportantOnly(event.target.checked)
    void unreadCount.refetch()
  }

  function updateCookie (key: string, value: boolean): void {
    const prevCookies = getCookie()
    const updatedCookie = {
      ...prevCookies,
      [key]: value
    }
    Cookies.set(cookieName, JSON.stringify(updatedCookie), { secure: true })
  }

  function handleShipmentsChange (event: ChangeEvent<HTMLInputElement>): void {
    setShipments(event.target.checked)
    updateCookie('shipments', event.target.checked)
    void unreadCount.refetch()
  }

  function handleMessagesUpdatesChange (event: ChangeEvent<HTMLInputElement>): void {
    setMessagesUpdates(event.target.checked)
    updateCookie('messagesUpdates', event.target.checked)
    void unreadCount.refetch()
  }

  /**
   * On component load, read cookie and set defaults
   */
  useEffect(() => {
    const cookieSettings = getCookie()
    if (cookieSettings.shipments !== undefined) {
      setShipments(cookieSettings.shipments)
    }
    if (cookieSettings.messagesUpdates !== undefined) {
      setMessagesUpdates(cookieSettings.messagesUpdates)
    }
  }, [getCookie, setShipments, setMessagesUpdates])

  return (
    <Form>
      {unreadCountImportant > 0 && (
        <>
          <FormGroup
            check='switch'
            groupId='unread-important-only'
            inline
          >
            <Input type='checkbox' checked={unreadImportantOnly} onChange={handleUnreadImportantOnly} />
            <FormLabel check>
              Unread Alerts Only:
              <span className='text-danger fw-bold'>
                {' '}{unreadCountImportant} Unread Alert{unreadCountImportant > 1 && 's'}
              </span>
            </FormLabel>
          </FormGroup>
          {!unreadImportantOnly && <hr className='mt-0' />}
        </>
      )}
      {!(unreadImportantOnly && unreadCountImportant > 0) && (
        <div className='row justify-content-evenly gx-3'>
          <div className='col-auto'>
            <FormGroup
              check='switch'
              groupId='shipments'
              inline
            >
              <Input type='checkbox' checked={shipments} onChange={handleShipmentsChange} />
              <FormLabel check>Shipments</FormLabel>
            </FormGroup>
          </div>
          <div className='col-auto'>
            <FormGroup
              check='switch'
              groupId='messages-updates'
              inline
            >
              <Input type='checkbox' checked={messagesUpdates} onChange={handleMessagesUpdatesChange} />
              <FormLabel check>Messages & Updates</FormLabel>
            </FormGroup>
          </div>
        </div>
      )}
    </Form>
  )
}

export {
  MessagesFilters
}
