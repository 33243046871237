import { memo, type ReactElement } from 'react'
import clsx from 'clsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'
import { type RatePageProps } from '@stuller/stullercom/feat/layout-context'
import { useToggle } from '@stuller/shared/util/react-hooks'
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody
} from '@stuller/stullercom/ui'
import { useAuth } from '@stuller/stullercom/feat/auth'
import { CmsContent } from '@stuller/stullercom/feat/cms-content'

/**
 * Rate this page component
 */

const RatePage = memo(({
  className,
  disabled = false,
  title = 'Rate This Page',
  hideIcon = false
}: RatePageProps): ReactElement | null => {
  const { authUser, isJewelerShowcase, isSterling } = useAuth()
  const [modalOpen, toggleModalOpen] = useToggle()
  const classNames = clsx(
    'fs-6 fw-normal text-decoration-none mt-5',
    className
  )

  if (disabled || authUser == null || isJewelerShowcase || isSterling) {
    return null
  }

  return (
    <div className='container-xxxl mw-xxl px-4 pb-3 d-print-none'>
      <div className='row justify-content-end'>
        <div className='col-auto'>
          <Button className={classNames} color='anchor-dark' onClick={toggleModalOpen}>
            {!hideIcon && <span>[ <FontAwesomeIcon icon={fas.faComment} /> ] </span>}
            {title}
          </Button>
        </div>
        <Modal isOpen={modalOpen} onToggle={toggleModalOpen}>
          <ModalHeader onToggle={toggleModalOpen}>{title}</ModalHeader>
          <ModalBody className='mb-6'>
            <CmsContent contentContainerName='rate-page-form' />
          </ModalBody>
        </Modal>
      </div>
    </div>
  )
})
RatePage.displayName = 'RatePage'

export {
  RatePage
}
