import { forwardRef, useMemo, useState, useCallback, type ChangeEvent, type ComponentPropsWithoutRef, useEffect } from 'react'
import { Input } from '../input/Input'
import InputMask from 'react-input-mask'
import { CreditCardIcon } from './CreditCardIcon'
import { type CreditCardType } from 'credit-card-type/dist/types'
import creditCardType from 'credit-card-type'
import Verification from 'card-validator'
import clsx from 'clsx'

type InputTagProps = Omit<ComponentPropsWithoutRef<'input'>, 'size' | 'onChange' | 'value'>

export interface CreditCardNumberInputProps extends InputTagProps {
  /**
   * Id of element
   */
  id?: string
  /**
   * Input name field
   */
  name?: string
  /**
   * Input required field
   */
  required?: boolean
  /**
   * Input onChange event
   */
  onChange?: (formattedCreditCardNumber: string, cardType: CreditCardType | null, invalid: boolean) => void
  /**
   * Is field disabled
   */
  disabled?: boolean
  /**
   * Invalid styling
   */
  invalid?: boolean | string
  /**
   * Valid styling
   */
  valid?: boolean | string
  /**
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /**
   * Input value
   */
  value?: string
  /**
   * Is panpad being used
   */
  usePanPad?: boolean
}

/**
 * Get the credit card type based on the card number.
 */
function getCardType (cardNumber?: string): CreditCardType | null {
  const matchingCardTypes = cardNumber == null || cardNumber.trim() === '' ? null : creditCardType(cardNumber)

  return matchingCardTypes == null || matchingCardTypes.length === 0 ? null : matchingCardTypes[0]
}

/**
 * CreditCardNumberInput component for rendering a credit card number input field.
 */
const CreditCardNumberInput = forwardRef<HTMLDivElement, CreditCardNumberInputProps>(({
  id,
  name,
  required,
  onChange,
  disabled = false,
  invalid = false,
  valid = false,
  className,
  value = '',
  usePanPad = false,
  ...otherAttributes
}, ref) => {
  const [cardType, setCardType] = useState<CreditCardType | null>(null)
  const [cardIsValid, setCardIsValid] = useState<boolean>(true)
  const classNames = clsx(
    'form-credit-card-number',
    ((invalid !== false && invalid !== '') || !cardIsValid) && 'is-invalid',
    valid === true && cardIsValid && 'is-valid',
    className
  )
  const cardTypeMaxLength = useMemo(() => cardType != null ? Math.max(...cardType.lengths) : 16, [cardType])
  const cardMask = useMemo(() => {
    const mask: Array<string | RegExp> = []
    for (let i = 0; i < cardTypeMaxLength; i++) {
      if (cardType != null && cardType.gaps.filter(x => x === i).length > 0) {
        mask.push(' ')
      }
      if (!usePanPad) {
        mask.push(/[0-9]/)
      } else {
        mask.push(/.*/)
      }
    }

    return mask
  }, [cardType, cardTypeMaxLength, usePanPad])

  const handleCardInputChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const cardInputValue = event.target.value
    const matchingCardType = getCardType(cardInputValue)
    setCardType(matchingCardType)
    setCardIsValid(Verification.number(cardInputValue).isPotentiallyValid)
    onChange?.(cardInputValue, matchingCardType, !Verification.number(cardInputValue).isPotentiallyValid)
  }, [onChange])

  /**
   * (PanPad Only) when value updates, get the card value and trigger the onChange callback
   */
  useEffect(() => {
    if (usePanPad && value !== '') {
      const cardInputValue = value
      const matchingCardType = getCardType(cardInputValue)
      onChange?.(value, matchingCardType, false)
      setCardType(matchingCardType)
    }
  }, [value, usePanPad, onChange])

  /**
   * If the value is empty, reset the card type
   */
  useEffect(() => {
    if (value === '' && cardType !== null) {
      setCardType(null)
    }
  }, [setCardType, value, cardType])

  const beforeMaskedStateChange = useCallback(({ currentState, nextState }: { currentState: any, nextState: any }) => {
    if (currentState?.value != null && currentState.value !== '') {
      currentState.value = currentState.value.replace(/[^0-9+*]/g, '') // Remove all non-numeric characters exclusing *'s
      const trimmedValue = currentState.value.replaceAll(' ', '')
      const maxLength = trimmedValue.length < cardTypeMaxLength ? trimmedValue.length : cardTypeMaxLength

      let newMaskedValue: string = ''
      let gapsAdded = 0
      for (let i = 0; i < maxLength; i++) {
        if (cardType != null && cardType.gaps.filter((x: number) => x === i).length > 0) {
          newMaskedValue += ` ${trimmedValue[i] as string}`
          gapsAdded++
        } else {
          newMaskedValue += trimmedValue[i] as string
        }
      }

      return {
        value: newMaskedValue,
        selection: {
          start: newMaskedValue.length,
          end: newMaskedValue.length + gapsAdded
        }
      }
    }

    return nextState
  }, [cardType, cardTypeMaxLength])

  return (
    <div className={classNames}>
      <CreditCardIcon
        displayCardType={cardType?.niceType}
      />
      <InputMask
        mask={cardMask}
        maskPlaceholder={null}
        disabled={usePanPad || disabled || false}
        value={value}
        onChange={handleCardInputChange}
        beforeMaskedStateChange={beforeMaskedStateChange}
        {...otherAttributes}
      >
        <Input
          id={id}
          name={name}
          inputMode='numeric'
          placeholder='0000 0000 0000 0000'
          invalid={!cardIsValid || invalid}
          valid={valid}
          required={required}
          autoComplete='cc-number'
          autoCorrect='off'
          spellCheck='false'
          data-test='card-number'
        />
      </InputMask>
    </div>
  )
})
CreditCardNumberInput.displayName = 'CreditCardNumberInput'

export { CreditCardNumberInput }
