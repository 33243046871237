import { useStullerEventListener } from '@stuller/stullercom/feat/stuller-events'
import { submitFormBuilder } from './submitFormBuilder'

/**
 * Hook to install Stuller event listener to respond to form builder events
 */
function useFormBuilder (): void {
  useStullerEventListener('form-builder-submission', ({ detail }) => {
    if (detail != null) {
      void submitFormBuilder(detail)
    }
  })
}

export {
  useFormBuilder
}
