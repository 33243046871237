import { type ReactElement } from 'react'
import Link from 'next/link'
import { useAuth } from '@stuller/stullercom/feat/auth'
import {
  STULLER_LOGO_DESKTOP
} from '@stuller/shared/util/constants'

/**
 * Showcase logo or title
 */
function ShowcaseLogoOrTitle (): ReactElement | null {
  const { showcaseSettings, isEmbeddedJewelerShowcase } = useAuth()

  if (showcaseSettings == null || isEmbeddedJewelerShowcase) {
    return null
  }

  return showcaseSettings.logo != null
    ? <img
        className='img-fluid'
        alt='Logo'
        src={showcaseSettings.logo}
      />
    : (
      <div
        className='py-3 px-2 p-md-0 lh-1 fs-2 fw-medium'
        style={{
          color: `var(--bs-${showcaseSettings?.backgroundColorClass ?? ''}-color, var(--bs-gray-700))`
        }}
      >
        {showcaseSettings.title ?? ''}
      </div>)
}

/**
 * Stuller logo
 */
function StullerLogo (): ReactElement | null {
  return (
    <img
      src={STULLER_LOGO_DESKTOP}
      data-fallback='https://meteor.stullercloud.com/das/75581463?wid=270&fmt=smart-alpha'
      alt='Stuller'
      className='img-fluid'
      style={{ maxWidth: 180 }}
    />
  )
}

/**
 * Logo for search nav
 */
function Logo (): ReactElement | null {
  const { isShowcase } = useAuth()

  return (
    <Link href='/' data-gtm-click-text='Home' className='text-decoration-none'>
      {isShowcase ? <ShowcaseLogoOrTitle /> : <StullerLogo />}
    </Link>
  )
}

export {
  Logo
}
