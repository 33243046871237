import { type ReactElement } from 'react'
import clsx from 'clsx'
import { EligibleProductsModal } from './EligibleProductsModal'
import type plugin from 'dayjs/plugin/utc'

interface CountdownInfoSectionPreviousOrderProps {
  duration: plugin.Duration
}

/**
 * A component to show the shipping countdown info section for the "PREVIOUS_ORDER" kind
 */
function CountdownInfoSectionPreviousOrder ({ duration }: CountdownInfoSectionPreviousOrderProps): ReactElement {
  const classNames = clsx('fw-bold', duration.asHours() > 4 ? 'text-primary' : 'text-danger')

  return (
    <div>
      <div>
        Checkout in the next
        <span className={classNames}>{duration.format(' H[h] m[m] s[s] ')}</span>
        and eligible items can ship with your previous order
      </div>
      <div>
        <EligibleProductsModal />
      </div>
    </div>
  )
}

export {
  CountdownInfoSectionPreviousOrder
}
