import { META_TITLE_SHOWCASE_DEFAULT, META_TITLE_DEFAULT, META_TITLE_POSTFIX, META_TITLE_SHOWCASE_REPLACE } from '@stuller/shared/util/constants'
import { type ReactElement, memo } from 'react'
import { type TitleProps } from '@stuller/stullercom/feat/layout-context'
import { useAuth } from '@stuller/stullercom/feat/auth'
import Head from 'next/head'

/**
 * Customizable `<title>` with postfix and 'Stuller' replacement
 */
const Title = memo(({
  children,
  disabled = false
}: TitleProps): ReactElement | null => {
  const { isShowcase, showcaseSettings } = useAuth()
  if (disabled) {
    return null
  }

  let title = (children ?? '').replace(META_TITLE_POSTFIX, '').trim()
  if (isShowcase) {
    title = title.replace(META_TITLE_SHOWCASE_REPLACE, '').trim()
    if (title === '') {
      title = showcaseSettings?.title ?? META_TITLE_SHOWCASE_DEFAULT
    }
  } else {
    if (title !== '') {
      title = `${title}${META_TITLE_POSTFIX}`
    } else {
      title = META_TITLE_DEFAULT
    }
  }

  return (
    <Head>
      <title>{title}</title>
      <meta property='og:title' key='og:title' content={title} />
      <meta name='twitter:title' key='twitter:title' content={title} />
    </Head>
  )
})
Title.displayName = 'Title'

export {
  Title
}
