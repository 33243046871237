import { memo, type ReactElement, type ReactNode } from 'react'

export interface MainProps {
  /**
   * Children of layout
   */
  children?: ReactNode
}

/**
 * Main content of a site page (where the page component goes)
 */

const Main = memo(({
  children
}: MainProps): ReactElement => {
  return (
    <main>
      {children}
    </main>
  )
})
Main.displayName = 'Main'

export {
  Main
}
