import {
  type ReactElement,
  useCallback,
  type ChangeEvent,
  useMemo,
  useState
} from 'react'
import { Input } from '../input/Input'
import InputMask from 'react-input-mask'
import { type CreditCardType } from 'credit-card-type/dist/types'
import Verification from 'card-validator'

interface CvvInputProps {
  /**
   * Id of element
   */
  id?: string
  /**
   * Input name field
   */
  name: string
  /**
   * Input value field
   */
  value?: string
  /**
   * Input required field
   */
  required?: boolean
  /**
   * Card type from parent
   */
  cardType: CreditCardType | null
  /**
   * Input onChange event
   */
  onChange: (cvv: string, invalid: boolean) => void
  /**
   * Invalid styling
   */
  invalid?: boolean | string
  /**
   * Valid styling
   */
  valid?: boolean | string
}

/**
  * Get the CVV Input from the user
 */
function CvvInput ({ id, name, value, required, cardType, onChange, invalid, valid, ...otherAttributes }: CvvInputProps): ReactElement {
  const [cvvInputIsValid, setCvvInputIsValid] = useState<boolean>(true)
  const [internalvalue, setInternalValue] = useState<string | undefined>(value)
  const cvvMaxLength = useMemo(() => cardType?.code?.size ?? 3, [cardType])
  const cvvMask = useMemo(() => {
    const mask: RegExp[] = []
    for (let i = 0; i < cvvMaxLength; i++) {
      mask.push(/[0-9|*]/)
    }

    return mask
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cvvMaxLength, internalvalue])
  const cvvPlaceholder = useMemo(() => {
    let value = ''
    for (let i = 0; i < cvvMaxLength; i++) {
      value += '0'
    }

    return value
  }, [cvvMaxLength])

  /**
   * Callback to handle CVV input value change
   */
  const handleCvvChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const cvv = event.target.value
    setInternalValue(cvv)
    setCvvInputIsValid(Verification.cvv(cvv).isPotentiallyValid)
    onChange(cvv, !cvvInputIsValid)
  }, [onChange, cvvInputIsValid])

  return (
    <InputMask
      mask={cvvMask}
      maskPlaceholder={null}
      onChange={handleCvvChange}
      value={value}
      {...otherAttributes}
    >
      <Input
        id={id}
        name={name}
        placeholder={cvvPlaceholder !== '' ? cvvPlaceholder : '000'}
        type='tel'
        maxLength={cvvMaxLength}
        required={required}
        autoComplete='cc-csc'
        autoCorrect='off'
        spellCheck='false'
        className='c-gray-dk-3'
        invalid={invalid ?? !cvvInputIsValid}
        data-test='cvv'
      />
    </InputMask>
  )
}

export { CvvInput }
