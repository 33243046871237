import {
  forwardRef,
  useContext,
  type ReactElement
} from 'react'
import clsx from 'clsx'
import { MultiItemCarouselContext } from '../../..'

export interface MultiItemCarouselIndicatorsProps {
  /**
   * Id of element
   */
  id?: string
  /**
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /**
   * Additional class name(s) to give to the individual indicators
   */
  indicatorClassName?: string
}

export interface MultiItemCarouselIndicatorProps {
  /**
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /**
   * Index of the carousel item
   */
  index: number
}

/**
 * A single multi-item carousel indicator button
 */
function MultiItemCarouselIndicator ({
  className,
  index
}: MultiItemCarouselIndicatorProps): ReactElement {
  const { index: indexContext, handleIndex } = useContext(MultiItemCarouselContext)
  const classNames = clsx(index === indexContext ? 'active' : '', className)

  function handleClick (): void {
    handleIndex(index)
  }

  return (
    <button
      type='button'
      className={classNames}
      data-bs-target=''
      data-bs-slide-to={index}
      onClick={handleClick}
    />
  )
}

/**
 * MultiItemCarousel indicators component
 */
const MultiItemCarouselIndicators = forwardRef<HTMLDivElement, MultiItemCarouselIndicatorsProps>(({
  className,
  indicatorClassName,
  ...otherAttributes
}, ref) => {
  const { maxIndex } = useContext(MultiItemCarouselContext)
  const classNames = clsx(
    'carousel-indicators d-none d-md-flex',
    className
  )

  // Do not render if max index < 1
  if (maxIndex < 1) {
    return null
  }

  return (
    <div className='w-100'>
      <div
        className={classNames}
        ref={ref}
        {...otherAttributes}
      >
        {Array.from(Array(maxIndex + 1)).map((_, i) => (
          <MultiItemCarouselIndicator
            key={i}
            className={indicatorClassName}
            index={i}
          />))}
      </div>
    </div>

  )
})
MultiItemCarouselIndicators.displayName = 'MultiItemCarouselIndicators'

export {
  MultiItemCarouselIndicators
}
