import { type ReactElement } from 'react'

/**
 * Renders banner for unsupported browser without JavaScript via `noscript` tag
 */
function UnsupportedNoscript (): ReactElement {
  return (
    <noscript>
      <div className='bg-blue-500 text-center text-white py-6 fs-4 fw-bold'>
        You have JavaScript disabled on your browser and this site will not function as intended.
      </div>
    </noscript>
  )
}

export {
  UnsupportedNoscript
}
