import { useMemo } from 'react'
import {
  ApolloClient,
  ApolloLink,
  DocumentTransform,
  from,
  HttpLink,
  InMemoryCache,
  split,
  type HttpOptions
} from '@apollo/client'
import { onError, type ErrorHandler } from '@apollo/client/link/error'
import { visit, Kind, type ASTNode, stripIgnoredCharacters } from 'graphql'
import fetch from 'cross-fetch'
import merge from 'deepmerge'
import isEqual from 'lodash/isEqual'
import { urlParts, throwIfNull } from '@stuller/shared/util/core'
import {
  type GetServerSidePropsContext,
  type NextPageContext,
  type GetStaticPropsContext,
  type GetStaticPathsContext
} from 'next'
import { getDomainInfo } from '@stuller/stullercom/feat/auth'
import {
  X_HEADER_SITEMODE,
  SITEMODE_STERLING,
  SITEMODE_SHOWCASE,
  SITEMODE_SHOWCASE_IFRAME,
  SITEMODE_SHOWCASE_IFRAME_CATEGORYEMBED,
  SITEMODE_SHOWCASE_IFRAME_CATEGORYEMBED_WITH_ID,
  SITEMODE_PUNCHOUT
} from '@stuller/shared/util/constants'
import { type CustomNextPageContext, type StatusCodeProps } from '@stuller/stullercom/feat/layout-context'
import { typePolicies } from './typePolicies'
import { possibleTypesResult } from '@stuller/stullercom/data-access/apollo-queries'
import { siteConfig } from '@stuller/stullercom/util/site-config'
import { getUnauthorizedInitial } from '@stuller/stullercom/feat/unauthorized'
import ContentstackLivePreview from '@contentstack/live-preview-utils'
import { parse as setCookieHeaderParser } from 'set-cookie-parser'
import { parse as cookieHeaderParser } from 'cookie'
import { type ServerResponse } from 'http'

type NextAnyContext = (GetServerSidePropsContext | NextPageContext | GetStaticPropsContext | GetStaticPathsContext) & { apolloClient?: any }

let apolloClient: ApolloClient<unknown>

/**
 * Document transform to remove __typename from queries
 * This can be removed once they fix there issue with query sizes
 */
const contentstackDocumentTransform = new DocumentTransform((document) => {
  const transformedDocument = visit(document, {
    Field (field) {
      if (field.selectionSet?.selections != null) {
        field.selectionSet.selections = field.selectionSet.selections.filter(selection => {
          return selection.kind !== Kind.FIELD || selection.name.value !== '__typename'
        })
      }

      return field
    }
  })

  return transformedDocument
})

// Init Contentstack live preview (only client-side and in the Contentstack live preview iframe)
if (typeof window !== 'undefined' &&
  window.location !== window.parent.location &&
  window.location.ancestorOrigins?.[0] === 'https://app.contentstack.com' &&
  (ContentstackLivePreview.hash == null || ContentstackLivePreview.hash === '')) {
  void ContentstackLivePreview.init({
    ssr: true,
    runScriptsOnUpdate: false,
    cleanCslpOnProduction: false,
    editButton: {
      enable: false
    }
  })
}

/**
 * Factory class for functions to interact with Apollo client
 *
 * **DO NOT USE DIRECTLY**: Use the individual functions exported
 */
class Client {
  /**
   * Gets the auth redirect url giving the url to redirect back to
   */
  getAuthRedirectUrl = (url: string): string => {
    return `/login?ReturnUrl=${encodeURIComponent(url)}`
  }

  /**
   * Creates the Apollo client
   */
  createApolloClient = (context?: NextAnyContext): ApolloClient<unknown> => {
    // Get the search params to send to the Contentstack live preview
    let parts = null
    if (context != null && 'req' in context && context?.req != null) {
      parts = urlParts({ req: context.req })
    } else {
      parts = urlParts()
    }
    if (parts.urlObject != null) {
      ContentstackLivePreview.setConfigFromParams(parts.urlObject.searchParams)
    }

    // Create the Apollo client and prevent it from re-creating on the server
    const client = new ApolloClient({
      ssrMode: typeof window === 'undefined',
      link: this.getApolloLink(context),
      cache: new InMemoryCache({ typePolicies, possibleTypes: possibleTypesResult.possibleTypes }),
      connectToDevTools: siteConfig.NODE_ENV === 'development'
    })
    // @ts-expect-error - This is a hack to prevent Next.js from serializing the client
    client.toJSON = () => null

    return client
  }

  /**
   * Error handler for error link
   */
  errorHandler: ErrorHandler = ({ networkError, graphQLErrors }) => {
    // Create unauthenticated link
    if (graphQLErrors != null) {
      for (const graphQLError of graphQLErrors) {
        console.error(`[GraphQL error]: ${graphQLError.message}`, graphQLError)
        // Redirect for unauthenticated
        if (graphQLError.extensions?.code === 'UNAUTHENTICATED' && typeof window !== 'undefined') {
          window.location.assign(this.getAuthRedirectUrl(window.location.href))
          break
        }
      }
    }

    if (networkError != null) {
      console.error(`[Network error]: ${networkError.message}`, networkError)
      if ('statusCode' in networkError && networkError.statusCode === 401 && typeof window !== 'undefined') {
        window.location.assign(this.getAuthRedirectUrl(window.location.href))
      }
    }
  }

  /**
   * Contentstack Error handler for error link
   */
  contentstackErrorHandler: ErrorHandler = ({ networkError, graphQLErrors }) => {
    // Create unauthenticated link
    if (graphQLErrors != null) {
      if (Array.isArray(graphQLErrors)) {
        for (const graphQLError of graphQLErrors) {
          console.error(`[Contentstack GraphQL error]: ${graphQLError.message}`, graphQLError)
        }
      } else {
        console.error(`[Contentstack GraphQL error]: ${JSON.stringify(graphQLErrors)}`, graphQLErrors)
      }
    }

    if (networkError != null) {
      console.error(`[Contentstack Network error]: ${networkError.message}`, networkError)
    }
  }

  /**
   * Gets the merged cookies from the set cookie headers and existing cookies
   */
  getMergedCookiesFromSetHeaders = (res: ServerResponse, cookies?: string): string => {
    cookies ??= ''
    const existingCookies = cookieHeaderParser(cookies)

    // forward anything in the pending in the set cookie header as well
    let setCookieHeader = res.getHeader('Set-Cookie')
    if (!Array.isArray(setCookieHeader)) {
      setCookieHeader = setCookieHeader == null ? [] : [String(setCookieHeader)]
    }

    const setCookies = setCookieHeaderParser(setCookieHeader).filter(x => x.expires == null || x.expires > new Date())
    for (const cookie of setCookies) {
      if (existingCookies[cookie.name] == null) {
        if (cookies.length > 0) {
          cookies += `; ${cookie.name}=${cookie.value}`
        } else {
          cookies = `${cookie.name}=${cookie.value}`
        }
      }
    }

    return cookies
  }

  /**
   * Gets the http options for the Apollo client
   */
  getHttpOptions = (context?: NextAnyContext): HttpOptions => {
    let host = typeof window !== 'undefined' ? window.location.host : ''
    const httpOptions: HttpOptions = {
      uri: '/graphql',
      fetch,
      credentials: 'include',
      headers: {
        'apollographql-client-name': 'stullercom-site',
        'X-Requested-With': 'ajax'
      },
      print (ast: ASTNode, originalPrint: (node: ASTNode) => string) {
        return stripIgnoredCharacters(originalPrint(ast))
      }
    }

    // On the server, add cookies and make sure the URI is correct
    if (context != null && 'req' in context && context?.req != null) {
      host = context.req.headers.host ?? ''

      // Add x-forwarded-for headers to apollo client calls when in SSR context
      const xForwardedFor = context.req.headers['x-forwarded-for']
      const clientAddresses = Array.isArray(xForwardedFor)
        ? xForwardedFor
        : xForwardedFor != null
          ? [xForwardedFor]
          : []
      if (clientAddresses.length > 0 && httpOptions.headers != null) {
        httpOptions.headers['X-Forwarded-For'] = clientAddresses.join(',')
      }

      // Forward client cookies to GraphQL
      if (context.req.headers.cookie != null && httpOptions.headers != null) {
        const cookies = this.getMergedCookiesFromSetHeaders(throwIfNull(context.res, 'Response cannot be null'), context.req.headers.cookie)
        httpOptions.headers.Cookie = cookies
      }

      // Make sure the URI is correct on the server
      const req = context.req
      const relativeUriValueOrFn = httpOptions.uri
      httpOptions.uri = operation => {
        const relativeUri = relativeUriValueOrFn == null || typeof relativeUriValueOrFn === 'string'
          ? relativeUriValueOrFn
          : relativeUriValueOrFn(operation)
        const { url } = urlParts({ req, path: relativeUri })

        return url
      }
    }

    // Add `X-Stuller-Site-Mode` header
    const { isSterling, jewelerShowcaseDomainInfo, jewelerShowcasePunchoutInfo } = getDomainInfo(host)
    let stullerSiteMode = null
    if (isSterling) {
      stullerSiteMode = SITEMODE_STERLING
    } else if (jewelerShowcaseDomainInfo != null) {
      if (jewelerShowcaseDomainInfo.isEmbeddedCategoryIFrame && jewelerShowcaseDomainInfo.embeddedCategoryId != null) {
        stullerSiteMode = `${SITEMODE_SHOWCASE_IFRAME_CATEGORYEMBED_WITH_ID}.${jewelerShowcaseDomainInfo.subdomain}.${jewelerShowcaseDomainInfo.embeddedCategoryId}`
      } else if (jewelerShowcaseDomainInfo.isEmbeddedCategoryIFrame) {
        stullerSiteMode = `${SITEMODE_SHOWCASE_IFRAME_CATEGORYEMBED}.${jewelerShowcaseDomainInfo.subdomain}`
      } else if (jewelerShowcaseDomainInfo.isIFrame) {
        stullerSiteMode = `${SITEMODE_SHOWCASE_IFRAME}.${jewelerShowcaseDomainInfo.subdomain}`
      } else {
        stullerSiteMode = `${SITEMODE_SHOWCASE}.${jewelerShowcaseDomainInfo.subdomain}`
      }
    } else if (jewelerShowcasePunchoutInfo != null) {
      stullerSiteMode = `${SITEMODE_PUNCHOUT}.${jewelerShowcasePunchoutInfo.clientId}.${jewelerShowcasePunchoutInfo.userMemberId}.${jewelerShowcasePunchoutInfo.sessionId}`
    }
    if (stullerSiteMode != null && httpOptions.headers != null) {
      httpOptions.headers[X_HEADER_SITEMODE] = stullerSiteMode
    }

    return httpOptions
  }

  /**
   * Gets the http options for the Contentstack Apollo client
   */
  getContentstackHttpOptions = (context?: NextAnyContext): HttpOptions => {
    const contentstackHttpOptions: HttpOptions = {
      uri: this.getContentstackGraphqlUrl(),
      fetch,
      headers: this.getContentstackHeaders(),
      print (ast: ASTNode, originalPrint: (node: ASTNode) => string) {
        return stripIgnoredCharacters(originalPrint(ast))
      }
    }

    return contentstackHttpOptions
  }

  /**
   * Gets the Contentstack GraphQL URL based on whether or not a live preview hash is present
   */
  getContentstackGraphqlUrl = (): string | undefined => {
    const hash = ContentstackLivePreview.hash
    const url = hash != null && hash !== ''
      ? throwIfNull(siteConfig.NEXT_PUBLIC_CONTENTSTACK_LIVE_PREVIEW_GRAPHQL_URL, 'NEXT_PUBLIC_CONTENTSTACK_LIVE_PREVIEW_GRAPHQL_URL is a required environment variable.')
      : throwIfNull(siteConfig.NEXT_PUBLIC_CONTENTSTACK_GRAPHQL_URL, 'NEXT_PUBLIC_CONTENTSTACK_GRAPHQL_URL is a required environment variable.')

    return url
  }

  /**
   * Gets the Contentstack headers based on whether or not a live preview hash is present
   */
  getContentstackHeaders = (): Record<string, string> => {
    const hash = ContentstackLivePreview.hash
    const headers: Record<string, string> = {
      access_token: throwIfNull(siteConfig.NEXT_PUBLIC_CONTENTSTACK_DELIVERY_TOKEN, 'NEXT_PUBLIC_CONTENTSTACK_DELIVERY_TOKEN is a required environment variable.'),
      branch: throwIfNull(siteConfig.NEXT_PUBLIC_CONTENTSTACK_BRANCH, 'NEXT_PUBLIC_CONTENTSTACK_BRANCH is a required environment variable.')
    }

    if (hash != null && hash !== '') {
      headers.live_preview = hash
      headers.preview_token = throwIfNull(siteConfig.NEXT_PUBLIC_CONTENTSTACK_PREVIEW_TOKEN, 'NEXT_PUBLIC_CONTENTSTACK_PREVIEW_TOKEN is a required environment variable.')
    }

    return headers
  }

  /**
   * Gets the link to the Apollo client based on contentstack / contentstack live preview / normal graphql
   */
  getApolloLink = (context?: NextAnyContext): ApolloLink => {
    return split(
      operation => {
        return operation.getContext().contentstackLink === true
      },
      // Contentstack GraphQL link
      from([
        onError(this.contentstackErrorHandler),
        from([
          new ApolloLink((operation, forward) => {
            operation.query = contentstackDocumentTransform.transformDocument(operation.query)

            return forward(operation)
          }),
          new HttpLink(this.getContentstackHttpOptions(context))
        ])
      ]),
      // Stuller GraphQL link
      from([
        onError(this.errorHandler),
        new HttpLink(this.getHttpOptions(context))
      ])
    )
  }

  /**
   * Inits the Apollo client and state
   */
  initializeApollo = (context?: NextAnyContext, state?: any): ApolloClient<unknown> => {
    const _apolloClient = context?.apolloClient ?? apolloClient ?? this.createApolloClient(context)

    // If your page has Next.js data fetching methods that use Apollo Client, the initial state gets hydrated here
    if (state != null) {
      // Get existing cache, loaded during client-side data fetching
      const existingCache: any = _apolloClient.extract()

      // Merge the existing cache into data passed from getStaticProps/getServerSideProps
      /* eslint-disable-next-line @typescript-eslint/no-unsafe-argument */
      const data = merge(state, existingCache, {
        // Combine arrays using object equality (like in sets)
        arrayMerge: (destinationArray: any[], sourceArray: any[]) => [
          ...sourceArray,
          ...destinationArray.filter(d => sourceArray.every(s => !isEqual(d, s)))
        ]
      })

      // Restore the cache with the merged data
      _apolloClient.cache.restore(data)
    }

    // For SSG and SSR always create a new Apollo Client
    if (typeof window === 'undefined') {
      return _apolloClient
    }

    // Create the Apollo Client once in the client
    if (apolloClient == null) {
      apolloClient = _apolloClient
    }

    return _apolloClient
  }

  /**
   * Gets Apollo data, and adds cache to pageProps for getServerSideProps and getStaticProps
   */
  addApolloProp = (client: ApolloClient<unknown>, pageProps: any = { props: {} }): any => {
    if (pageProps.props == null) {
      pageProps.props = {}
    }

    pageProps.props.apolloState = client.cache.extract()

    return pageProps
  }

  /**
   * Get `StatusCodeProps` for redirect Apollo unauthenticated error and 500 error if there was some other apollo error
   */
  apolloHandleInitialError = async (error: any, context: CustomNextPageContext): Promise<StatusCodeProps> => {
    /**
     * Get redirect needed for getInitialProps back to login page
     */
    const getRedirect = (): StatusCodeProps => {
      return getUnauthorizedInitial(context)
    }

    if (error?.graphQLErrors != null) {
      for (const graphQLError of error?.graphQLErrors) {
        if (graphQLError?.extensions?.code === 'UNAUTHENTICATED') {
          return getRedirect()
        }
      }
    }

    if (error?.networkError != null) {
      console.error(`[Network error]: ${error?.networkError?.message}`, error?.networkError)
      if ('statusCode' in error?.networkError && error?.networkError.statusCode === 401) {
        return getRedirect()
      }
    }

    return { statusCode: 500 }
  }

  /**
   * Handles Apollo client init etc via hook
   */
  useApollo = (client: any, state?: any): ApolloClient<unknown> => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useMemo(() => client ?? this.initializeApollo(undefined, state), [client, state])
  }
}

// Init the client and export it's methods
const {
  initializeApollo,
  getApolloLink,
  addApolloProp,
  apolloHandleInitialError,
  useApollo
} = new Client()

export {
  initializeApollo,
  getApolloLink,
  addApolloProp,
  apolloHandleInitialError,
  useApollo,
  Client
}
