import { type ReactElement } from 'react'
import Link from 'next/link'
import { Title } from '@stuller/stullercom/feat/layout'
import { useAuth } from '@stuller/stullercom/feat/auth'
import { ErrorContact } from './ErrorContact'
import { ERROR_404_IMAGE } from '@stuller/shared/util/constants'

/**
 * 404 error page (no layout)
 */
function Error404 (): ReactElement {
  const { isShowcase } = useAuth()

  return (
    <div className='container-xxxl mw-xxl px-4 py-4'>
      <Title>Whoops! Page Not Found</Title>
      <div>
        <h2 className='text-center'>Whoops! (404 Error)</h2>
        <div className='row'>
          <div className='col-md-6'>
            <p>We're Sorry...</p>
            <p>
              The page you are looking for doesn't seem to exist.<br />
              You may be able to find the information you were looking for with one of these links, or try using the search bar.
            </p>

            <ul>
              <li><Link href='/'>Return to the Homepage</Link></li>
              <li><Link href='/browse'>Products</Link></li>
              {isShowcase && (
                <>
                  <li><Link href='/services'>Services</Link></li>
                  <li><Link href='/events'>Events</Link></li>
                  <li><Link href='/education'>Education</Link></li>
                </>)}
            </ul>

            <ErrorContact />
          </div>

          <div className='col-md-6 text-center d-none d-md-block'>
            <img className='img-fluid' src={ERROR_404_IMAGE} alt='Page Not Found' />
          </div>
        </div>
      </div>
    </div>
  )
}

export {
  Error404
}
