import { type AppContext } from 'next/app'
import { type GetAuth } from '@stuller/stullercom/feat/auth'

const STERLING_LOGIN_PATH = '/login/?ReturnUrl='

/**
 * Redirects to Sterling login page if user is not authenticated
 */
async function sterlingRedirect (appContext: AppContext, auth: GetAuth): Promise<boolean> {
  const { ctx, router } = appContext
  const { isSterling, authUser } = auth
  const returnPath = ctx.asPath

  // If isSterling & no auth cookie then redirect to login
  if (isSterling && authUser == null && returnPath != null) {
    const path = `${STERLING_LOGIN_PATH}${encodeURIComponent(returnPath)}`

    // If router isReady, check current pathname
    if (ctx.pathname !== '/login/') {
      if (ctx.res != null) {
        ctx.res.writeHead(302, { Location: path })
        ctx.res.end()
      } else {
        await router.push(path)
      }
    }

    return true
  }

  return false
}

export {
  sterlingRedirect
}
