import type { TargetingMap } from '@stuller/contentstack/util/types'
import { useAuth } from '@stuller/stullercom/feat/auth'
import { useTargetingPreviewer } from './targetingPreviewer'

/**
 * Hook to check if website is in showcase subdomain
 */
function useShowcaseSubdomainTargeting (targeting: TargetingMap['SHOWCASE_SUBDOMAIN']): boolean {
  const [targetingPreviewer] = useTargetingPreviewer()
  const { jewelerShowcaseDomainInfo } = useAuth()
  const subdomain = targetingPreviewer?.subdomain != null ? targetingPreviewer.subdomain : jewelerShowcaseDomainInfo?.subdomain

  return subdomain == null ? false : targeting.targetValue.includes(subdomain)
}

export { useShowcaseSubdomainTargeting }
