import { type ReactElement } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'
import { Alert, AlertHeading, AlertIcon } from '@stuller/stullercom/ui'

/**
 * The message for the messages popover when there are no messages
 */
function MessagesEmpty (): ReactElement {
  return (
    <Alert color='warning' transparent className='mb-0 pb-0'>
      <div className='row align-items-center justify-content-center'>
        <div className='col-auto'>
          <AlertIcon size='lg'>
            <FontAwesomeIcon icon={fas.faInbox} />
          </AlertIcon>
        </div>
        <div className='col-auto'>
          <AlertHeading tag='h3' className='mb-0'>
            No Messages
          </AlertHeading>
        </div>
      </div>
    </Alert>
  )
}

export {
  MessagesEmpty
}
