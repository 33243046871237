import { useMemo } from 'react'
import { useFetch, type UseFetch } from '@stuller/shared/util/react-hooks'
import { useAuth } from '@stuller/stullercom/feat/auth'
import type { RecommendationPlacementProps, RecommendationPlacementsResponse } from './types'
import { buildAlgonomyUrl, buildResponse } from './util'

interface RecommendationProps {
  /**
   * The group id represents the currently viewed group
   */
  groupId?: string
  /**
   * The placements to get recommendations for ( this is an Algonomy concept )
   */
  placements: RecommendationPlacementProps[]
}

/**
 * Uses the useFetch hook to fetch recommendations from the Algonomy API.
 */
function useProductRecommendations ({ groupId, placements }: RecommendationProps): UseFetch<RecommendationPlacementsResponse[] | null> {
  const auth = useAuth()
  const { authUser, sessionId } = auth

  const url = useMemo(() => buildAlgonomyUrl(placements, authUser?.user?.memberId, sessionId, { groupId }), [placements, groupId, authUser, sessionId])
  const results = useFetch(url)

  const recommendations = useMemo(() => buildResponse(results.data, placements), [results.data, placements])
  const previousRecommendations = useMemo(() => buildResponse(results.previousData, placements), [results.previousData, placements])

  return {
    ...results,
    data: recommendations,
    previousData: previousRecommendations
  }
}

export {
  useProductRecommendations
}
