import { type CSSProperties, forwardRef, type ReactNode } from 'react'
import clsx from 'clsx'
import { type ThemeColor } from '../../..'

export interface ZoneProps {
  /**
   * Id of element
   */
  id?: string
  /**
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /**
   * Children of the zone indicator
   */
  children?: ReactNode
  /**
   * Theme color for zone
   */
  color?: ThemeColor | 'consultant-mode'
  /**
   * Tooltip text to show on hover
   */
  tooltip?: string
  /**
   * Indicates to not render the zone (useful for conditional rendering the zone styling)
   */
  disabled?: boolean
}

/**
 * Zones are sections that are special and are used to highlight areas of the UI that require special attention.
 * They are a combination of background color and hover outline/tooltip.
 * Note that zones don't provide padding/margin etc and should be styled accordingly via `className`.
 *
 * Variables:
 * - `--bs-zone-bg`
 * - `--bs-zone-outline-color`
 * - `--bs-zone-tooltip`
 */
const Zone = forwardRef<HTMLDivElement, ZoneProps>(({
  className,
  children,
  color = 'secondary',
  tooltip,
  disabled = false,
  ...otherAttributes
}, ref) => {
  const classNames = clsx(
    !disabled && `zone zone-${color}`,
    className
  )
  const styles: CSSProperties = {
    '--bs-zone-tooltip': tooltip != null && tooltip.trim().length > 0 ? `"${tooltip}"` : undefined
  }

  return (
    <div
      className={classNames}
      style={styles}
      ref={ref}
      {...otherAttributes}
    >
      {children}
    </div>
  )
})
Zone.displayName = 'Zone'

export {
  Zone
}
