import { type ReactElement, memo } from 'react'
import { type UnsupportedBannerProps } from '@stuller/stullercom/feat/layout-context'
import { UnsupportedBrowserBanner } from './UnsupportedBrowserBanner'

/**
 * Banners for unsupported browsers/OSes
 */
const UnsupportedBanner = memo(({
  disabled = false
}: UnsupportedBannerProps): ReactElement | null => {
  if (disabled) {
    return null
  }

  return <UnsupportedBrowserBanner />
})
UnsupportedBanner.displayName = 'UnsupportedBanner'

export {
  UnsupportedBanner
}
