import { type CSSProperties, type ReactNode, forwardRef, useContext } from 'react'
import clsx from 'clsx'
import { DropdownContext, Popover } from '../../..'

interface DropdownMenuProps {
  /**
   * Id of element
   */
  id?: string
  /**
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /**
   * Inline styles to pass to the containing element
   */
  style?: CSSProperties
  /**
   * Children of element
   */
  children?: ReactNode
}

/**
 * Dropdown menu to display a list of links, etc.
 */
const DropdownMenu = forwardRef<HTMLUListElement, DropdownMenuProps>(({
  className,
  children,
  ...otherAttributes
}, ref) => {
  const {
    open,
    onOpenChange,
    reference,
    placement,
    offset,
    shift,
    hover,
    dismiss
  } = useContext(DropdownContext)
  const classNames = clsx('dropdown-menu', className)

  return (
    <Popover
      tag='ul'
      open={open}
      onOpenChange={onOpenChange}
      className={classNames}
      skipClassName
      reference={reference}
      placement={placement}
      offset={offset}
      flip={false}
      shift={shift}
      hover={hover}
      dismiss={dismiss}
      ref={ref}
      {...otherAttributes}
    >
      {children}
    </Popover>
  )
})
DropdownMenu.displayName = 'DropdownMenu'

export {
  DropdownMenu
}
