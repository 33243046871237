import { type ReactElement } from 'react'
import { useRouter } from 'next/router'
import { Button } from '@stuller/stullercom/ui'

/**
 * Shown when the user is in showcase mode (turn off button)
 */
function ShowcaseMode (): ReactElement {
  const { asPath } = useRouter()

  return (
    <Button
      href={`/login?returnUrl=${encodeURIComponent(asPath)}`}
      tag='a'
      color='primary'
      className='w-100'
      pill
    >
      Turn Off Showcase Mode
    </Button>
  )
}

export {
  ShowcaseMode
}
