import {
  forwardRef,
  type ReactNode,
  type CSSProperties,
  type HTMLAttributes
} from 'react'
import clsx from 'clsx'

export interface InputGroupTextProps {
  /**
   * Id of element
   */
  id?: string
  /**
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /**
   * Input group element children
   */
  children?: ReactNode
  /**
   * Inline styles to pass to the containing element
   */
  style?: CSSProperties
  /**
   * Optional onClick event
   */
  onClick?: HTMLAttributes<HTMLDivElement>['onClick']
}

/**
 * Input group text wrapper that can be used to display text, checkboxes, and radios in input groups.
 */
const InputGroupText = forwardRef<HTMLInputElement, InputGroupTextProps>(({
  className,
  children,
  ...otherAttributes
}, ref) => {
  const classNames = clsx('input-group-text', className)

  return (
    <div
      className={classNames}
      ref={ref}
      {...otherAttributes}
    >
      {children}
    </div>
  )
})
InputGroupText.displayName = 'InputGroupText'

export {
  InputGroupText
}
