import { type ReactElement, type ReactNode } from 'react'
import clsx from 'clsx'

interface FormFeedbackProps {
  /**
   * Id of element
   */
  id?: string
  /**
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /**
   * Error to show
   */
  children?: ReactNode
  /**
   * Indicates if the feedback is valid or invalid feedback
   */
  valid?: boolean
}

/**
 * Form feedback component for form validation errors.
 */
function FormFeedback ({
  className,
  children,
  valid = false,
  ...otherAttributes
}: FormFeedbackProps): ReactElement | null {
  const classNames = clsx(
    valid ? 'valid-feedback' : 'invalid-feedback',
    className
  )

  return (
    <div
      className={classNames}
      {...otherAttributes}
    >
      {children}
    </div>
  )
}

export {
  FormFeedback
}
