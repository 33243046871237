import { useCallback, useState } from 'react'
import { useInterval } from './useInterval'
import { dayjs } from '@stuller/shared/util/core'

type Duration = ReturnType<typeof dayjs.duration>

/**
 * Calculates the duration between the current time and a future date
 */
function getDuration (futureDate: dayjs.Dayjs): Duration {
  const duration = dayjs.duration(futureDate.diff(dayjs.utc()))

  if (duration.asMilliseconds() <= 0) {
    return dayjs.duration(0)
  }

  return duration
}

/**
 * Calculates the duration between the current UTC time and a future date and updates it every `delay` milliseconds
 * Use `useMemo` to memoize the `futureDate` to avoid unnecessary re-renders
 */
function useDurationCountdown (futureDate: dayjs.Dayjs, delay: number | null): Duration {
  const [duration, setDuration] = useState<Duration>(getDuration(futureDate))

  const callback = useCallback(() => {
    setDuration(getDuration(futureDate))
  }, [futureDate])

  useInterval(callback, duration.asMilliseconds() > 0 ? delay : null)

  return duration
}

export {
  useDurationCountdown
}
