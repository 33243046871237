import { v4 as uuidv4 } from 'uuid'
import { parse as cookieParse, serialize as cookieSerialize, type CookieSerializeOptions } from 'cookie'
import Cookies from 'js-cookie'
import { type NextPageContext } from 'next'
import { throwIfNull } from '@stuller/shared/util/core'

const SESSION_COOKIE_NAME = 'user_session_id'

/**
 * Gets the cookie options regardless of client/server-side
 */
function getCookieOptions (): CookieSerializeOptions & Cookies.CookieAttributes {
  const expires = new Date()
  expires.setMinutes(expires.getMinutes() + 30)

  return {
    expires,
    path: '/',
    secure: true,
    sameSite: 'none'
  }
}

/**
 * Method to be used on the server-side to generate/update the session id value & cookie
 */
function getServerSideSessionId ({ req, res }: NextPageContext): string {
  req = throwIfNull(req, 'Request cannot be null')
  res = throwIfNull(res, 'Response cannot be null')

  const cookies = cookieParse(req.headers.cookie ?? '')
  const sessionId = cookies[SESSION_COOKIE_NAME] ?? uuidv4()
  let currentRespCookies = res.getHeader('Set-Cookie')
  if (!Array.isArray(currentRespCookies)) {
    currentRespCookies = currentRespCookies == null ? [] : [String(currentRespCookies)]
  }
  currentRespCookies.push(cookieSerialize(SESSION_COOKIE_NAME, sessionId, getCookieOptions()))
  res.setHeader('Set-Cookie', currentRespCookies)

  return sessionId
}

/**
 * Method to be used on the client-side to generate/update the session id value & cookie
 */
function getClientSideSessionId (): string {
  const sessionId = Cookies.get(SESSION_COOKIE_NAME) ?? uuidv4()
  Cookies.set(SESSION_COOKIE_NAME, sessionId, getCookieOptions())

  return sessionId
}

/**
 * Returns an existing session id or creates a new one
 * Updates the session id cookie's expiration to be + 30 mins
 * Can be called from the client or server-side
 */
function getSessionId (ctx: NextPageContext): string {
  if (ctx.req != null) {
    return getServerSideSessionId(ctx)
  }

  return getClientSideSessionId()
}

export {
  getSessionId
}
