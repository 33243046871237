import type { TargetingMap } from '@stuller/contentstack/util/types'
import { useAuth } from '@stuller/stullercom/feat/auth'
import { useTargetingPreviewer } from './targetingPreviewer'

/**
 * Hook to check if web user has a specific bill to account number
 */
function useBillToAccountTargeting (targeting: TargetingMap['BILL_TO_ACCOUNT']): boolean {
  const [targetingPreviewer] = useTargetingPreviewer()
  const { authUser } = useAuth()
  const billToId = targetingPreviewer?.billToAccount != null ? targetingPreviewer?.billToAccount : authUser?.user?.defaultActiveShipToAccount?.billToAccount?.id

  return billToId == null ? false : targeting.targetValue.includes(billToId)
}

export { useBillToAccountTargeting }
