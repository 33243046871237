import { type ReactElement, useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Alert, AlertHeading, AlertIcon } from '@stuller/stullercom/ui'
import { MenuCartContext } from './MenuCartContext'

/**
 * The message for the cart popover when there are no cart lines
 */
function CartEmpty (): ReactElement {
  const { title, icon } = useContext(MenuCartContext)

  return (
    <Alert color='success' transparent className='mb-0 py-0'>
      <div className='row align-items-center justify-content-center'>
        <div className='col-auto'>
          <AlertIcon size='lg'>
            <FontAwesomeIcon icon={icon} />
          </AlertIcon>
        </div>
        <div className='col-auto'>
          <AlertHeading tag='h3' className='mb-0' data-test='cart-empty-text'>
            Your {title} is empty
          </AlertHeading>
        </div>
      </div>
    </Alert>
  )
}

export {
  CartEmpty
}
