/**
 * Given a string, return true if the value is null | undefined | empty string otherwise return false
 */
function isStringEmpty (value: string | null | undefined): value is null | undefined {
  if (value == null || value.trim() === '') {
    return true
  }

  return false
}

export {
  isStringEmpty
}
