import type { TargetingMap } from '@stuller/contentstack/util/types'
import { useAuth } from '@stuller/stullercom/feat/auth'
import { useTargetingPreviewer } from './targetingPreviewer'

/**
 * Hook to check if user is an ever&ever member or not
 */
function useEverAndEverMemberTargeting (targeting: TargetingMap['EVER_AND_EVER_MEMBER']): boolean {
  const [targetingPreviewer] = useTargetingPreviewer()
  const auth = useAuth()
  const { authUser } = auth

  const isEverAndEverMember = targetingPreviewer?.isEverandEverMember != null ? targetingPreviewer.isEverandEverMember : authUser?.user?.isEverAndEverMember ?? false

  return targeting.targetValue ? isEverAndEverMember : !isEverAndEverMember
}

export { useEverAndEverMemberTargeting }
