import { type ReactElement, memo } from 'react'
import { useAuth } from '@stuller/stullercom/feat/auth'
import { Menu } from './Menu'
import { Info } from './Info'
import { UtilityNavSterling } from './UtilityNavSterling'

/**
 * Stuller.com utility nav
 */
function UtilityNavStullerCom (): ReactElement {
  return (
    <header className='bg-white m-0 d-print-none' data-gtm-click-section='utility-nav'>
      <div className='container-xxxl'>
        <div className='row align-items-center'>
          <div className='col d-none d-sm-flex justify-content-start ps-0'>
            <Info />
          </div>
          <div className='col col-sm-auto ps-0 pe-0 ps-md-3 h-100'>
            <Menu />
          </div>
        </div>
      </div>
    </header>
  )
}

/**
 * Utility nav: contact info, menu (showcase, messages, cart, account, etc.) and logo on mobile
 */
const UtilityNav = memo((): ReactElement | null => {
  const { isJewelerShowcase, isSterling, showcaseSettings } = useAuth()

  if (isSterling) {
    return <UtilityNavSterling />
  }

  // The layout should not be shown when on an invalid showcase
  if (isJewelerShowcase && showcaseSettings == null) {
    return null
  }

  return <UtilityNavStullerCom />
})
UtilityNav.displayName = 'UtilityNav'

export {
  UtilityNav
}
