import { type ReactElement, type ReactNode } from 'react'
import clsx from 'clsx'

interface FormTextProps {
  /**
   * Id of element
   */
  id?: string
  /**
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /**
   * Error to show
   */
  children?: ReactNode
  /**
   * Defined element type
   */
  tag?: 'div' | 'span'
}

/**
 * Block-level or inline-level form text.
 */
function FormText ({
  className,
  children,
  tag: Tag = 'div',
  ...otherAttributes
}: FormTextProps): ReactElement {
  const classNames = clsx('form-text', className)

  return (
    <Tag
      className={classNames}
      {...otherAttributes}
    >
      {children}
    </Tag>
  )
}

export {
  FormText
}
