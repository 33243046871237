import { type ReactElement } from 'react'
import { useAuth } from '@stuller/stullercom/feat/auth'
import { ShowcasePromotionBanner } from './ShowcasePromotionBanner'
import { ShowcaseMarketRates } from './ShowcaseMarketRates'

function ShowcaseFooter (): ReactElement | null {
  const { isJewelerShowcase, isShowcase, showcaseSettings } = useAuth()

  if (!isShowcase) {
    return null
  }

  // The layout should not be shown when on an invalid showcase
  if (isJewelerShowcase && showcaseSettings == null) {
    return null
  }

  return (
    <div
      className='bg-gray-100 border-top d-print-none'
      style={{
        '--bs-border-color': `var(--bs-${showcaseSettings?.backgroundColorClass ?? ''}, var(--bs-gray-100))`
      }}
    >
      <ShowcasePromotionBanner />
      <ShowcaseMarketRates />
    </div>
  )
}

export {
  ShowcaseFooter
}
