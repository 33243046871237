import { type PluginFunc } from 'dayjs'

declare module 'dayjs' {
  interface Dayjs {
    /**
     * Provides either the timepart of the .
     * ```
     * Assuming today is 2019-01-26T12:00:00.0000
     * dayjs('2019-01-26T13:15:00.0000').toShortTimeFromNowString() => '21h'
     * dayjs('2019-01-25T09:15:00.0000').toShortTimeFromNowString() => '1d'
     * dayjs('2019-01-19T09:15:00.0000').toShortTimeFromNowString() => '1w'
     * ```
     * Docs: https://day.js.org/docs/en/display/from-now
     */
    toShortTimeFromNowString: () => string
    /**
     * To format as an ISO 8601 string without the time component.
     * ```
     * dayjs('2019-01-25').toISODateString() // '2019-01-25'
     * ```
     * Docs: https://day.js.org/docs/en/display/as-iso-string
     */
    toISODateString: () => string
  }
}

/**
 * Add a method to get the ISO date string without the time component
 */
const plugin: PluginFunc = (option, Dayjs, dayjs) => {
  Dayjs.prototype.toShortTimeFromNowString = function () {
    const date = dayjs(this)

    return date.isToday() ? date.format('LT') : date.fromNow(true)
  }
  Dayjs.prototype.toISODateString = function () {
    return this.toDate().toISOString().split('T')[0]
  }
}

export default plugin
