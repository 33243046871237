import { type ReactElement } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'
import { siteConfig } from '@stuller/stullercom/util/site-config'

/**
 * Shows the environment alert
 */
function EnvironmentAlert (): ReactElement {
  return (
    <div className='text-danger fw-bold'>
      <FontAwesomeIcon icon={fas.faTriangleExclamation} className='pe-2' />
      {siteConfig.NEXT_PUBLIC_ENVIRONMENT_ALERT}
    </div>
  )
}

export {
  EnvironmentAlert
}
