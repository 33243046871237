import { type ReactElement } from 'react'
import type { SerializedDiamond } from '../types'

interface SerializedDiamondSpecialRequestProps {
  /**
   * The serialized diamond to display
   */
  serializedDiamond: SerializedDiamond
}

function SerializedDiamondSpecialRequest ({ serializedDiamond }: SerializedDiamondSpecialRequestProps): ReactElement {
  return (
    <p>SerializedDiamondSpecialRequest placeholder</p>
  )
}

export {
  SerializedDiamondSpecialRequest
}
