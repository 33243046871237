import { components } from 'react-select'
import { SelectContainer } from './SelectContainer'
import { Input } from './SelectInput'

/**
 * Select components with overrides and alternatives
 */
const selectDropdownComponents = {
  ...components,
  SelectContainer,
  Input
}

export {
  selectDropdownComponents
}
