import { type ChangeEvent, type FormEvent, type ReactElement, useState, useContext } from 'react'
import { type CartLine } from './types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'
import clsx from 'clsx'
import { Button, Card, Input, Loading, Form } from '@stuller/stullercom/ui'
import {
  useSaveCartLineForLaterMutation,
  useUpdateCartLineQuantityMutation,
  useDeleteCartLineMutation
} from '@stuller/stullercom/data-access/apollo-queries'
import { useAuth } from '@stuller/stullercom/feat/auth'
import { CartCardAlert } from './CartCardAlert'
import { useCurrency } from '@stuller/shared/util/react-hooks'
import { trackAddToCart, trackRemoveFromCart } from '@stuller/stullercom/feat/google-tag-manager'
import { MenuCartContext } from './MenuCartContext'
import { type Reference, type StoreObject } from '@apollo/client/cache/inmemory/types'
import { logger } from '@stuller/stullercom/feat/datadog-logs'
import { getClientValidationErrors } from '@stuller/stullercom/data-access/apollo-client'
import { type CartLineMessageFragment } from '@stuller/stullercom/data-access/apollo-queries'

interface SaveForLaterButtonProps {
  onClick: () => Promise<any>
}

interface RemoveButtonProps {
  onClick: () => Promise<any>
}

/**
 * Displays link to remove the current line from the cart and save for later
 */
function SaveForLaterButton ({ onClick }: SaveForLaterButtonProps): ReactElement {
  return (
    <Button color='anchor' onClick={onClick} className='text-decoration-none' data-test='save-for-later-button'>
      <FontAwesomeIcon className='mx-2 fs-6' icon={fas.faPlus} />
      <span className='fs-7'>SAVE FOR LATER</span>
    </Button>
  )
}

/**
 * Displays link to remove the current line from the cart
 */
function RemoveButton ({ onClick }: RemoveButtonProps): ReactElement {
  return (
    <Button color='anchor' onClick={onClick} className='text-decoration-none' data-test='remove-items-button'>
      <FontAwesomeIcon className='mx-2 fs-6' icon={fas.faTrash} />
      <span className='fs-7'>REMOVE</span>
    </Button>
  )
}

interface CartCardProps {
  cartLine: CartLine
}

/**
 * Gets the cart line's details url and short description
 */
function getDetailsUrlAndSku (cartLine: CartLine): { itemDetailsUrl: string, shortDescription: string } {
  const shortDescription = cartLine.item.itemNumber
  let itemDetailsUrl = `/products/details?recommendationSource=Cart&gid=${encodeURIComponent(cartLine.productGroup?.id ?? '')}&`
  if (cartLine.item.__typename === 'Product') {
    itemDetailsUrl += `iid=${encodeURIComponent(cartLine.item.id)}`
  } else if (cartLine.item.__typename === 'SerializedProduct') {
    itemDetailsUrl += `serialNumber=${encodeURIComponent(cartLine.item.id)}`
  } else if (cartLine.item.__typename === 'Configuration' && cartLine.item.configurationProduct != null) {
    itemDetailsUrl += `configId=${encodeURIComponent(cartLine.item.id)}&iid=${encodeURIComponent(cartLine.item.configurationProduct.id)}`
  }

  return { itemDetailsUrl, shortDescription }
}

/**
 * Gets the cart line's image
 */
function getImageUrl (cartLine: CartLine): string | null {
  if (cartLine.item.image != null) {
    return `${cartLine.item.image.replace('$standard$', '$100$')}&sharpen`
  }

  return null
}

/**
 * The card to show an individual cart item
 */
function CartCard ({ cartLine }: CartCardProps): ReactElement {
  const auth = useAuth()
  const { isJewelerShowcase, authUser, showcaseSettings } = auth
  const { hasScheduledOrder } = useContext(MenuCartContext)

  const [cartItemQuantity, setCartItemQuantity] = useState(cartLine.options.quantity)
  const [isQuantityInputValid, setIsQuantityInputValid] = useState(true)
  const [lastSubmittedQuantity, setLastSubmittedQuantity] = useState(cartLine.options.quantity)
  const [quantityErrors, setQuantityErrors] = useState<CartLineMessageFragment[]>([])
  const [cartItemErrors, setCartItemErrors] = useState<CartLineMessageFragment[]>(cartLine.errors)

  const errors = [...quantityErrors ?? [], ...cartItemErrors ?? []]
  const hideUSDPrices = showcaseSettings?.hideUSDPrices ?? false
  const allowSaveForLater = !isJewelerShowcase && authUser != null
  const classNames = clsx('p-3 mb-2 rounded-3 bg-white w-100', cartItemErrors.length > 0 ? 'border border-3 border-danger' : 'border-0')

  const imageUrl = getImageUrl(cartLine)
  const { itemDetailsUrl, shortDescription } = getDetailsUrlAndSku(cartLine)

  const [saveForLater, { loading: saveForLaterLoading }] = useSaveCartLineForLaterMutation({
    variables: { cartLineId: cartLine.id },
    onError: (error) => {
      logger.error(`Could not save cart line ${cartLine.id} for later`, { cartLineId: cartLine.id }, error)
      const validationErrorMessages = getClientValidationErrors(error)
      const validationErrors = validationErrorMessages.length > 0 ? validationErrorMessages.map(message => ({ code: 'CART_ERROR', message })) : [{ code: 'CART_ERROR', message: 'There was an issue saving this item' }]
      setCartItemErrors([...validationErrors, ...cartLine.errors])
    },
    update: (cache, { data }) => {
      if (data?.saveCartLineForLater?.cart?.id != null) {
        cache.modify({
          id: 'ROOT_QUERY',
          fields: {
            cartItemCount () {
              return data?.saveCartLineForLater?.cart?.itemCount ?? 0
            }
          }
        })
        trackRemoveFromCart(cartLine, auth)
      }
    },
    refetchQueries: ['GetSavedCartLines']
  })

  const [updateQuantity, { loading: updateQuantityLoading }] = useUpdateCartLineQuantityMutation({
    onError: (error) => {
      logger.error(`Could not update quantity for cart line ${cartLine.id}`, { cartLineId: cartLine.id }, error)
      const validationErrorMessages = getClientValidationErrors(error)
      const validationErrors = validationErrorMessages.length > 0 ? validationErrorMessages.map(message => ({ code: 'CART_ERROR', message })) : [{ code: 'CART_ERROR', message: 'There was an issue changing the quantity for this item' }]
      setQuantityErrors([...validationErrors, ...cartLine.errors])
    },
    update: (cache, { data }) => {
      if (data?.updateCartLineQuantity?.cart?.id != null) {
        cache.modify({
          id: 'ROOT_QUERY',
          fields: {
            items (existingCartLineRefs, { readField }) {
              return cartLine.options.quantity === 0
                ? existingCartLineRefs.filter((cartLineRef: StoreObject | Reference) => cartLine.id !== readField('id', cartLineRef))
                : existingCartLineRefs
            }
          }
        })
      }
    }
  })

  function handleOnChangeQuantity (event: ChangeEvent<HTMLInputElement>): void {
    setCartItemQuantity(event.target.valueAsNumber)
  }

  function handleOnBlurQuantity (event: FormEvent<HTMLFormElement>): void {
    if (lastSubmittedQuantity !== cartItemQuantity) {
      handleOnSubmitQuantity(event).catch(reason => {
        logger.error('Unexpected error on quantity blur.', {}, reason)
      })
    }
  }

  async function handleOnSubmitQuantity (event: FormEvent<HTMLFormElement>): Promise<void> {
    const currentQuantity = cartItemQuantity
    event.preventDefault()
    setQuantityErrors([])

    if (currentQuantity !== 0) {
      if ((cartLine.item.minimumQuantity != null && cartLine.item.minimumQuantity > currentQuantity) ||
        (cartLine.item.maximumQuantity != null && cartLine.item.maximumQuantity < currentQuantity)) {
        setIsQuantityInputValid(false)

        return
      }
    }
    setIsQuantityInputValid(true)

    try {
      const response = await updateQuantity({
        variables: {
          input: {
            id: cartLine.id,
            quantity: currentQuantity
          }
        }
      })
      if (response.errors != null) {
        // If there were expected errors from the Apollo client, set it back to the initial value
        setIsQuantityInputValid(false)
      } else {
        if (lastSubmittedQuantity < currentQuantity) {
          trackAddToCart(cartLine, auth, currentQuantity - lastSubmittedQuantity)
        }
        if (lastSubmittedQuantity > currentQuantity) {
          trackRemoveFromCart(cartLine, auth, lastSubmittedQuantity - currentQuantity)
        }
        setLastSubmittedQuantity(currentQuantity)
      }
    } catch (error: any) {
      // Some unexpected error happened, set it back to the initial value
      logger.error(`Failed to update quantity of cart item: ${cartLine.id}`, {}, error)
      setIsQuantityInputValid(false)
    }
  }

  const [remove, { loading: removeLoading }] = useDeleteCartLineMutation({
    variables: { input: { id: cartLine.id } },
    onError: (error) => {
      logger.error(`Could not remove cart line ${cartLine.id}`, { cartLineId: cartLine.id }, error)
      const validationErrorMessages = getClientValidationErrors(error)
      const validationErrors = validationErrorMessages.length > 0 ? validationErrorMessages.map(message => ({ code: 'CART_ERROR', message })) : [{ code: 'CART_ERROR', message: 'There was an issue deleting this item' }]
      setCartItemErrors([...validationErrors, ...cartLine.errors])
    },
    update: (cache, { data }) => {
      if (data?.deleteCartLine?.cart?.id != null) {
        cache.modify({
          id: 'ROOT_QUERY',
          fields: {
            cartItemCount () {
              return data?.deleteCartLine?.cart?.itemCount ?? 0
            }
          }
        })
        trackRemoveFromCart(cartLine, auth)
      }
    }
  })

  const priceDecimal = cartLine.price?.total.value
  const isForConsignment = cartLine.options.isForConsignment
  const [price] = useCurrency(priceDecimal, cartLine.price?.total.exchangeRate)
  const usdDecimalPrice = cartLine.price?.total.valueInUsd
  const [usdPrice] = useCurrency(usdDecimalPrice)
  const description = cartLine.item.title
  const currencyCode = cartLine.price?.total.exchangeRate.code.toUpperCase() ?? 'USD'
  const showUsdConversionPrice = (currencyCode !== 'USD' && usdDecimalPrice != null && !hideUSDPrices)

  const loading = saveForLaterLoading || updateQuantityLoading || removeLoading

  return (
    <Card className={classNames} data-test='cart-dropdown-products'>
      <Loading loading={loading}>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-2 p-0'>
              {imageUrl != null && (
                <a href={itemDetailsUrl}>
                  <img className='img-fluid' alt={cartLine.item.title} src={imageUrl} />
                </a>
              )}
            </div>
            <div className='col-6'>
              <span className='d-block text-cyan-400 fw-bold fs-6' data-test='product-sku'>{shortDescription}</span>
              <a
                className='fw-normal text-wrap link-dark' style={{ textDecoration: 'inherit' }}
                href={itemDetailsUrl}
              >
                {description}
              </a>
            </div>
            <div className='col-4 p-0'>
              {priceDecimal != null && (
                <div className='mb-2'>
                  <div className='d-flex justify-content-end fw-bold fs-4 text-gray-700' data-test='usd-price'>
                    {isForConsignment ? 'For Review' : price}
                  </div>

                  {showUsdConversionPrice && !isForConsignment && (
                    <div className='d-flex justify-content-end align-items-baseline fw-normal fst-italic fs-6 mt-0' data-test='international-price'>
                      {usdPrice}<span className='fs-7 ms-1'> (USD)</span>
                    </div>)}
                </div>
              )}

              <div className='d-flex align-items-center justify-content-end'>
                <div className='me-2 mt-1 text-gray-800 fw-bold fs-6'>QTY</div>
                <div>
                  {((cartLine.item.maximumQuantity == null || cartLine.item.maximumQuantity > 1) && !hasScheduledOrder)
                    ? (
                      <Form onSubmit={handleOnSubmitQuantity} onBlur={handleOnBlurQuantity}>
                        <Input
                          className='text-end p-2 text-gray-700'
                          style={{ width: 50 }}
                          type='number'
                          maxLength={5}
                          value={cartItemQuantity}
                          invalid={!isQuantityInputValid}
                          onChange={handleOnChangeQuantity}
                          data-test='item-quantity'
                        />
                      </Form>)
                    : <div className='fs-4 text-gray-800 fw-medium' data-test='quantity'>{cartItemQuantity}</div>}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='container'>
          <div className='row pt-3 align-items-center'>
            <div className='col-3'>
              <RemoveButton onClick={remove} />
            </div>

            <div className='col-6'>
              {allowSaveForLater && <SaveForLaterButton onClick={saveForLater} />}
            </div>

            <div className='col-3 px-0'>
              <div className='d-flex justify-content-end'>
                {errors.length > 0 && <CartCardAlert messages={errors} type='error' />}
                {cartLine.notes.length > 0 && <CartCardAlert messages={cartLine.notes} type='note' />}
              </div>
            </div>
          </div>
        </div>
      </Loading>
    </Card>
  )
}

export {
  CartCard
}
