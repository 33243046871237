import { type ReactElement } from 'react'
import Link from 'next/link'
import {
  STULLER_LOGO_MOBILE,
  STULLER_LOGO_DESKTOP
} from '@stuller/shared/util/constants'
import variables from '@stuller/stullercom/ui/styles/variables.module.scss'
import { useAuth } from '@stuller/stullercom/feat/auth'

/**
 * Showcase desktop logo or title
 */
function ShowcaseDesktopLogoOrTitle (): ReactElement | null {
  const { showcaseSettings } = useAuth()

  if (showcaseSettings == null) {
    return null
  }

  return (
    <div className='d-none d-sm-block d-lg-none w-100'>
      <Link href='/' className='link-dark text-decoration-none' data-gtm-click-text='Home'>
        {showcaseSettings.logo != null
          ? (
            <img
              className='img-fluid ps-3'
              alt='Logo'
              style={{ maxHeight: 65 }}
              src={showcaseSettings.logo}
            />)
          : (
            <div className='px-4 py-3 py-md-0 lh-1 fs-2 fw-medium'>
              {showcaseSettings.title ?? ''}
            </div>)}
      </Link>
    </div>
  )
}

/**
 * Showcase mobile logo or title
 */
function ShowcaseMobileLogoOrTitle (): ReactElement | null {
  const { showcaseSettings, isEmbeddedJewelerShowcase } = useAuth()

  if (showcaseSettings == null) {
    return null
  }

  return (
    <li
      className='d-sm-none text-center flex-fill flex-sm-grow-0 position-relative p-0'
    >
      {!isEmbeddedJewelerShowcase && (
        <Link href='/' className='d-block link-dark text-decoration-none' data-gtm-click-text='Home'>
          {showcaseSettings.logo != null
            ? (
              <img
                className='img-fluid px-4'
                alt='Logo'
                style={{ maxHeight: 65 }}
                src={showcaseSettings.logo}
              />)
            : (
              <div className='px-4 py-3 py-md-0 lh-1 fs-2 fw-medium'>
                {showcaseSettings.title ?? ''}
              </div>)}
        </Link>)}
    </li>
  )
}

/**
 * Stuller desktop logo for utility nav
 */
function StullerDesktopLogo (): ReactElement | null {
  return (
    <div className='d-none d-sm-block d-lg-none ps-4 w-100'>
      <Link href='/' data-gtm-click-text='Home'>
        <img
          src={STULLER_LOGO_DESKTOP}
          data-fallback='https://meteor.stullercloud.com/das/75581463?wid=270&fmt=smart-alpha'
          alt='Stuller'
          className='img-fluid w-100'
          style={{ maxWidth: 150 }}
        />
      </Link>
    </div>
  )
}

/**
 * Stuller mobile logo for utility nav
 */
function StullerMobileLogo (): ReactElement | null {
  return (
    <>
      <li
        className='d-sm-none text-center flex-fill flex-sm-grow-0 position-relative p-0'
      >
        <Link href='/' className='d-block py-2 px-4' data-gtm-click-text='Home'>
          <img
            id='header-icon-logo'
            src={STULLER_LOGO_MOBILE}
            data-fallback='//meteor.stullercloud.com/das/75581463?wid=270&amp;fmt=smart-alpha'
            alt='Stuller'
            style={{ maxWidth: 20 }}
          />
        </Link>
      </li>
      <style jsx>{`
        @media (min-width: ${variables.breakpointSm}) {
          li {
            min-width: 95px;
          }
        }
      `}
      </style>
    </>
  )
}

export {
  StullerDesktopLogo,
  StullerMobileLogo,
  ShowcaseDesktopLogoOrTitle,
  ShowcaseMobileLogoOrTitle
}
