import type { TargetingMap } from '@stuller/contentstack/util/types'
import { useAuth } from '@stuller/stullercom/feat/auth'
import { useTargetingPreviewer } from './targetingPreviewer'

/**
 * Hook to check if current user is enrolled in Stuller First
 */
function useStullerFirstTargeting (targeting: TargetingMap['STULLER_FIRST']): boolean {
  const auth = useAuth()
  const [targetingPreviewer] = useTargetingPreviewer()
  const { authUser } = auth
  const isStullerFirstEnrolled = targetingPreviewer?.isStullerFirstEnrolled != null ? targetingPreviewer.isStullerFirstEnrolled : authUser?.user?.isStullerFirstEnrolled ?? false

  return targeting.targetValue ? isStullerFirstEnrolled : !isStullerFirstEnrolled
}

export { useStullerFirstTargeting }
