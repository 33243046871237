import { type RefObject, useCallback, useEffect, useState } from 'react'
import { useEventListener, useResizeObserver } from '..'

export type ScrollOrientation = 'horizontal' | 'vertical'

interface UseScrollState {
  /**
   * Indicates that the scroll container is at the start of its scrollable area
   */
  atStart: boolean
  /**
   * Indicates that the scroll container is at the end of its scrollable area
   */
  atEnd: boolean
  /**
   * Indicates that the scroll container has overflow
   */
  hasOverflow: boolean
}

interface UseScrollStateOptions {
  /**
   * The scroll container orientation
   */
  orientation: ScrollOrientation
}

/**
 * Hook to control shadows used to indicate an overflow of content in a container
 */
function useScrollState (
  scrollContainer?: RefObject<HTMLElement>,
  options: UseScrollStateOptions = { orientation: 'vertical' }
): UseScrollState {
  const [atStart, setAtStart] = useState(true)
  const [atEnd, setAtEnd] = useState(true)
  const [hasOverflow, setHasOverflow] = useState(false)
  const { orientation } = options

  const setScrollState = useCallback(() => {
    if (scrollContainer?.current != null) {
      switch (orientation) {
        case 'vertical': {
          const scrollTop = scrollContainer.current.scrollTop
          const scrollHeight = scrollContainer.current.scrollHeight - scrollContainer.current.clientHeight
          const overflowing = scrollContainer.current.scrollHeight > scrollContainer.current.clientHeight
          setAtStart(!overflowing || scrollTop === 0)
          setAtEnd(!overflowing || (scrollTop >= scrollHeight && scrollTop !== 0))
          setHasOverflow(overflowing)
          break
        }
        case 'horizontal': {
          const scrollLeft = scrollContainer.current.scrollLeft
          const scrollWidth = scrollContainer.current.scrollWidth - scrollContainer.current.clientWidth
          const overflowing = scrollContainer.current.scrollWidth > scrollContainer.current.clientWidth
          setAtStart(!overflowing || scrollLeft <= 0)
          setAtEnd(!overflowing || (Math.ceil(scrollLeft) >= scrollWidth && scrollLeft !== 0))
          setHasOverflow(overflowing)
          break
        }
      }
    }
  }, [scrollContainer, orientation])

  useEventListener('scroll', setScrollState, scrollContainer)
  useResizeObserver(setScrollState, scrollContainer)

  useEffect(() => {
    setScrollState()
  }, [setScrollState])

  return { atStart, atEnd, hasOverflow }
}

export {
  useScrollState
}
