import { memo, useState, type ReactElement } from 'react'
import { type BarcodeInputProps } from '@stuller/stullercom/feat/layout-context'
import { useEventListener } from '@stuller/shared/util/react-hooks'

const BARCODE_PREFIX = 'show.'
const BARCODE_SUFFIX_KEY = '|'
const BARCODE_SUFFIX_KEY_ALT = '\\'
const BARCODE_SUFFIX_CODE = 220

/**
 * Validate key down event code
 */
function isValidEntryKey (keyCode: number): boolean {
  // Is the entry within the acceptable range of numbers, letters, symbols (but not backslash or pipe since that is our "end symbol")
  return ((keyCode >= 48 && keyCode <= 90) ||
          (keyCode >= 96 && keyCode <= 111) ||
          (keyCode >= 186 && keyCode <= 222))
}

/**
 * Barcode input listener component
 */
function BarcodeInputListener (): null {
  const [capture, setCapture] = useState('')

  /**
   * Post barcode capture to search page
   */
  function postCapture (): void {
    const sanitizedCapture = capture.replace(BARCODE_PREFIX, '').replace(BARCODE_SUFFIX_KEY, '').replace(BARCODE_SUFFIX_KEY_ALT, '')
    // The "end symbol" has been entered, so send user to search page
    window.location.href = `/search/results?query=${sanitizedCapture}`
  }

  /**
   * Handle key down event
   */
  function captureKeyDown (key: string, keyCode: number): void {
    if (isValidEntryKey(keyCode)) {
      setCapture(capture + key)
      // Validate format of captured barcode and send user to search page
      if (capture.substring(0, 5) === BARCODE_PREFIX && keyCode === BARCODE_SUFFIX_CODE) {
        postCapture()
      }
    }
  }

  useEventListener('keydown', (event: Event) => {
    const { key, keyCode } = event as KeyboardEvent
    captureKeyDown(key, keyCode)
  })

  return null
}

/**
 * Barcode input component that listens for barcode input
 */
const BarcodeInput = memo(({
  disabled = false
}: BarcodeInputProps): ReactElement | null => {
  if (disabled) {
    return null
  }

  return <BarcodeInputListener />
})
BarcodeInput.displayName = 'BarcodeInput'

export {
  BarcodeInput
}
