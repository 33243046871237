import App, { type AppContext } from 'next/app'
import { initializeApollo } from '@stuller/stullercom/data-access/apollo-client'
import { type CustomAppProps } from './types'
import { getSessionId } from './getSessionId'
import { getAuth } from './getAuth'
import { refreshAuthUserRedirect } from './refreshAuthUserRedirect'
import { resetPasswordRedirect } from './resetPasswordRedirect'
import { sterlingRedirect } from './sterlingRedirect'
import { authenticatedShowcaseRedirect } from './authenticatedShowcaseRedirect'
import { getShowcaseSettings } from './getShowcaseSettings'
import { isInvalidShowcase } from './isInvalidShowcase'
import { getUA } from './getUA'
import { getApolloData } from './getApolloData'
import { getAuthProvider } from '@stuller/stullercom/feat/auth'
import { type CustomNextPageContext } from '@stuller/stullercom/feat/layout-context'
import { ensureCartSessionId } from './ensureCartSessionId'

/**
 * Custom app context with extra context attached to `ctx`
 */
type CustomAppContext = AppContext & {
  ctx: CustomNextPageContext
}

/**
 * Gets server-side (initial load) and client-side (client routed) props for App (auth, user agent, etc.)
 * See https://nextjs.org/docs/api-reference/data-fetching/get-initial-props
 */
async function getInitialProps (appContext: CustomAppContext): Promise<CustomAppProps> {
  const { ctx, router } = appContext

  // Get the user session id
  const userSessionId = getSessionId(ctx)

  // Ensure the cart session id cookie exists as it is required for certain graphql calls
  ensureCartSessionId(ctx)

  // Init apollo
  const apolloClient = initializeApollo(ctx)

  // Get auth
  const auth = await getAuth(ctx, apolloClient, userSessionId)

  let appProps: CustomAppProps = {
    asPath: ctx.asPath,
    ua: getUA(ctx),
    pageProps: undefined,
    host: auth.host,
    authUser: auth.authUser,
    sessionId: auth.sessionId,
    showcaseSettings: null,
    apolloClient
  }

  // Refresh auth user redirect
  if (await refreshAuthUserRedirect(appContext, auth)) {
    return { ...appProps, pageProps: { statusCode: 307 } }
  }

  // Reset password redirect
  if (await resetPasswordRedirect(appContext, auth)) {
    return { ...appProps, pageProps: { statusCode: 302 } }
  }

  // Sterling redirect
  if (await sterlingRedirect(appContext, auth)) {
    return { ...appProps, pageProps: { statusCode: 302 } }
  }

  // Get showcase settings
  const showcaseSettings = await getShowcaseSettings(apolloClient, auth)
  appProps = { ...appProps, showcaseSettings }

  // Send 403 forbidden when this is a jeweler showcase with missing or incomplete showcaseSettings
  if (isInvalidShowcase(auth, showcaseSettings)) {
    return { ...appProps, pageProps: { statusCode: 403 } }
  }

  // Authenticated jeweler showcase redirect
  if (await authenticatedShowcaseRedirect(appContext, auth, showcaseSettings)) {
    return appProps
  }

  // Get `App` props along with `pageProps`
  ctx.apolloClient = apolloClient
  ctx.auth = getAuthProvider(auth.authUser, auth.host, auth.sessionId, showcaseSettings)
  const appInitialProps = await App.getInitialProps(appContext)
  appProps = { ...appProps, ...appInitialProps }

  // Get Apollo data from tree
  await getApolloData(appContext, appProps)

  // Add Apollo state to App props
  appProps.apolloState = apolloClient.cache.extract()

  // Set the response status code if server side and http code exists
  if (ctx.res != null && appProps.pageProps.statusCode != null) {
    ctx.res.statusCode = appProps.pageProps.statusCode
  }

  if (appProps.pageProps != null) {
    const location: string | undefined = appProps.pageProps.location
    const statusCode: number | undefined = appProps.pageProps.statusCode
    if (location != null && statusCode != null && [301, 302, 307, 308].includes(statusCode) && appProps.pageProps.location != null) {
      if (ctx.res != null) {
        // Using redirect codes here to ensure http method is not changed in case this was a post
        ctx.res.writeHead(statusCode, { Location: location })
        ctx.res.end()
      } else {
        await router.push(location)
      }
    }
  }

  return appProps
}

export {
  getInitialProps
}
