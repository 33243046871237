import clsx from 'clsx'
import { type ReactNode, forwardRef } from 'react'
import { type HeadingTag } from '../../types/bootstrap'

export interface DropdownHeaderProps {
  /**
   * Id of element
   */
  id?: string
  /**
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /**
   * Children of element
   */
  children?: ReactNode
  /**
   * HTML element tag type, must be h1-h6
   */
  tag?: HeadingTag
}

/**
 * Dropdown list item header item to display in a dropdown menu
 */
const DropdownHeader = forwardRef<HTMLLIElement, DropdownHeaderProps>(({
  className,
  children,
  tag: Tag = 'h4',
  ...otherAttributes
}, ref) => {
  const classNames = clsx('dropdown-header', className)

  return (
    <li ref={ref}>
      <Tag
        className={classNames}
        {...otherAttributes}
      >
        {children}
      </Tag>
    </li>
  )
})
DropdownHeader.displayName = 'DropdownHeader'

export {
  DropdownHeader
}
