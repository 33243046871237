import { removeSurroundQuotes } from '@stuller/shared/util/core'

/**
 * Interface to allow window.dataLayer (for this file only)
 */
declare global {
  interface Window {
    /**
     * Google Tag Manager data layer array
     */
    dataLayer?: object[]
  }
}

type TrackEventDetails = Record<string, any>

interface TrackUniversalEventDetails {
  /**
   * Universal Google Analytics category
   */
  category: string
  /**
   * Universal Google Analytics action
   */
  action: string
  /**
   * Universal Google Analytics label
   */
  label?: string
  /**
   * Universal Google Analytics value
   */
  value?: number
}

/**
 * Add to Google Tag Manager `dataLayer.push` directly
 */
function track (data: object = {}): void {
  if (window?.dataLayer != null) {
    window.dataLayer.push(data)
  } else {
    throw new Error('track called server-side or before dataLayer initialized')
  }
}

/**
 * Fire a Google Tag Manager event via `dataLayer.push`
 */
function trackEvent (event: string, details: TrackEventDetails = {}): void {
  track({ details: null })
  track({ event, details })
}

/**
 * Fire a 'universal' Google Tag Manager event via `dataLayer.push`
 * This will be sent to Google Tag Manager with the event name 'universal'
 */
function trackUniversalEvent (details: TrackUniversalEventDetails): void {
  // Check that category and action are sent
  if (details.category == null || details.action == null) {
    throw new Error('trackUniversalEvent called without category and/or action')
  }

  // Check that value is a number
  if (details.value != null && typeof details.value !== 'number') {
    throw new Error('trackEvent called with value that is not a number')
  }

  // Remove extra starting/ending single/double quotes certain keys if present
  for (const key of ['category', 'action', 'label'] as const) {
    const value = details[key]

    if (value != null) {
      details[key] = removeSurroundQuotes(value)
    }
  }

  trackEvent('universal', details)
}

export {
  track,
  trackEvent,
  trackUniversalEvent
}
