import { type ReactElement, useEffect, useState } from 'react'
import Cookies from 'js-cookie'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fab } from '@awesome.me/kit-3dbd93c064/icons'

const DEV_UI_ENABLED_COOKIE_NAME = 'dev_ui_enabled'

/**
 * Component for displaying bootstrap screen sizes when 'Dev UI Mode' is enabled
 */
function BootstrapSizeHelper (): ReactElement | null {
  const [devUIEnabled, setDevUIEnabled] = useState(false)

  useEffect(() => {
    setDevUIEnabled(Cookies.get(DEV_UI_ENABLED_COOKIE_NAME)?.toLowerCase() === 'true')
  }, [])

  if (!devUIEnabled) {
    return null
  }

  return (
    <>
      {devUIEnabled &&
        <div className='position-fixed z-fixed start-0 bg-cyan-400 text-white p-2' style={{ bottom: '50px' }}>
          <div className='d-flex align-items-center'>
            <FontAwesomeIcon className='fs-2 text-cyan-800' icon={fab.faBootstrap} />
            <div className='d-flex px-2 fs-4 fw-medium'>
              <div className='d-none d-xxxl-block'>XXXL</div>
              <div className='d-none d-xxl-block d-xxxl-none'>XXL</div>
              <div className='d-none d-xl-block d-xxl-none'>XL</div>
              <div className='d-none d-lg-block d-xl-none'>LG</div>
              <div className='d-none d-md-block d-lg-none'>MD</div>
              <div className='d-none d-sm-block d-md-none'>SM</div>
              <div className='d-xs-block d-sm-none'>XS</div>
            </div>
          </div>
        </div>}
    </>
  )
}

export {
  BootstrapSizeHelper
}
