import { METATAG_OG_IMAGE, METATAG_SHOWCASE_LOGO } from '@stuller/shared/util/constants'
import { type MetaImageProps } from '@stuller/stullercom/feat/layout-context'
import { type ReactElement, memo } from 'react'
import { useAuth } from '@stuller/stullercom/feat/auth'
import Head from 'next/head'

/**
 * Customizable `<meta property='og:image'>` and `<meta property='twitter:image'>`
 * - Optimal Image features:
 *   - A dimension of `1200x630` is ideal
 *   - File size under `5MB` is advisable
 *   - Image format `JPG` or `PNG` is most compatible
 */
const MetaImage = memo(({
  children,
  disabled = false
}: MetaImageProps): ReactElement | null => {
  const { isShowcase, showcaseSettings } = useAuth()
  if (disabled) {
    return null
  }

  let image = (children ?? '').trim()
  if (isShowcase && image === '') {
    image = showcaseSettings?.logo ?? METATAG_SHOWCASE_LOGO
  } else if (image === '') {
    image = METATAG_OG_IMAGE
  }

  return (
    <Head>
      <meta property='og:image' key='og:image' content={image} />
      <meta property='twitter:image' key='twitter:image' content={image} />
    </Head>
  )
})
MetaImage.displayName = 'MetaImage'

export {
  MetaImage
}
