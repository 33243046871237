import { type ReactElement, memo } from 'react'
import Head from 'next/head'
import { type MetaProps } from '@stuller/stullercom/feat/layout-context'
import { useAuth, type Auth } from '@stuller/stullercom/feat/auth'
import {
  SHOWCASE_FAVICON,
  SHOWCASE_FAVICON_ICO,
  WEB_FAVICON_16,
  WEB_FAVICON_180,
  WEB_FAVICON_192,
  WEB_FAVICON_32,
  WEB_FAVICON_ICO,
  WEB_FAVICON_MASK,
  WEB_FAVICON_TILE
} from '@stuller/shared/util/constants'

interface ShowcaseOpenGraphMetaTagsProps {
  showcaseSettings: Auth['showcaseSettings']
}

/**
 * Return a robots meta tag for JewelerShowcase
 */
function ShowcaseRobots (): ReactElement {
  return (
    <Head>
      <meta name='robots' content='noindex, nofollow' />
    </Head>
  )
}

/**
 * Return meta links specific to Jeweler Showcase domain
 */
function ShowcaseFavicons (): ReactElement {
  return (
    <Head>
      <link rel='icon' href={SHOWCASE_FAVICON} />
      <link rel='shortcut icon' href={SHOWCASE_FAVICON_ICO} />
    </Head>
  )
}

/**
 * Return Showcase specific Open Graph meta tags
 */
function ShowcaseOpenGraphMetaTags ({ showcaseSettings }: ShowcaseOpenGraphMetaTagsProps): ReactElement | null {
  if (showcaseSettings == null) {
    return null
  }

  return (
    <Head>
      <meta property='og:type' content='website' />
      <meta property='og:site_name' content={showcaseSettings.title ?? ''} />
    </Head>
  )
}

/**
 * Return meta links specific to Stuller domain
 */
function StullerFavicons (): ReactElement {
  return (
    <Head>
      <meta name='msapplication-TileImage' content={WEB_FAVICON_TILE} />
      <link rel='icon' type='image/png' sizes='32x32' href={WEB_FAVICON_32} />
      <link rel='icon' type='image/png' sizes='192x192' href={WEB_FAVICON_192} />
      <link rel='icon' type='image/png' sizes='16x16' href={WEB_FAVICON_16} />
      <link rel='mask-icon' href={WEB_FAVICON_MASK} color='#333333' />
      <link rel='shortcut icon' href={WEB_FAVICON_ICO} />
      <link rel='apple-touch-icon' sizes='180x180' href={WEB_FAVICON_180} />
    </Head>
  )
}

/**
 * Return Stuller default Open Graph meta tags
 */
function StullerOpenGraphMetaTags (): ReactElement {
  return (
    <Head>
      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:site' content='@StullerInc' />
      <meta property='og:type' content='website' />
      <meta property='og:site_name' content='Stuller, Inc.' />
    </Head>
  )
}

/**
 * Common web site meta/link tags
 * Tags can be added/overriden by including them using the `Head` component
 * See https://nextjs.org/docs/api-reference/next/head
 */
const Meta = memo(({
  disabled = false
}: MetaProps): ReactElement | null => {
  const { isShowcase, isJewelerShowcase, showcaseSettings } = useAuth()

  if (disabled) {
    return null
  }

  return (
    <>
      <Head>
        <meta charSet='utf-8' />
        <meta httpEquiv='content-type' content='text/html; charset=UTF-8' />
        <meta httpEquiv='x-dns-prefetch-control' content='on' />
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <meta name='google-site-verification' content='BQH4it7qPRFWBxiJ7FicXqTvlL5oH4f1OADIRNiCM64' />
        <meta name='facebook-domain-verification' content='smm1t77d702elx3bfuywxj9tz89d44' />
        <meta name='apple-mobile-web-app-title' content='Stuller, Inc.' />
        <meta name='application-name' content='Stuller, Inc.' />
        <meta name='msapplication-TileColor' content='#ffffff' />
        <meta name='theme-color' content='#ffffff' />
        <link rel='dns-prefetch' href='//meteor.stullercloud.com' />
        <link rel='dns-prefetch' href='//assets.stullercloud.com' />
        <link rel='manifest' href='https://assets.stullercloud.com/web/static/sitemaps/site.webmanifest' />
      </Head>
      {isShowcase
        ? <ShowcaseOpenGraphMetaTags showcaseSettings={showcaseSettings} />
        : <StullerOpenGraphMetaTags />}
      {isShowcase
        ? <ShowcaseFavicons />
        : <StullerFavicons />}
      {isJewelerShowcase && <ShowcaseRobots />}
    </>
  )
})
Meta.displayName = 'Meta'

export {
  Meta
}
