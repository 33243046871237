import { type ReactNode, forwardRef } from 'react'
import clsx from 'clsx'

export interface ModalBodyProps {
  /**
   * Id of element
   */
  id?: string
  /**
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /**
   * Children of element
   */
  children?: ReactNode
}

/**
 * Modal body component
 */
const ModalBody = forwardRef<HTMLDivElement, ModalBodyProps>(({
  className,
  children,
  ...otherAttributes
}, ref) => {
  const classNames = clsx('modal-body', className)

  return (
    <div
      className={classNames}
      ref={ref}
      {...otherAttributes}
    >
      {children}
    </div>
  )
})
ModalBody.displayName = 'ModalBody'

export {
  ModalBody
}
