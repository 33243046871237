import { createContext } from 'react'

export interface AccordionContextType {
  /**
   * The currently open accordion item(s)
   */
  open?: string | string[] | null
  /**
   * Function to call when an accordion item is toggled
   */
  onToggle?: (key: string) => void
  /**
   * Indicates to include schema.org FAQPage microdata
   */
  faqSchema?: boolean
}

const AccordionContext = createContext<AccordionContextType>({})

export {
  AccordionContext
}
