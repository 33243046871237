import { type GetAuth } from '@stuller/stullercom/feat/auth'
import { type CustomAppProps } from './types'

/**
 * Throws a 403 error if host appears to be a jeweler showcase site but has missing or incomplete showcase settings
 */
function isInvalidShowcase (auth: GetAuth, showcaseSettings?: CustomAppProps['showcaseSettings']): boolean {
  const { isJewelerShowcase } = auth

  // If isJewelerShowcase & not markupsComplete return false
  return (isJewelerShowcase && showcaseSettings == null)
}

export {
  isInvalidShowcase
}
