import { forwardRef, type ReactNode, useContext, useMemo } from 'react'
import clsx from 'clsx'
import { AccordionContext, AccordionItemContext, type AccordionItemContextType } from '../../..'

export interface AccordionItemProps extends AccordionItemContextType {
  /**
   * Id of element
   */
  id?: string
  /**
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /**
   * Children of element
   */
  children?: ReactNode
}

/**
 * Accordion item component that represents a single section of the accordion (header and body).
 */
const AccordionItem = forwardRef<HTMLDivElement, AccordionItemProps>(({
  className,
  children,
  itemId,
  ...otherAttributes
}, ref) => {
  const { faqSchema } = useContext(AccordionContext)
  const context = useMemo(() => ({ itemId }), [itemId])
  const faqSchemaMicrodata = faqSchema === true && { itemScope: true, itemProp: 'mainEntity', itemType: 'https://schema.org/Question' }
  const classNames = clsx('accordion-item', className)

  return (
    <AccordionItemContext.Provider value={context}>
      <div
        className={classNames}
        {...faqSchemaMicrodata}
        ref={ref}
        {...otherAttributes}
      >
        {children}
      </div>
    </AccordionItemContext.Provider>
  )
})
AccordionItem.displayName = 'AccordionItem'

export {
  AccordionItem
}
