import { type CSSProperties, forwardRef, type ReactNode } from 'react'
import clsx from 'clsx'

export interface InputGroupProps {
  /**
   * Id of element
   */
  id?: string
  /**
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /**
   * Inline styles to pass to the containing element
   */
  style?: CSSProperties
  /**
   * Input group element children
   */
  children?: ReactNode
  /**
   * Indicates to render the input group as a pill (applies "rounded pill" to first and last child)
   */
  pill?: boolean
  /**
   * Size of the input group (no need to specify size on input children)
   */
  size?: 'sm' | 'lg'
  /**
   * Indicates something in the input group is invalid to show fail message
   */
  invalid?: boolean | string
  /**
   * Indicates something in the input group is valid to show success message
   */
  valid?: boolean | string
}

/**
 * Input group component to extend form controls by adding text, buttons, or button groups on either side of textual inputs, selects, and file inputs.
 *
 * **Important**: In order to make sure that all styles are applied correctly, make sure that the first and last element of the input group is an input group text, button, or form control.
 * AKA do not place things like popovers, validation messages, etc. in the input group.
 */
const InputGroup = forwardRef<HTMLDivElement, InputGroupProps>(({
  className,
  children,
  pill = false,
  size,
  invalid = false,
  valid = false,
  ...otherAttributes
}, ref) => {
  const classNames = clsx(
    'input-group',
    pill && 'rounded-pill',
    size != null && `input-group-${size}`,
    invalid !== false && invalid !== '' && 'is-invalid',
    valid === true && 'is-valid',
    className
  )

  return (
    <div
      className={classNames}
      ref={ref}
      {...otherAttributes}
    >
      {children}
    </div>
  )
})
InputGroup.displayName = 'InputGroup'

export {
  InputGroup
}
