import { type ReactElement, type ReactNode, useMemo } from 'react'
import { UAParser } from 'ua-parser-js'
import { UserAgentContext } from './UserAgentContext'
import { type UserAgent } from './types'

export interface UserAgentProviderProps {
  /**
   * Children under the provider
   */
  children?: ReactNode
  /**
   * The user agent string
   */
  ua: UserAgent['ua']
}

/**
 * Get user agent info
 */
function getUserAgent (ua: UserAgent['ua']): UserAgent {
  const userAgent: UserAgent = new UAParser(ua).getResult()

  if (userAgent.browser.version != null) {
    const parts = userAgent.browser.version.split('.')
    userAgent.browser.majorVersion = parseInt(parts[0] ?? 0)
    userAgent.browser.minorVersion = parseInt(parts[1] ?? 0)
  }

  if (userAgent.os.version != null) {
    const parts = userAgent.os.version.split('.')?.[0]
    userAgent.os.majorVersion = parseInt(parts[0] ?? 0)
    userAgent.os.minorVersion = parseInt(parts[1] ?? 0)
  }

  return userAgent
}

/**
 * Provides user agent info to context
 */
function UserAgentProvider ({ ua, children }: UserAgentProviderProps): ReactElement {
  const userAgent = useMemo(() => getUserAgent(ua), [ua])

  return (
    <UserAgentContext.Provider value={userAgent}>
      {children}
    </UserAgentContext.Provider>
  )
}

export {
  UserAgentProvider,
  getUserAgent,
  UserAgentContext
}
