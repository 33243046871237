import { type ComponentRef, type ReactElement, useState } from 'react'
import { Badge, type BadgeProps } from '../../shared/badge/Badge'
import { Popover } from '../../shared/popover/Popover'
import { PopoverBody } from '../../shared/popover/PopoverBody'
import {
  type ProductBadgeConfiguredProps,
  type ProductBadgeType,
  productBadges
} from './product-badges'

interface ProductBadgeProps extends Omit<BadgeProps, ProductBadgeConfiguredProps | 'pill'> {
  /**
   * The badge type that will control the badge's appearance
   */
  type: ProductBadgeType
}

/**
 * Product badge that wraps the Badge component for product specific badge types.
 */
function ProductBadge ({
  type,
  ...otherAttributes
}: ProductBadgeProps): ReactElement | null {
  const badge = productBadges[type]
  const { popover, ...props } = badge ?? {}
  const [open, handleOpenChange] = useState(false)
  const [reference, setReference] = useState<ComponentRef<typeof Badge> | null>(null)

  if (badge == null) {
    return null
  }

  return (
    <>
      <Badge
        {...props}
        pill
        ref={setReference}
        {...otherAttributes}
      />
      {popover != null && (
        <Popover
          placement='top'
          hover
          hoverProps={{ delay: { open: 300 } }}
          offset={5}
          open={open}
          onOpenChange={handleOpenChange}
          reference={reference}
        >
          <PopoverBody className='p-4'>
            {popover}
          </PopoverBody>
        </Popover>)}
    </>
  )
}

export {
  ProductBadge
}
