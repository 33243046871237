import {
  forwardRef,
  type ReactNode,
  type ComponentPropsWithoutRef,
  useContext,
  type ChangeEventHandler
} from 'react'
import clsx from 'clsx'
import { type AsyncFunction } from '@stuller/shared/util/type-helpers'
import { FormGroupContext } from '../../../..'

type SelectTagProps = Omit<ComponentPropsWithoutRef<'select'>, 'size' | 'onChange'>

export interface SelectProps extends SelectTagProps {
  /**
   * Id of element
   */
  id?: string
  /**
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /**
   * Select options
   */
  children?: ReactNode
  /**
   * Indicates to render the select as a pill
   */
  pill?: boolean
  /**
   * Size of the select
   * Bootstrap size not HTML `size` (use `htmlSize` for number of visible options)
   */
  size?: 'sm' | 'lg'
  /**
   * Indicates the select is invalid to show fail style
   */
  invalid?: boolean | string
  /**
   * Indicates the select is valid to show success style
   */
  valid?: boolean | string
  /**
   * The html size property to control visible options
   */
  htmlSize?: ComponentPropsWithoutRef<'input'>['size']
  /**
   * On change handler with async
   */
  onChange?: AsyncFunction<ChangeEventHandler<HTMLSelectElement>>
}

/**
 * Select component with for rendering `<select>` element.
 * Pass `<option>` elements as children.
 *
 * See `Form` for more usage examples.
 * Remember to use `value` for controlled components.
 */
const Select = forwardRef<HTMLSelectElement, SelectProps>(({
  id: idIn,
  className,
  children,
  pill = false,
  size,
  invalid = false,
  valid = false,
  htmlSize,
  onChange,
  ...otherAttributes
}, ref) => {
  const { groupId } = useContext(FormGroupContext)
  const id = idIn ?? groupId
  const classNames = clsx(
    'form-select',
    pill && 'rounded-pill',
    size != null && `form-select-${size}`,
    invalid !== false && invalid !== '' && 'is-invalid',
    valid === true && 'is-valid',
    className
  )

  return (
    <select
      id={id}
      className={classNames}
      size={htmlSize}
      onChange={onChange}
      ref={ref}
      {...otherAttributes}
    >
      {children}
    </select>
  )
})
Select.displayName = 'Select'

export {
  Select
}
