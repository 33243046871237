import { useContext } from 'react'
import { UserAgentContext } from './UserAgentProvider'
import { type UserAgentContextType } from './types'

/**
 * Hook to fetch user agent information
 */
function useUserAgent (): UserAgentContextType {
  return useContext(UserAgentContext)
}

export { useUserAgent }
