import React, { type ReactElement, useContext, useMemo } from 'react'
import { Button } from '@stuller/stullercom/ui'
import { MenuCartContext } from './MenuCartContext'
import { CartPopoverContext } from './CartPopoverContext'
import { CartCheckoutError } from './CartCheckoutError'
import { ShippingCountdown } from '@stuller/stullercom/feat/shipping-countdown'
import { useGetCanCheckoutQuery } from '@stuller/stullercom/data-access/apollo-queries'

/**
 * The button that links to the checkout page
 */
function CartCheckoutButton (): ReactElement | null {
  const { hasScheduledOrder } = useContext(MenuCartContext)
  const { cart } = useContext(CartPopoverContext)
  const { data } = useGetCanCheckoutQuery()
  const cartLines = cart.loading
    ? cart.previousData?.cart?.items
    : cart.data?.cart?.items
  const cartErrors = cart.loading
    ? cart.previousData?.cart?.errors
    : cart.data?.cart?.errors
  const cartItemsErrorsArray = cart.loading
    ? cart.previousData?.cart?.items?.map(item => item.errors)
    : cart.data?.cart?.items?.map(item => item.errors)
  const shippingCountdown = cart.loading
    ? cart.previousData?.cart?.shippingCountdown
    : cart.data?.cart?.shippingCountdown

  const showCheckoutButton = !hasScheduledOrder &&
    cartLines != null &&
    cartLines?.length > 0 &&
    (data?.viewerPermissions.canCheckout ?? false)

  const hasErrors = useMemo(() => {
    return (cartErrors != null && cartErrors?.length > 0) ||
    (cartItemsErrorsArray != null && cartItemsErrorsArray?.length > 0 && cartItemsErrorsArray.some(errors => errors.length > 0))
  }, [cartErrors, cartItemsErrorsArray])

  if (!showCheckoutButton) {
    return null
  }

  return (
    <div className='px-6 mb-4'>
      {shippingCountdown != null && <ShippingCountdown countdown={shippingCountdown} />}
      {hasErrors && <CartCheckoutError />}
      <Button
        href='/checkout'
        tag='a'
        color='success'
        pill
        className='w-100'
        disabled={hasErrors}
        data-test='checkout-now-button'
      >
        Checkout Now
      </Button>
    </div>
  )
}

export {
  CartCheckoutButton
}
