import { useState, type ReactElement } from 'react'
import { useToggle } from '@stuller/shared/util/react-hooks'
import { Dropdown, DropdownDivider, DropdownMenu, DropdownToggle } from '@stuller/stullercom/ui'
import { CmsTools } from './CmsTools'
import { DevTools } from './DevTools'

interface AdminDropdownProps {
  /**
   * Whether to show CMS tools
   */
  showCmsTools: boolean
  /**
   * Whether to show dev tools
   */
  showDevTools: boolean
}

/**
 * The admin tools dropdown for dev/cms admin users
 */
function AdminTools ({ showCmsTools, showDevTools }: AdminDropdownProps): ReactElement {
  const [open, handleToggle, handleOpenChange] = useToggle(false)
  const [reference, setReference] = useState<Element | null>(null)

  return (
    <Dropdown
      open={open}
      onToggle={handleToggle}
      onOpenChange={handleOpenChange}
      reference={reference}
    >
      <DropdownToggle color='anchor-dark' ref={setReference}>
        Admin Tools
      </DropdownToggle>
      <DropdownMenu>
        {showCmsTools && <CmsTools onToggleTools={handleToggle} />}
        {showCmsTools && showDevTools && <DropdownDivider />}
        {showDevTools && <DevTools />}
      </DropdownMenu>
    </Dropdown>
  )
}

export {
  AdminTools
}
