import { type ReactElement } from 'react'
import Link from 'next/link'
import { Button } from '@stuller/stullercom/ui'
import { MenuPopover, type MenuPopoverProps } from '../MenuPopover'
import { CmsContent } from '@stuller/stullercom/feat/cms-content'
import { useAuth } from '@stuller/stullercom/feat/auth'
import { AccountMemberships } from './AccountMemberships'

interface AccountPopoverProps extends MenuPopoverProps {
}

/**
 * The popover for account menu
 */
function AccountPopover ({
  open,
  onOpenChange,
  reference
}: AccountPopoverProps): ReactElement {
  const { authUser } = useAuth()

  return (
    <MenuPopover
      open={open}
      onOpenChange={onOpenChange}
      reference={reference}
    >
      <div className='p-6' data-gtm-click-subsection1='Account'>
        {authUser?.user?.contact?.firstName != null && (
          <div className='text-center mb-4'>
            <span>Hi, {authUser?.user?.contact?.firstName}</span>
            <span className='mx-3'>|</span>
            <span>
              <Link className='primary' href='/myaccount/accountsettings/contactinformation'>Not you?</Link>
            </span>
          </div>
        )}

        <Button href='/orders' tag='a' color='primary' pill className='w-100 mb-4'>Orders</Button>

        <Button href='/myaccount' tag='a' color='primary' pill className='w-100 mb-4' data-gtm-click-text='My Account'>
          <div className='px-4'>
            My Account
            <div className='fs-6 fw-normal lh-1'>
              Settings + Dashboard + Actions
            </div>
          </div>
        </Button>

        <Button href='/myaccount/accountsettings/preferencecenter' tag='a' color='primary' pill className='w-100 mb-4'>
          Preferences
        </Button>

        <Button href='/logout' tag='a' color='primary' outline pill className='w-100 mb-6'>
          Log Out
        </Button>

        <AccountMemberships />

        <CmsContent
          contentContainerName='main-nav-account-helpful-links'
          skipLoading
          noRefetch
        />
      </div>
    </MenuPopover>
  )
}

export {
  AccountPopover
}
